"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildUrl = void 0;
const _1 = require(".");
const normalise_team_slug_1 = require("./normalise-team-slug");
const buildUrl = (props) => {
    const normalisedTeamSlug = (0, normalise_team_slug_1.normaliseTeamSlug)(props.teamSlug);
    return `https://${props.accountSlug}${normalisedTeamSlug ? '-' : ''}${normalisedTeamSlug || ''}.${(0, _1.rootDomain)(props.expertliUrl)}${props.path}`;
};
exports.buildUrl = buildUrl;
