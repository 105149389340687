"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clonePages = void 0;
const clonePages = (input, pageId, getPageChange) => {
    const curPage = input[pageId];
    if (!curPage) {
        //should not happen - can happen when undo has removed a page
        //TODO need to log
        console.error('Page not found');
        return {
            ...input,
        };
    }
    const delta = getPageChange(curPage);
    return {
        ...input,
        [pageId]: { ...input[pageId], ...delta },
    };
};
exports.clonePages = clonePages;
