"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNSET_TEAM_SLUG = exports.ROLEID_ADMIN = exports.ROLEID_MEMBER = exports.ROLEID_PUBLIC = exports.PDF_SUPPORTED_FILE_TYPES = exports.PAGEID_SETTINGS = exports.PAGEID_ADD_PAGE = exports.PAGEID_SCREEN_SHARE = exports.PAGEID_VIDEO_GALLERY = exports.EXPERTLI_SIGNATURE_HEADER_KEY = exports.STORAGE_PROTOCOL = exports.DEFAULT_TOOL = exports.EXPERTLI_TERMS_URL = exports.EXPERTLI_PRIVACY_POLICY_URL = exports.UPLOAD_MAX_FILE_SIZE = exports.AUTH_SESSION_COOKIE_NAME = exports.AUTH_COOKIE_PREFIX = void 0;
exports.AUTH_COOKIE_PREFIX = 'expertli.auth.';
exports.AUTH_SESSION_COOKIE_NAME = `${exports.AUTH_COOKIE_PREFIX}session-token`;
exports.UPLOAD_MAX_FILE_SIZE = 500 * 1024 * 1024; // 500MB
exports.EXPERTLI_PRIVACY_POLICY_URL = 'https://www.expertli.com/privacy-policy';
exports.EXPERTLI_TERMS_URL = 'https://www.expertli.com/terms-of-use';
exports.DEFAULT_TOOL = 'pointer';
exports.STORAGE_PROTOCOL = 'storage';
exports.EXPERTLI_SIGNATURE_HEADER_KEY = 'x-expertli-signature';
exports.PAGEID_VIDEO_GALLERY = 'video-gallery';
exports.PAGEID_SCREEN_SHARE = 'screen-share';
exports.PAGEID_ADD_PAGE = 'add-page';
exports.PAGEID_SETTINGS = 'settings';
exports.PDF_SUPPORTED_FILE_TYPES = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
};
exports.ROLEID_PUBLIC = 'PUBLIC';
exports.ROLEID_MEMBER = 'MEMBER';
exports.ROLEID_ADMIN = 'ADMIN';
//hack becuase nextjs does not support empty double slashes in paths
exports.UNSET_TEAM_SLUG = 'xx-unset-slug-xx';
