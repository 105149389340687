import { useQuery, useQueryClient } from 'react-query';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

import { WorkspaceFile } from '../types';
import { WorkspaceKeys } from './query-keys';

const queryKey = (workspaceId: string) => WorkspaceKeys.attachments(workspaceId);

async function getFiles(url: string) {
  const response = await client.get(url, {
    headers: {
      Accept: 'application/json',
    },
  });
  return response.data as WorkspaceFile[];
}

export function useGetFiles(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(queryKey(workspaceId), () =>
    getFiles(
      `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/storage?excludeSystemFiles=true`
    )
  );

  const invalidate = async () => {
    await queryClient.invalidateQueries(queryKey(workspaceId));
  };

  return {
    isLoading,
    files: data,
    invalidate,
  };
}
