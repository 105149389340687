"use strict";
exports.__esModule = true;
exports.wrap = void 0;
// Allow pino.error to be used like: logger.error('message', err)
function wrap(logger) {
    var error = logger.error, child = logger.child;
    function errorRearranger() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (typeof args[0] === 'string' && args.length > 1) {
            for (var i = 1; i < args.length; i++) {
                var arg = args[i];
                if (arg instanceof Error) {
                    // @ts-ignore
                    return error.apply(logger, [{ error: arg }, args[0]]);
                }
            }
        }
        // @ts-ignore
        return error.apply(this, args);
    }
    function childModifier() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        //  @ts-ignore
        var childLogger = child.apply(this, args);
        childLogger.error = errorRearranger;
        // @ts-ignore
        childLogger.child = childModifier;
        return childLogger;
    }
    logger.error = errorRearranger;
    // @ts-ignore
    logger.child = childModifier;
    return logger;
}
exports.wrap = wrap;
