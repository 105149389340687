"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOperationFromTransactionId = exports.getTransactionId = exports.TRANSACTION_ID_WITH_NEXT = exports.TRANSACTION_ID_WITH_PREVIOUS = void 0;
const uuid_1 = require("uuid");
exports.TRANSACTION_ID_WITH_PREVIOUS = '-with-previous';
exports.TRANSACTION_ID_WITH_NEXT = '-with-next';
const getTransactionId = ({ operation, autoUniqueId, uniqueId }) => `${operation ? operation : ''}${autoUniqueId || uniqueId ? ':' : ''}${autoUniqueId ? (0, uuid_1.v4)() : uniqueId ? uniqueId?.replace(/:/gi, '|') : ''}`;
exports.getTransactionId = getTransactionId;
const getOperationFromTransactionId = (transactionId) => {
    if (!transactionId)
        return undefined;
    return transactionId.split(':')?.[0] ?? undefined;
};
exports.getOperationFromTransactionId = getOperationFromTransactionId;
