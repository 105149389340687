"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isArtifactStickyMessage = exports.isArtifactRemoveMessage = exports.isArtifactPositionMessage = exports.isArtifactRedactionMessage = exports.isArtifactTextBlockMessage = void 0;
const isArtifactTextBlockMessage = (msg) => msg.messageType === 'artifact-textblock';
exports.isArtifactTextBlockMessage = isArtifactTextBlockMessage;
const isArtifactRedactionMessage = (msg) => msg.messageType === 'artifact-redaction';
exports.isArtifactRedactionMessage = isArtifactRedactionMessage;
const isArtifactPositionMessage = (msg) => msg.messageType === 'artifact-position';
exports.isArtifactPositionMessage = isArtifactPositionMessage;
const isArtifactRemoveMessage = (msg) => msg.messageType === 'artifact-remove';
exports.isArtifactRemoveMessage = isArtifactRemoveMessage;
const isArtifactStickyMessage = (msg) => msg.messageType === 'artifact-sticky';
exports.isArtifactStickyMessage = isArtifactStickyMessage;
