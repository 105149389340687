import { useCallback, useEffect, useMemo, useRef } from 'react';

export const useAnimationFrame: () => {
  execute: (method: () => void) => void;
  cancel: () => void;
} = () => {
  const lastAnimationFrameRef = useRef<number>(null);
  const isMounted = useRef(true);

  const cancel = useCallback(() => {
    if (lastAnimationFrameRef.current) {
      cancelAnimationFrame(lastAnimationFrameRef.current);
      lastAnimationFrameRef.current = null;
    }
  }, []);

  const execute = useCallback(
    (method: () => void) => {
      cancel();
      lastAnimationFrameRef.current = requestAnimationFrame(() => {
        if (isMounted.current) {
          method();
        }
      });
    },
    [cancel]
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
      cancel();
    };
  }, [cancel]);

  const returnValue = useMemo(
    () => ({
      execute,
      cancel,
    }),
    [cancel, execute]
  );

  return returnValue;
};
