"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootDomain = void 0;
function isLocalDomain(address) {
    const url = new URL(address);
    const localTLDs = ['.local', '.localdomain', 'localhost'];
    return url.port || localTLDs.find((tld) => url.hostname.endsWith(tld));
}
function rootDomain(address) {
    const url = new URL(address);
    //  Support for macOS network address. See Sharing preference for your own
    // .local domain address.
    if (isLocalDomain(address)) {
        return new URL(address).hostname;
    }
    const domain = url.hostname;
    const parts = domain.split('.');
    return parts.length > 2 ? parts[parts.length - 2] + '.' + parts[parts.length - 1] : domain;
}
exports.rootDomain = rootDomain;
