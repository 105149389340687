import { QueryClient, useQuery, useQueryClient } from 'react-query';

import { getStencils as getStencilsAsync } from '../utils/template';

const getQueryKey = (id: string) => ['workspace', id, 'stencils'];

export const invalidateGetStencils = async (queryClient: QueryClient, workspaceId: string) => {
  await queryClient.invalidateQueries(getQueryKey(workspaceId));
  await getStencils(queryClient, workspaceId);
};

export const getStencils = (queryClient: QueryClient, workspaceId: string) => {
  return queryClient.fetchQuery(
    getQueryKey(workspaceId),
    () => {
      return getStencilsAsync(workspaceId);
    },
    {
      staleTime: Infinity,
    }
  );
};

export function useGetStencils(workspaceId: string, enabled: boolean) {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery(
    getQueryKey(workspaceId),
    () => {
      return getStencilsAsync(workspaceId);
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled,
      onError: (err) => {
        console.error('Error retrieving Template', err);
      },
    }
  );

  return {
    isLoading,
    data,
    invalidate: () => {
      invalidateGetStencils(queryClient, workspaceId);
    },
  };
}
