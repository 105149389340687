"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMessageCollection = exports.isStateBulkMessage = exports.isStateResetMessage = exports.isStateRedoMessage = exports.isStateUndoMessage = void 0;
const isStateUndoMessage = (msg) => msg.messageType === 'state-undo';
exports.isStateUndoMessage = isStateUndoMessage;
const isStateRedoMessage = (msg) => msg.messageType === 'state-redo';
exports.isStateRedoMessage = isStateRedoMessage;
const isStateResetMessage = (msg) => msg.messageType === 'state-reset';
exports.isStateResetMessage = isStateResetMessage;
const isStateBulkMessage = (msg) => msg.messageType === 'state-bulk';
exports.isStateBulkMessage = isStateBulkMessage;
const isMessageCollection = (msg) => msg.messageType === 'state-bulk' || msg.messageType === 'state-reset';
exports.isMessageCollection = isMessageCollection;
