import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

export async function requestAccess(workspaceId: string, body: { message?: string }) {
  await client.post(
    `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/access/request`,
    body
  );
}
export function useRequestAccess() {
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (input: { message?: string; workspaceId: string }) =>
      requestAccess(input.workspaceId, { message: input.message })
  );
  return {
    isLoading,
    response: data,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    requestAccess: mutateAsync,
  };
}
