"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotImplemented = exports.ResourceNotFound = exports.MethodNotAllowed = exports.OTPRequired = exports.Forbidden = exports.SessionExpired = exports.Unauthorized = exports.Conflict = exports.TooManyRequests = exports.UnprocessableEntity = exports.BadRequest = exports.ErrorResponse = void 0;
const ramda_1 = require("ramda");
class ErrorResponse extends Error {
    code;
    statusCode;
    logError;
    extra;
    constructor(statusCode, code, message, logError = false) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.code = code;
        this.message = message;
        this.statusCode = statusCode;
        this.logError = logError || statusCode > 499;
        this.extra = {};
        Error.captureStackTrace(this);
    }
    inheritFromError(e) {
        if (e) {
            this.stack += '\nCaused By: ' + e.message + '\n' + e.stack;
        }
    }
    toJSON() {
        const { code, message } = this;
        return {
            code,
            message,
        };
    }
    toString() {
        // 400 BadRequest - Given institution credentials are invalid - invalid_credentials
        return `${this.statusCode} ${this.name} - ${this.message} - ${this.code}`;
    }
}
exports.ErrorResponse = ErrorResponse;
class BadRequest extends ErrorResponse {
    constructor(code = 'bad_request', message = 'Bad Request', e) {
        super(400, code, message, true);
        this.inheritFromError(e);
    }
}
exports.BadRequest = BadRequest;
class UnprocessableEntity extends ErrorResponse {
    details;
    constructor(code = 'unprocessable_entity', message = 'Invalid request payload. Check the details', details = [], e) {
        super(422, code, message, true);
        this.details = details;
        this.inheritFromError(e);
    }
    toJSON() {
        return {
            ...super.toJSON(),
            details: this.details.map((i) => (0, ramda_1.pick)(['type', 'message', 'path'], i)),
        };
    }
}
exports.UnprocessableEntity = UnprocessableEntity;
class TooManyRequests extends ErrorResponse {
    constructor(code = 'too_many_requests', message = 'Stop spamming 🤬', e) {
        super(429, code, message);
        this.inheritFromError(e);
    }
}
exports.TooManyRequests = TooManyRequests;
class Conflict extends ErrorResponse {
    constructor(code = 'conflict', message = 'Conflict', e) {
        super(409, code, message, true);
        this.inheritFromError(e);
    }
}
exports.Conflict = Conflict;
class Unauthorized extends ErrorResponse {
    constructor(e) {
        super(401, 'unauthorized', 'Unauthorized');
        this.inheritFromError(e);
    }
}
exports.Unauthorized = Unauthorized;
class SessionExpired extends ErrorResponse {
    constructor(e) {
        super(401, 'session_expired', 'Session expired');
        this.inheritFromError(e);
    }
}
exports.SessionExpired = SessionExpired;
class Forbidden extends ErrorResponse {
    constructor(code = 'forbidden', message = 'Forbidden', e) {
        super(403, code, message);
        this.inheritFromError(e);
    }
}
exports.Forbidden = Forbidden;
class OTPRequired extends Forbidden {
    constructor(code = 'otp_required', message = 'OTP required', e) {
        super(code, message, e);
        this.inheritFromError(e);
    }
}
exports.OTPRequired = OTPRequired;
class MethodNotAllowed extends ErrorResponse {
    constructor(e) {
        super(405, 'method_not_allowed', 'Method not allowed', true);
        this.inheritFromError(e);
    }
}
exports.MethodNotAllowed = MethodNotAllowed;
class ResourceNotFound extends ErrorResponse {
    constructor(e) {
        super(404, 'resource_not_found', 'Resource Not Found');
        this.inheritFromError(e);
    }
}
exports.ResourceNotFound = ResourceNotFound;
class NotImplemented extends ErrorResponse {
    constructor(e) {
        super(500, 'not_implemented', 'Not Implemented', true);
        this.inheritFromError(e);
    }
}
exports.NotImplemented = NotImplemented;
