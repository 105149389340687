import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import type { Role } from 'expertli-db';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';

const queryUrl = (workspaceId: string, userId: string) =>
  `${apis.WORKSPACES}/${workspaceId}/permissions/users/${userId}`;

export async function getUserWorkspacePermission(workspaceId: string, userId: string) {
  const response = await client.get(queryUrl(workspaceId, userId), {
    validateStatus: (status) => status < 400 || status === 404,
  });
  return response;
}

export function useGetUserWorkspacePermission(workspaceId: string, userId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(queryUrl(workspaceId, userId), () =>
    getUserWorkspacePermission(workspaceId, userId)
  );

  const invalidate = useCallback(async () => {
    await queryClient.invalidateQueries(queryUrl(workspaceId, userId));
  }, [queryClient, userId, workspaceId]);

  return {
    isLoading,
    permission: data?.data as {
      organisationRole?: Role;
      individualRole?: Role;
      role?: Role;
      email?: string;
      userId: string;
      token?: string;
      name?: string;
      lastName?: string;
      individualPermissionId?: string;
    },
    notFound: data?.status === 404,
    invalidate,
  };
}
