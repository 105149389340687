import { TextField } from '@mui/material';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';

import { InputOptions } from '../model/dialog';
import { ConfirmationDialog } from './confirmation-dialog';

export const InputDialog: FC<
  InputOptions & { onOk: (value: string) => void; onCancel: () => void }
> = ({ children, onOk, asyncExecuteOnOk, ...props }) => {
  const [loading, setLoading] = useState(false);
  const { defaultValue, required, placeholder } = props;
  const [value, setValue] = useState(defaultValue);
  const textRef = useRef<HTMLTextAreaElement>();

  const handleAsyncExecuteOnOk = useCallback(async () => {
    setLoading(true);
    if (asyncExecuteOnOk) {
      await asyncExecuteOnOk(value);
    }
  }, [asyncExecuteOnOk, value]);

  useEffect(() => {
    setTimeout(() => {
      textRef.current?.focus();
      textRef.current?.select();
    }, 0);
  }, []);

  const handleOk = useCallback(() => {
    onOk?.(value);
  }, [onOk, value]);

  return (
    <ConfirmationDialog
      {...props}
      asyncExecuteOnOk={handleAsyncExecuteOnOk}
      onOk={handleOk}
      okDisabled={required && (value?.trim() ?? '') === ''}
    >
      {children}
      <TextField
        autoFocus
        disabled={loading}
        inputRef={textRef}
        margin="dense"
        label={placeholder}
        type="text"
        fullWidth
        variant="outlined"
        required={required}
        defaultValue={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </ConfirmationDialog>
  );
};
