import { MutableRefObject, useLayoutEffect, useMemo } from 'react';

import { useDistributedPageContext, useLatestRef } from '.';
import { RegisteredElement, RegisteredHtmlElement } from '../context';

export const useRegisterElement: (props: {
  name: string;
  ref: MutableRefObject<RegisteredHtmlElement | null>;
  drawingAnchorType: RegisteredElement['drawingAnchorType'];
  anchorLabel?: string;
  index?: number;
}) => { forceRefresh: () => void } = (props) => {
  const { name, ref, anchorLabel, drawingAnchorType } = props;
  const latestProps = useLatestRef(props);
  const { registerElement } = useDistributedPageContext();

  useLayoutEffect(() => {
    if (ref && name) {
      registerElement({ name, elementRef: ref, anchorLabel, drawingAnchorType });

      return () => {
        registerElement({ name, elementRef: null, drawingAnchorType: 'none' });
      };
    }
  }, [anchorLabel, name, ref, registerElement, drawingAnchorType]);

  const returnValue = useMemo(
    () => ({
      forceRefresh: () =>
        registerElement({
          name: latestProps.current.name,
          elementRef: latestProps.current.ref,
          anchorLabel: latestProps.current.anchorLabel,
          drawingAnchorType: latestProps.current.drawingAnchorType,
        }),
    }),
    [latestProps, registerElement]
  );
  return returnValue;
};
