import { STORAGE_PROTOCOL } from 'expertli-lib/dist/constants';

import config from '../../../config';

export const getStorageUrl = (workspaceId: string, url: string) =>
  url
    ? url.replace(
        new RegExp(`^${STORAGE_PROTOCOL}:\/\/`, 'g'),
        `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/storage/`
      )
    : url;
