"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspacePolicy = exports.workspaceClaimsByRole = void 0;
/**
 * These are claims within single workspace. These are tied to workspace
 * sessions not global user session. This is the default claims by role table
 * (policy if you like) can and should be built upon using other sources.
 */
exports.workspaceClaimsByRole = {
    ADMIN: [
        'artboard:*:any',
        'section:*:any',
        'follow:*:any',
        'upload:*:any',
        'toolbar:*:any',
        'page:*:any',
        'folder:*:any',
        'notes:*:any',
        'export:*:any',
        'viewport:*:any',
        'artboard:*:any',
        'checklist:*:any',
        'qr-code:*:any',
        'serviceability:*:any',
        'reorder-page:*:any',
        'flashback:use:any',
        'settings:*:any',
        'archive:use:any',
        'id-verification:use:any',
        'document-verification:use:any',
    ],
    EDITOR: [
        'toolbar:*:any',
        'upload:create:any',
        'upload:read:any',
        'export:*:any',
        'flashback:use:any',
        'page:*:any',
    ],
    VIEWER: ['upload:read:any', 'upload:create:any', 'flashback:use:any'],
};
var WorkspacePolicy = /** @class */ (function () {
    function WorkspacePolicy(claims) {
        if (claims === void 0) { claims = []; }
        this._claims = [];
        this.update(claims);
    }
    WorkspacePolicy.prototype.check = function (resource, action, ownership) {
        // FIXME: Hacky implementation of '*' coverage. Could look at using glob or Notation.
        return (this._claims || []).some(function (x) { return "".concat(resource, ":").concat(action, ":").concat(ownership) === x || "".concat(resource, ":*:").concat(ownership) === x; });
    };
    WorkspacePolicy.prototype.update = function (claims) {
        this._claims = claims;
    };
    Object.defineProperty(WorkspacePolicy.prototype, "canCreateFolder", {
        //#region Folders & Pages
        get: function () {
            return this.check('folder', 'create', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canUpdatePage", {
        get: function () {
            return this.check('page', 'update', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canCreatePage", {
        get: function () {
            return this.check('page', 'create', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canDeletePage", {
        get: function () {
            return this.check('page', 'delete', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canReorderPage", {
        get: function () {
            return this.check('reorder-page', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canUseFlashback", {
        //#endregion
        get: function () {
            return this.check('flashback', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canViewViewports", {
        get: function () {
            return this.check('viewport', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canExportPage", {
        get: function () {
            return this.check('export', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canUseToolbar", {
        get: function () {
            return this.check('toolbar', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canUseFollow", {
        get: function () {
            return this.check('follow', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canCreateUpload", {
        get: function () {
            return this.check('upload', 'create', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canAccessQRCode", {
        get: function () {
            return this.check('qr-code', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canEditNotes", {
        get: function () {
            return this.check('notes', 'update', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canEditChecklist", {
        //#region Arboard
        get: function () {
            return this.check('checklist', 'update', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canCreateArtboard", {
        //#endregion
        //#region Arboard
        get: function () {
            return this.check('artboard', 'create', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canDeleteArtboard", {
        get: function () {
            return this.check('artboard', 'delete', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canCreateServiceability", {
        //#endregion
        //#region Home loan serviceability
        get: function () {
            return this.check('serviceability', 'create', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canAccessSettings", {
        //#endregion
        get: function () {
            return this.check('settings', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canReadFiles", {
        get: function () {
            return this.check('upload', 'read', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canArchive", {
        get: function () {
            return this.check('archive', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canUseIdVerification", {
        get: function () {
            return this.check('id-verification', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WorkspacePolicy.prototype, "canVerifyDocuments", {
        get: function () {
            return this.check('document-verification', 'use', 'any');
        },
        enumerable: false,
        configurable: true
    });
    return WorkspacePolicy;
}());
exports.WorkspacePolicy = WorkspacePolicy;
