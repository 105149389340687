import { useCallback, useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import { JsonValue } from 'type-fest';

import { useDistributedPageContext } from '.';

export type SetStateOptions = {
  doNotSetRemote?: boolean;
  doNotSetLocal?: boolean;
  transactionId?: string;
};

export type DistributedStateOptions<T> = {
  key: string;
  pageId: string;
  initialState?: T;
};

export function useDistributedState<T extends JsonValue>(
  options: DistributedStateOptions<T>
): [T, (value: T | ((oldValue: T) => T), options?: SetStateOptions) => void];
export function useDistributedState<T extends JsonValue>(
  key: string,
  initialState?: T
): [T, (value: T | ((oldValue: T) => T), options?: SetStateOptions) => void];

export function useDistributedState<T extends JsonValue>(
  arg1: DistributedStateOptions<T> | string,
  arg2?: T
): [T, (value: T | ((oldValue: T) => T), options?: SetStateOptions) => void] {
  const { getValue, setValue, pages, subscribeValue, unsubscribeValue } =
    useDistributedPageContext();
  const [, forceUpdate] = useReducer((s) => s + 1, 0);

  const [key, initialState, pageId] =
    typeof arg1 === 'object' ? [arg1.key, arg1.initialState, arg1.pageId] : [arg1, arg2, undefined];

  //initialstate is being stored in a ref - if an object was passed in eg {a: 1, b: 2} it would cause it to mutate with every render
  const initialStateRef = useRef<unknown>(initialState);
  const setState: (value: T | ((oldValue: T) => T), options?: SetStateOptions) => void =
    useCallback(
      (value, options) => {
        return setValue<T>(key, value, {
          doNotSetLocal: options?.doNotSetLocal,
          doNotSetRemote: options?.doNotSetRemote,
          initialValue: initialStateRef.current as never,
          transactionId: options?.transactionId,
          ...(pageId ? { pageId } : {}),
        });
      },
      [key, pageId, setValue]
    );

  useLayoutEffect(() => {
    initialStateRef.current = initialState;
  });

  useEffect(() => {
    if (pageId) {
      const unSubscribe = pages.subscribe((p) => p?.[pageId]?.fields?.[key], forceUpdate);
      return unSubscribe;
    } else {
      subscribeValue(key, forceUpdate);
      return () => {
        unsubscribeValue(key, forceUpdate);
      };
    }
  }, [key, subscribeValue, unsubscribeValue, pageId, pages]);

  const value = pageId
    ? (pages.getValue()?.[pageId]?.fields?.[key] as T) || (initialState as T)
    : getValue<T>(key, initialState as any);

  return [value, setState];
}
