import { useMutation } from 'react-query';

import { client } from '@expertli/lib/axios';

import config from '../../../config';

export const storageRename: (
  workspaceId: string,
  fileName: string,
  newFileName: string
) => Promise<{ newFileName: string }> = async (workspaceId, fileName, newFileName) => {
  const result = await client.patch<{ newFileName: string }>(
    `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/storage/${fileName}`,
    { newFileName }
  );
  return result.data;
};

export function useStorageRename(workspaceId: string, fileName: string, newFileName: string) {
  const { isLoading, data, mutateAsync, error, isError } = useMutation(() =>
    storageRename(workspaceId, fileName, newFileName)
  );
  return {
    isLoading,
    response: data,
    error,
    isError,
    storageUpload: mutateAsync,
  };
}
