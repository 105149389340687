"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EMPTY_INIT_PARAMS = exports.GET_EMPTY_POINTER = exports.GET_EMPTY_PARTICIPANT_DEVICE = exports.GET_EMPTY_PARTICIPANT = exports.GET_EMPTY_VIEWPORT = exports.GET_EMPTY_POLICY = exports.GET_EMPTY_CONVERSATION = exports.GET_EMPTY_PAGE = void 0;
const constants_1 = require("../constants");
const models_1 = require("../models");
const GET_EMPTY_PAGE = () => ({
    index: 0,
    pageType: 'blank-a4-landscape',
    artifacts: {},
    fields: {},
    drawing: { elements: {}, order: [], uniqueId: '' },
    label: '',
    attachments: {},
    note: {
        isEditing: false,
        participantId: '',
        formattedText: '',
        plainText: '',
    },
    createdByParticipantId: '',
    createdTimestamp: Date.now(),
});
exports.GET_EMPTY_PAGE = GET_EMPTY_PAGE;
const GET_EMPTY_CONVERSATION = () => {
    const result = {
        participants: {},
        foci: {},
        followingUsers: {},
        pages: {
            [constants_1.PAGEID_VIDEO_GALLERY]: {
                ...(0, exports.GET_EMPTY_PAGE)(),
                pageType: 'video-gallery',
                label: models_1.PAGE_TYPES['video-gallery'].defaultLabel ?? '',
            },
            [constants_1.PAGEID_SCREEN_SHARE]: {
                ...(0, exports.GET_EMPTY_PAGE)(),
                pageType: 'screen-share',
                hideFromNav: true,
                label: models_1.PAGE_TYPES['screen-share'].defaultLabel ?? '',
            },
            [constants_1.PAGEID_SETTINGS]: {
                ...(0, exports.GET_EMPTY_PAGE)(),
                pageType: 'settings',
                hideToolbar: true,
                parentId: constants_1.PAGEID_VIDEO_GALLERY,
                label: models_1.PAGE_TYPES['settings'].defaultLabel ?? '',
            },
        },
        navData: [],
        pointers: {},
        spotlight: {
            participantId: '',
            highlight: null,
            pageId: null,
        },
        viewports: {},
        participantDevices: {},
        sessions: [],
        sessionRecordingOptions: {
            recording: true,
            updatedByParticipantId: null,
            updatedTimestamp: null,
        },
        commentary: {},
        lastMessageTimestamp: {},
    };
    return result;
};
exports.GET_EMPTY_CONVERSATION = GET_EMPTY_CONVERSATION;
const GET_EMPTY_POLICY = () => ({
    canDelete: false,
    canRename: false,
    canAnnotate: false,
    canZoom: false,
    canExport: false,
    canManuallyCreate: false,
    canDuplicate: false,
    canSaveAsTemplate: false,
    canReorder: false,
    canRedact: false,
});
exports.GET_EMPTY_POLICY = GET_EMPTY_POLICY;
const GET_EMPTY_VIEWPORT = () => ({
    offset: { x: 0, y: 0 },
    pageId: '',
    scale: 1,
    flashback: null,
});
exports.GET_EMPTY_VIEWPORT = GET_EMPTY_VIEWPORT;
const GET_EMPTY_PARTICIPANT = () => ({
    name: 'Guest',
    status: 'connecting',
    userId: '',
    connectUTCTimestamp: Date.now(),
    colorIndex: 0,
});
exports.GET_EMPTY_PARTICIPANT = GET_EMPTY_PARTICIPANT;
const GET_EMPTY_PARTICIPANT_DEVICE = () => ({
    screen: { width: 0, height: 0, orientation: 'landscape', deviceType: 'desktop' },
});
exports.GET_EMPTY_PARTICIPANT_DEVICE = GET_EMPTY_PARTICIPANT_DEVICE;
const GET_EMPTY_POINTER = () => ({
    position: null,
    currentTool: constants_1.DEFAULT_TOOL,
    live: true,
});
exports.GET_EMPTY_POINTER = GET_EMPTY_POINTER;
const GET_EMPTY_INIT_PARAMS = () => ({
    participant: {
        name: '',
        status: 'active',
        userId: '',
        colorIndex: 0,
        connectUTCTimestamp: Date.now(),
    },
    participantDevice: {},
    participantId: '',
    workspaceId: '',
});
exports.GET_EMPTY_INIT_PARAMS = GET_EMPTY_INIT_PARAMS;
