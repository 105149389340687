import { useMutation, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { PermitOrganisationForWorkspaceRequest } from '../types';
import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

async function permitOrg(workspaceId: string, request: PermitOrganisationForWorkspaceRequest) {
  await client.post(`${apis.WORKSPACES}/${workspaceId}/permissions/organisation`, request);
}

export function usePermitOrganisationForWorkspace(workspaceId: string) {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (request: PermitOrganisationForWorkspaceRequest) => permitOrg(workspaceId, request),
    {
      onSuccess: () => queryClient.invalidateQueries(WorkspaceKeys.orgPermission(workspaceId)),
    }
  );
  return {
    isLoading,
    response: data,
    error,
    isError,
    isSuccess,
    permitOrganisation: mutateAsync,
  };
}
