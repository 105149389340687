import { Button, Grid, Stack, Typography, styled } from '@mui/material';
import Router from 'next/router';
import { useEffectOnce } from 'usehooks-ts';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import config from '@expertli/config';
import logger from '@expertli/logging';

const { NEXT_PUBLIC_EXPERTLI_URL } = config;

const Root = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}));

const Image = styled('img')(() => ({
  width: 280,
  height: 280,
}));

export function GlobalErrorFallback({
  error,
}: {
  error: Error | ErrorResponse;
  resetErrorBoundary: () => void;
}) {
  useEffectOnce(() => {
    logger.error({ error }, `${error.message}`);
  });

  const errorDetails = (error as ErrorResponse).code || error.message || error.name;

  return (
    <Root>
      <Grid
        item
        container
        xs={12}
        sm={8}
        md={6}
        lg={4}
        xl={3}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Image src="/images/error.svg" alt="Not Found" />
        <Typography variant="h3" component="h1">
          Oops! Something went wrong.
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, marginTop: 2, maxWidth: 480, color: 'text.secondary' }}
        >
          Please wait a few moments and refresh the page, or contact us on&nbsp;
          <a
            href={`mailto:support@expertli.com?subject=${encodeURIComponent(
              'Error with website'
            )}&body=${encodeURIComponent(error.toString())}`}
          >
            support@expertli.com
          </a>
        </Typography>
        <Typography>
          Error: <code>{errorDetails}</code>
        </Typography>

        <Grid container direction="row" spacing={2} mt={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => Router.push(NEXT_PUBLIC_EXPERTLI_URL)}
              fullWidth
            >
              ← Back to dashboard
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
              fullWidth
            >
              Refresh the page
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
}
