import { ZIndex } from '@mui/material/styles/zIndex';

declare module '@mui/material/styles/zIndex' {
  export interface ZIndex {
    flashback_vignette: number;
    modal: number;
    snackbar: number;
    tooltip: number;
    stickies: number;
    spotlight: number;
    overlays: number;
    video: number;
  }
}

export const ZINDEX: ZIndex = {
  stickies: 700,
  flashback_vignette: 750,
  spotlight: 800,
  video: 900,
  overlays: 900,
  //defaults
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};
