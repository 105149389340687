import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import { client } from '@expertli/lib/axios';

import { UserReviewRequest } from '../types';
import { apis } from '../urls';

export async function createReview(workspaceId: string, request: UserReviewRequest) {
  await client.post(`${apis.WORKSPACES}/${workspaceId}/review`, request);
}
export function useCreateReview(workspaceId: string) {
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (request: UserReviewRequest) => createReview(workspaceId, request)
  );
  return {
    isLoading,
    response: data,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    createWatcher: mutateAsync,
  };
}
