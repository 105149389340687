import { useQuery, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';
import { GetWorkspaceResponse } from '@expertli/pages/api/workspaces/[id]';

import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

async function getWorkspace(workspaceId: string) {
  const response = await client.get(`${apis.WORKSPACES}/${workspaceId}`);
  return response.data as GetWorkspaceResponse;
}

export function useGetWorkspace(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(WorkspaceKeys.details(workspaceId), () =>
    getWorkspace(workspaceId)
  );

  const invalidate = async () => {
    await queryClient.invalidateQueries(WorkspaceKeys.details(workspaceId));
  };

  return {
    isLoading,
    workspace: data,
    invalidate,
  };
}
