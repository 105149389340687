import type { File as MulterFile } from 'fastify-multer/lib/interfaces';
import { QueryClient, useMutation, useQueryClient } from 'react-query';

import { UPLOAD_MAX_FILE_SIZE } from 'expertli-lib/dist/constants';

import { client } from '@expertli/lib/axios';

import config from '../../../config';
import { WorkspaceKeys } from './query-keys';

export const storageUpload: (
  workspaceId: string,
  file: File,
  action?: 'upsert' | 'create' | 'suffix',
  queryClient?: QueryClient,
  progressCallback?: (progress: number) => void
) => Promise<MulterFile[]> = async (
  workspaceId,
  file,
  action = 'upsert',
  queryClient,
  progressCallback
) => {
  if (file.size > UPLOAD_MAX_FILE_SIZE) throw new Error('Upload file too large');

  var bodyFormData = new FormData();
  bodyFormData.append('image', file);
  const result = await client.post<MulterFile[]>(
    `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/storage${
      action ? '?action=' + action : ''
    }`,
    bodyFormData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress(progress) {
        const loaded = progress?.loaded ?? 0;
        const total = progress?.total ?? 0;
        const progressValue = total === 0 ? 0 : Math.max(Math.min(100, (loaded / total) * 100), 0);
        progressCallback?.(progressValue);
      },
    }
  );
  queryClient?.invalidateQueries(WorkspaceKeys.attachments(workspaceId));
  return result.data;
};

export function useStorageUpload(workspaceId: string, file: File) {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError } = useMutation(() =>
    storageUpload(workspaceId, file, 'upsert', queryClient)
  );
  return {
    isLoading,
    response: data,
    error,
    isError,
    storageUpload: mutateAsync,
  };
}
