import { AxiosError } from 'axios';
import Router from 'next/router';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, useState } from 'react';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider, isError } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import urlParse from 'url-parse';

import type { ErrorResponse } from 'expertli-lib/dist/errors';

import { pages } from '@expertli/features/auth/urls';
import { isAxiosError } from '@expertli/lib/axios';
import logger from '@expertli/logging';

import { getCurrentUrl } from '../lib/get-current-url';

type Props<T> = {
  dehydratedState: any;
} & PropsWithChildren<T>;

export function QueryClientWrapper<T>({ children, dehydratedState }: Props<T>) {
  const { enqueueSnackbar } = useSnackbar();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error) => {
            if (isAxiosError(error)) {
              enqueueSnackbar(
                ((error as AxiosError).response?.data as Error)?.message ||
                  'An error has occurred, please try again.',
                { variant: 'error' }
              );

              if (error.response) {
                if (error.response.status >= 500) {
                  logger.error({ error });
                } else if (error.response.status === 401) {
                  if ((error.response.data as ErrorResponse)?.code === 'session_expired') {
                    let callbackUrl = (Router.query?.callbackUrl as string) || window.location;
                    let idleRedirectUrl = urlParse(
                      `${pages.LOGGED_OUT}?variant=sessionExpired`,
                      true
                    );
                    idleRedirectUrl.set('query', {
                      ...idleRedirectUrl.query,

                      // Set the callbackUrl to existing callbackUrl or current URL
                      callbackUrl,
                    });
                    // Log out first, then redirect to the original idleRedirectUrl
                    const redirectUrl = `${pages.LOG_OUT}?callbackUrl=${encodeURIComponent(
                      idleRedirectUrl.toString()
                    )}`;

                    Router.push(redirectUrl, undefined, { shallow: false });
                  }
                  logger.warn({
                    msg: 'Unauthorized API response (status 401)',
                    apiUrl: error.config.url,
                  });
                }
              } else {
                logger.error({ error });
              }
            } else if (isError(error)) {
              enqueueSnackbar(error.message || 'An error has occurred, please try again.', {
                variant: 'error',
              });
              logger.error({ error });
            } else {
              enqueueSnackbar('An error has occurred, please try again.', { variant: 'error' });
            }
          },
        }),
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      {process?.env?.NODE_ENV === 'development' && (
        <ReactQueryDevtools toggleButtonProps={{ style: { left: '10px', bottom: '-3px' } }} />
      )}
      <Hydrate state={dehydratedState}>{children}</Hydrate>
    </QueryClientProvider>
  );
}
