import {
  ThemeProvider as MUIThemeProvider,
  ThemeOptions,
  createTheme,
  darken,
  getContrastRatio,
  lighten,
} from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import { ColorComponents, getUserColors } from 'expertli-lib/dist/utils';

import { useWorkspaceContext } from '../workspace/context';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { CustomShadowOptions, customShadows } from './shadows';
import typography from './typography';
import { ZINDEX } from './zindex';

export type ThemeMode = 'dark' | 'light';

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: CustomShadowOptions;
    mode: ThemeMode;
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions;
    mode?: ThemeMode;
  }
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  forceMode?: ThemeMode | 'expertli';
};

export function ThemeProvider({ children, forceMode }: Props) {
  const orgSettings = useWorkspaceContext()?.workspace?.organisation;
  const mode = forceMode ?? (orgSettings?.darkMenuBackground ? 'dark' : 'light') ?? 'light';
  const isLight = mode !== 'dark';

  const theme: ThemeOptions = useMemo(() => {
    const colorFuncDefault: (index: string | number) => ColorComponents = (index) => {
      return getUserColors(index);
    };

    const colorFunc: (index: string | number) => ColorComponents = (index) => {
      return getUserColors(index, orgSettings?.defaultExpertColour);
    };

    const customColors =
      (orgSettings?.mainColour ?? '') === ''
        ? {}
        : {
            secondary: {
              main: orgSettings.mainColour,
              dark: darken(orgSettings?.mainColour, 0.15),
              darker: darken(orgSettings?.mainColour, 0.3),
              light: lighten(orgSettings?.mainColour, 0.15),
              lighter: lighten(orgSettings?.mainColour, 0.3),
              contrastText: getContrastRatio(orgSettings.mainColour, '#fff') >= 3 ? '#fff' : '#000',
            },
          };

    const options: ThemeOptions = {
      palette: {
        ...(mode === 'dark' ? palette.dark : palette.light),
        ...customColors,
        getUserColor:
          (orgSettings?.defaultExpertColour ?? '') === '' ? colorFuncDefault : colorFunc,
      },
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: {
        ...(isLight ? customShadows.light : customShadows.dark),
      },
      mode: isLight ? 'light' : 'dark',
      zIndex: ZINDEX,
    };

    const newTheme = createTheme(options);
    newTheme.components = componentsOverride(newTheme);
    return newTheme;
  }, [isLight, mode, orgSettings?.defaultExpertColour, orgSettings?.mainColour]);

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
