import { FC, createContext, useCallback, useMemo, useState } from 'react';

import { ConfirmationDialog, InputDialog, ProgressBarDialog, SpinnerDialog } from '../components';
import {
  ConfirmOptions,
  DialogContextProps,
  InputOptions,
  ProgressBarOptions,
  SpinnerOptions,
} from '../model/dialog';

const initialState: DialogContextProps = {
  alert: async () => {},
  confirm: async () => false,
  input: async () => false as false,
  spinner: async () => {},
  progressBar: async () => {},
};

export const DialogContext = createContext<DialogContextProps>(initialState);

export const DialogProvider: FC = ({ children }) => {
  const [confirmationMessage, setConfirmationMessage] = useState<
    ConfirmOptions & { onOk: () => void; onCancel?: () => void }
  >();
  const [inputMessage, setInputMessage] = useState<
    InputOptions & { onOk: (value: string) => void; onCancel: () => void }
  >();
  const [spinnerMessage, setSpinnerMessage] = useState<SpinnerOptions>();
  const [progressBarMessage, setProgressBarMessage] = useState<ProgressBarOptions>();

  const displayConfirmation: (props: ConfirmOptions) => Promise<boolean> = useCallback(
    (props: ConfirmOptions) => {
      return new Promise<boolean>((resolve) => {
        setConfirmationMessage({
          ...props,
          onOk: () => {
            setConfirmationMessage(undefined);
            resolve(true);
          },
          onCancel: props.onCancel
            ? () => {
                props.onCancel();
                setConfirmationMessage(undefined);
                resolve(false);
              }
            : undefined,
        });
      });
    },
    []
  );

  const displayInput: (props: InputOptions) => Promise<false | string> = useCallback(
    (props: InputOptions) => {
      return new Promise<false | string>((resolve) => {
        setInputMessage({
          ...props,
          onOk: (value) => {
            setInputMessage(undefined);
            resolve(value);
          },
          onCancel: () => {
            setInputMessage(undefined);
            resolve(false);
          },
        });
      });
    },
    []
  );

  const displaySpinner: (props: SpinnerOptions) => Promise<void> = useCallback(
    async (props: SpinnerOptions) => {
      setSpinnerMessage({ ...props });
      await props.closeOnResolve();
      setSpinnerMessage(null);
    },
    []
  );

  const displayProgressBar: (props: ProgressBarOptions) => Promise<void> = useCallback(
    async (props: ProgressBarOptions) => {
      const updateProgress = (progress) => {
        setProgressBarMessage({ ...props, progress });
      };
      setProgressBarMessage({ ...props });
      await props.closeOnResolveWithProgress(updateProgress);
      setProgressBarMessage(null);
    },
    []
  );

  const handleAlert: DialogContextProps['alert'] = useCallback(async (message, title, okLabel) => {
    return new Promise<void>((resolve) => {
      setConfirmationMessage({
        message,
        okLabel,
        title,
        onOk: () => {
          setConfirmationMessage(undefined);
          resolve();
        },
      });
    });
  }, []);

  const handleConfirm = useCallback(
    async (
      message?: string & ConfirmOptions,
      title?: string,
      okLabel?: string,
      cancelLabel?: string
    ) => {
      if (typeof message === 'object' && message) {
        return await displayConfirmation({ onCancel: () => {}, ...(message as ConfirmOptions) });
      }
      return await displayConfirmation({
        message,
        okLabel,
        title,
        cancelLabel,
        onCancel: () => {},
      } as ConfirmOptions);
    },
    [displayConfirmation]
  );

  const handleInput = useCallback(
    async (
      message?: string & InputOptions,
      title?: string,
      okLabel?: string,
      cancelLabel?: string
    ) => {
      if (typeof message === 'object') {
        return await displayInput(message as InputOptions);
      }
      return await displayInput({ message, okLabel, title, cancelLabel });
    },
    [displayInput]
  );

  const handleSpinner = useCallback(
    async (
      closeOnResolve: (() => Promise<void>) & SpinnerOptions,
      message?: string,
      title?: string
    ) => {
      if (typeof closeOnResolve === 'object') {
        return await displaySpinner(message as SpinnerOptions);
      }
      return await displaySpinner({ message, title, closeOnResolve });
    },
    [displaySpinner]
  );

  const handleProgressBar = useCallback(
    async (
      closeOnResolveWithProgress: ((
        progressCallback: (progress: number) => void
      ) => Promise<void>) &
        ProgressBarOptions,
      message?: string,
      title?: string
    ) => {
      if (typeof closeOnResolveWithProgress === 'object') {
        return await displayProgressBar(closeOnResolveWithProgress as ProgressBarOptions);
      }
      return await displayProgressBar({ message, title, closeOnResolveWithProgress });
    },
    [displayProgressBar]
  );

  const returnValue = useMemo<DialogContextProps>(
    () => ({
      alert: handleAlert,
      confirm: handleConfirm,
      input: handleInput,
      spinner: handleSpinner,
      progressBar: handleProgressBar,
    }),
    [handleAlert, handleConfirm, handleInput, handleSpinner, handleProgressBar]
  );

  return (
    <DialogContext.Provider value={returnValue}>
      {inputMessage && <InputDialog {...inputMessage} />}
      {confirmationMessage && <ConfirmationDialog {...confirmationMessage} />}
      {spinnerMessage && <SpinnerDialog {...spinnerMessage} />}
      {progressBarMessage && <ProgressBarDialog {...progressBarMessage} />}
      {children}
    </DialogContext.Provider>
  );
};
