export const apis = {
  WORKSPACES: '/api/workspaces',
  ORGANISATION: 'organisation',
};

export const media = {
  CAMERA_SHUTTER: '/media/camera-shutter.wav',
  PARTY_HORN: '/media/party-horn.wav',
  TADAH: '/media/tadah.mp3',
};

export const images = {
  FLASHBACK: '/images/workspace/flashback.svg',
  FLASHBACK_YELLOW: '/images/workspace/flashback-yellow.svg',

  SHARE: '/images/workspace/share.svg',
  CALL_END: '/images/workspace/call-end.svg',

  MOBILE_SCROLL: '/images/workspace/mobile-scroll.svg',
  BURGER: '/images/workspace/burger.svg',

  CLOSE_SLICE: '/images/workspace/close-slice.svg',
  ADD: '/images/workspace/add.svg',
  ADD_GREY: '/images/workspace/add-grey.svg',
  ARTBOARD_SETTINGS: '/images/workspace/artboard-settings.svg',
  DELETE: '/images/workspace/delete.svg',
  UPLOAD: '/images/workspace/upload.svg',
  UPLOAD_SELECTED: '/images/workspace/upload-selected.svg',
  DRAG: '/images/workspace/drag.svg',
  CLOSE: '/images/workspace/close.svg',

  MORE_VERT: '/images/workspace/more-vert.svg',
  MORE_VERT_GREY: '/images/workspace/more-vert-grey.svg',

  POWERED_BY: '/images/workspace/powered-by.svg',

  DEVICE_SECONDARY: '/images/workspace/device-secondary.png',
  DEVICE_TRANSFER: '/images/workspace/device-transfer.png',

  PLAY: '/images/workspace/play.svg',
  PAUSE: '/images/workspace/pause.svg',
  SEARCH: '/images/workspace/search.svg',

  NOTES: '/images/workspace/notes.svg',
  SPEECH_BUBBLE: '/images/workspace/speech-bubble.svg',
  EXPERT_ONLY: '/images/workspace/expert-only.svg',

  ED_DEFAULT: '/images/workspace/review/ed-default.png',
  ED_BAD: '/images/workspace/review/ed-bad.png',
  ED_GOOD: '/images/workspace/review/ed-good.png',
  ED_GREAT: '/images/workspace/review/ed-great.png',
  ED_DONE: '/images/workspace/review/ed-done.gif',

  DISCO_PNG: '/images/workspace/disco.png',
  DISCO_GIF: '/images/workspace/disco.gif',
  DISCO_SMALL: '/images/workspace/disco-small.png',

  SESSION_END: '/images/workspace/session-end.svg',
  SESSION_EXPIRED: '/images/workspace/session-expired.svg',

  RECORDING: '/images/workspace/av-footer/recording.svg',
  NOT_RECORDING: '/images/workspace/av-footer/not-recording.svg',
  RECORDING_DARK: '/images/workspace/av-footer/recording-dark.svg',
  NOT_RECORDING_DARK: '/images/workspace/av-footer/not-recording-dark.svg',
};

export const pages = {
  HOME: '/',
};
