import { JsonObject } from 'type-fest';

export const WorkspaceKeys = {
  all: ['workspaces'] as const,
  lists: () => [...WorkspaceKeys.all, 'list'] as const,
  list: (filters: JsonObject) => [...WorkspaceKeys.lists(), filters] as const,
  details: (id: string) => [...WorkspaceKeys.all, 'detail', id] as const,
  userPermissions: (id: string) => [...WorkspaceKeys.all, 'permissions', 'users', id] as const,
  accessRequests: (id: string) => [...WorkspaceKeys.all, 'access', 'requests', id] as const,
  accessRequest: (id: string) => [...WorkspaceKeys.all, 'access', 'request', id] as const,
  accessRequestForToken: (id: string, token: string) =>
    [...WorkspaceKeys.all, 'access', 'request', id, token] as const,
  orgPermission: (id: string) => [...WorkspaceKeys.all, 'permissions', 'organisation', id] as const,
  people: (id: string) => [...WorkspaceKeys.all, 'people', id] as const,
  attachments: (id: string) => [...WorkspaceKeys.all, 'attachments', id] as const,
  watchers: (id: string) => [...WorkspaceKeys.all, 'watchers', id] as const,
  organisation: (id: string) => [...WorkspaceKeys.all, 'organisation', id] as const,
  recordingRules: (id: string) => [...WorkspaceKeys.all, 'recording', 'rules', id] as const,
  requiredDocuments: (id: string, userId: string, pageId: string) =>
    [...WorkspaceKeys.all, 'required-documents', id, userId, pageId] as const,
  templates: (id: string) => [...WorkspaceKeys.all, 'templates', id] as const,
};
