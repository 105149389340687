"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.levelToStatus = exports.setContext = exports.createBrowserLogger = void 0;
var browser_logs_1 = require("@datadog/browser-logs");
function createBrowserLogger(options) {
    browser_logs_1.datadogLogs.init(__assign(__assign({}, options), { sampleRate: 100, silentMultipleInit: true }));
    var logger = browser_logs_1.datadogLogs.createLogger('browser', {
        level: options.logLevel,
        handler: options.disabled ? 'console' : ['console', 'http']
    });
    return logger;
}
exports.createBrowserLogger = createBrowserLogger;
function setContext(context) {
    browser_logs_1.datadogLogs.setLoggerGlobalContext(__assign({}, context));
}
exports.setContext = setContext;
function levelToStatus(level) {
    if (level === 10 || level === 20) {
        return 'debug';
    }
    if (level === 40) {
        return 'warn';
    }
    if (level === 50) {
        return 'error';
    }
    return 'info';
}
exports.levelToStatus = levelToStatus;
