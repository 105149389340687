import { NextPage } from 'next';
import { Session } from 'next-auth';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { AppProviders } from '@expertli/components/app-providers';
import { GlobalErrorFallback } from '@expertli/components/global-error-fallback';
import { GoogleAnalytics } from '@expertli/components/google-analytics';
import config from '@expertli/config';

import '../../styles/globals.css';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, props?: any) => ReactNode;
  getTitle?: () => string;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
  pageProps: {
    session?: Session;
    dehydratedState?: any;
  };
}

function MyApp({ Component, pageProps: { session, dehydratedState, ...pageProps } }: MyAppProps) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const title = Component.getTitle ?? (() => 'Expertli');

  return (
    <AppProviders session={session} dehydratedState={dehydratedState}>
      <ErrorBoundary FallbackComponent={GlobalErrorFallback}>
        <Head>
          <title>{title()}</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Expertli.com" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta httpEquiv="Content-Language" content="en" />
        </Head>
        {config.NEXT_PUBLIC_GTM_CONTAINER_ID && <GoogleAnalytics />}
        {getLayout(<Component {...pageProps} />, pageProps)}
      </ErrorBoundary>
    </AppProviders>
  );
}

export default MyApp;
