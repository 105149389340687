"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSticky = exports.isRemovedArtifact = exports.isRedaction = exports.isTextBlock = exports.isLineSegment = void 0;
const isLineSegment = (element) => element.type && element.type === 'line-segment';
exports.isLineSegment = isLineSegment;
const isTextBlock = (element) => element && element.artifactType === 'text-block';
exports.isTextBlock = isTextBlock;
const isRedaction = (element) => element && element.artifactType === 'redaction';
exports.isRedaction = isRedaction;
const isRemovedArtifact = (element) => element && element.artifactType === 'removed';
exports.isRemovedArtifact = isRemovedArtifact;
const isSticky = (element) => element && element.artifactType === 'sticky';
exports.isSticky = isSticky;
