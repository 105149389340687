import { useMutation, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

async function removePermission(workspaceId: string, permissionId: string) {
  await client.delete(`${apis.WORKSPACES}/${workspaceId}/permissions/${permissionId}`);
}

export function useRemoveUserWorkspacePermission(workspaceId: string) {
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync, error, isError, isSuccess } = useMutation(
    (permissionId: string) => removePermission(workspaceId, permissionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WorkspaceKeys.orgPermission(workspaceId));
        queryClient.invalidateQueries(WorkspaceKeys.accessRequests(workspaceId));
        queryClient.invalidateQueries(WorkspaceKeys.userPermissions(workspaceId));
      },
    }
  );
  return {
    isLoading,
    error,
    isError,
    isSuccess,
    removePermission: mutateAsync,
  };
}
