import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

import { WorkspaceAccessRequest } from '../types';
import { WorkspaceKeys } from './query-keys';

async function getAccessRequest(workspaceId: string, token: string) {
  return await client.get<WorkspaceAccessRequest>(
    `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/access/request?accessToken=${token}`,
    {
      validateStatus: (status) => status < 400 || status === 404,
    }
  );
}
export function useGetWorkspaceAccessRequest(workspaceId: string, accessToken?: string) {
  const { data, isLoading, error, isError, isSuccess, refetch } = useQuery(
    accessToken
      ? WorkspaceKeys.accessRequestForToken(workspaceId, accessToken as string)
      : WorkspaceKeys.accessRequest(workspaceId),
    () => getAccessRequest(workspaceId, accessToken),
    {
      enabled: Boolean(accessToken),
      refetchOnWindowFocus: 'always',
    }
  );

  return {
    isLoading,
    response: data,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    isActioned: data?.status === 404,
    getAccessRequest: refetch,
  };
}
