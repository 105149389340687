import { Theme } from '@mui/material/styles';

//
//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            opacity: 0.48,
            background: theme.palette.grey[100],
            '&.MuiSelect-select': {
              paddingLeft: '6px !important',
              borderRadius: '4px !important',
              cursor: 'not-allowed !important',
            },
          },
        },
      },
    },
  };
}
