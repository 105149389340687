import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

async function startVerification(
  workspaceId: string,
  body: { userId: string; mobileNumber: string }
) {
  await client.post(
    `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/id-verification`,
    body
  );
}

export function useStartIdVerification(workspaceId: string) {
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (body: { userId: string; mobileNumber: string }) => startVerification(workspaceId, body)
  );
  return {
    isLoading,
    response: data,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    startVerification: mutateAsync,
  };
}
