"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImagePageDimensions = void 0;
const A4_LARGE_DIMENSION = 1130; // 297 * 4 / 21 * 20;
const getImagePageDimensions = (width, height) => {
    // if smaller than  the large dimension - just return the image size
    // otherwise scale large dimenstion to be A4_LARGE_DIMENSION size
    const maxImageDimension = Math.max(width, height);
    const scale = width === 0 || height === 0
        ? 1
        : maxImageDimension < A4_LARGE_DIMENSION
            ? 1
            : A4_LARGE_DIMENSION / maxImageDimension;
    return { width: width * scale, height: height * scale };
};
exports.getImagePageDimensions = getImagePageDimensions;
