import { v4 as uuid } from 'uuid';

import { RemoteDispatchAsyncFunction } from '../../hooks';

export const duplicatePage: (
  dispatchAsync: RemoteDispatchAsyncFunction,
  participantId: string,
  pageId: string,
  parentId?: string
) => Promise<string> = async (dispatchAsync, participantId, pageId, parentId) => {
  const destinationPageId = uuid();

  // this is some awesome magic here - send the duplicate request and wait for it to complete
  await dispatchAsync(
    {
      messageType: 'page-duplicate',
      destinationPageId,
      participantId,
      sourcePageId: pageId,
      parentId,
      isVolatile: true,
      isDirective: true,
    },
    {
      doNotSetLocal: true,
    }
  );
  return destinationPageId;
};
