import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import { WorkspaceSession } from 'expertli-auth';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';

const FIFTEEN_MINS_MS = 1_000 * 60 * 15;

const queryKey = (workspaceId: string) => [apis.WORKSPACES, 'session', workspaceId];
const templateQueryKey = (workspaceId: string) => [
  apis.WORKSPACES,
  'session',
  'template',
  workspaceId,
];

async function getWorkspaceSession(url: string) {
  const response = await client.get(url);
  return response.data as WorkspaceSession;
}

async function getTemplateWorkspaceSession(url: string) {
  const response = await client.get(url, {
    validateStatus: (status) => (status >= 200 && status < 300) || status == 401,
  });
  return response.data as WorkspaceSession;
}

export function useWorkspaceSession(workspaceId: string) {
  const { data, isLoading, isError, error } = useQuery(
    queryKey(workspaceId),
    () => getWorkspaceSession(`${apis.WORKSPACES}/${workspaceId}/session`),
    {
      refetchInterval: FIFTEEN_MINS_MS,
    }
  );

  return {
    isLoading,
    isError,
    error: error as AxiosError<ErrorResponse>,
    session: data,
  };
}

export function useTemplateSession(workspaceId: string) {
  const { data, isLoading, isError, error } = useQuery(
    templateQueryKey(workspaceId),
    () => getTemplateWorkspaceSession(`${apis.WORKSPACES}/${workspaceId}/template`),
    {
      refetchInterval: FIFTEEN_MINS_MS,
    }
  );

  return {
    isLoading,
    isError,
    error: error as AxiosError<ErrorResponse>,
    session: data,
  };
}
