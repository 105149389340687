import { useQuery, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';

const queryKey = (workspaceId: string) => `${apis.WORKSPACES}/${workspaceId}/log-in`;

async function getWorkspaceLogInLink(url: string) {
  const response = await client.get(url);
  return response.data as { link: string };
}

export function useGetWorkspaceLogInLink(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(queryKey(workspaceId), (ctx) =>
    getWorkspaceLogInLink(ctx.queryKey[0])
  );

  const invalidate = async () => {
    await queryClient.invalidateQueries(queryKey(workspaceId));
  };

  return {
    isLoading,
    link: data?.link,
    invalidate,
    isError,
  };
}
