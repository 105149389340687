"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCancelSignal = exports.getNotificationContent = exports.toEvent = exports.getEventKey = void 0;
const messages_1 = require("./messages");
function getEventKey(eventType, participant, pageId, extra) {
    if (eventType === 'user-joined') {
        return `${messages_1.EventTypes.UserJoined}:${participant.userId}`;
    }
    if (eventType === 'task-list-item-assigned') {
        return `${messages_1.EventTypes.TaskListItemAssigned}:${pageId}:${extra?.taskId}:${participant.userId}`;
    }
    if (eventType === 'task-list-item-attachment-uploaded') {
        return `${eventType}:${pageId}:${participant.userId}:${extra?.url}`;
    }
    if (eventType === 'document-rejected') {
        return `${eventType}:${pageId}:${extra?.documentPageId}:${participant.userId}`;
    }
    return `${eventType}:${pageId}:${participant.userId}`;
}
exports.getEventKey = getEventKey;
function toEvent(eventType, conversation, pageId, extra, directRecipients, sendImmediately) {
    const participant = conversation.participants[conversation.myParticipantId];
    const viewport = conversation.viewports[conversation.myParticipantId];
    const page = pageId ? conversation.pages?.[pageId] : undefined;
    const participants = Object.keys(conversation.participants)
        .map((x) => ({ participantId: x, participant: conversation.participants[x] }))
        .reduce((accum, curr) => {
        if (curr.participant.status === 'active') {
            accum[curr.participantId] = {
                userId: curr.participant.userId,
                name: curr.participant.name,
                status: curr.participant.status,
            };
        }
        return accum;
    }, {});
    const event = {
        messageType: 'notification-event',
        isVolatile: true,
        isDirective: true,
        UTCTimestamp: new Date().getTime(),
        participantId: conversation.myParticipantId,
        participants,
        event: {
            eventType,
            messageKey: getEventKey(eventType, participant, pageId || viewport?.pageId, extra),
            extra,
        },
        page: page
            ? {
                id: pageId || viewport?.pageId,
                label: page?.label ?? '',
                type: page?.pageType,
            }
            : undefined,
        directRecipients,
        sendImmediately,
    };
    return event;
}
exports.toEvent = toEvent;
function getNotificationContent(eventType, participant, message) {
    const { page } = message;
    switch (eventType) {
        case messages_1.EventTypes.UserJoined: {
            return `${message.event?.extra?.name || participant.name} has joined the room`;
        }
        case messages_1.EventTypes.LoanAppSubmitted: {
            return `${participant?.name} has submitted the home loan application`;
        }
        case messages_1.EventTypes.TaskListItemChecked: {
            return `${participant?.name} has interacted with checklist '${page?.label}'`;
        }
        case messages_1.EventTypes.TaskListItemAttachmentUploaded: {
            return `${participant?.name} has uploaded an attachment '${message.event?.extra?.filename}' to checklist '${page?.label}'`;
        }
        case messages_1.EventTypes.TaskListComplete: {
            return `${participant.name} has completed checklist '${page?.label}'`;
        }
        case messages_1.EventTypes.AttachmentUploaded: {
            if (page?.type === 'task-list') {
                return null;
            }
            return `${participant?.name} has uploaded an attachment '${page?.label}'`;
        }
        case messages_1.EventTypes.DocumentRejected: {
            return `'${message.event?.extra?.documentLabel || page?.label || 'Page'}' has been rejected. Reason - ${message.event?.extra?.comment}`;
        }
        case messages_1.EventTypes.CustomerNote: {
            return `A customer has left a note, click the button below to view it.`;
        }
        default:
            return null;
    }
}
exports.getNotificationContent = getNotificationContent;
function getCancelSignal(message) {
    const eventType = message.event.eventType;
    const isPageRemoved = (m) => m.event.eventType === 'page-removed' && m.page?.id === message.page?.id;
    if (eventType === 'task-list-item-checked') {
        return (m) => (m.event.eventType === 'task-list-complete' && m.page?.id === message.page?.id) ||
            isPageRemoved(m);
    }
    if (eventType === 'task-list-complete') {
        return (m) => (m.event.eventType === 'task-list-item-checked' && m.page?.id === message.page?.id) ||
            isPageRemoved(m);
    }
    if (eventType === 'user-joined') {
        // if any action comes afte user-joined
        return (m) => Boolean(m);
    }
    return isPageRemoved;
}
exports.getCancelSignal = getCancelSignal;
