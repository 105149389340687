import { useMemo, useRef } from 'react';

export type ValueChanged = (newValue: any) => void;
export type ValueRecord<BT = unknown, K extends string = string> = Record<K, BT>;
export type ChangeSet<BT = unknown> = { changed: ValueRecord<BT>; removed: ValueRecord<BT> };

type HookReturn<BT = unknown, K extends string = string> = {
  setValue: <T extends BT, K extends string>(key: K, newValue?: T) => boolean;
  getValue: <T extends BT, K extends string>(key: K, initialValue?: T) => T;
  subscribe: (key: K, onValueChanged: ValueChanged) => void;
  unsubscribe: (key: K, onValueChanged: ValueChanged) => void;
  setValues: (newValues: ValueRecord<BT, K>, deleteNonexistantValues?: boolean) => ChangeSet;
};

export const useSubscriptionState: <BT, K extends string = string>(
  initialValues?: Partial<ValueRecord<BT, K>>
) => HookReturn<BT, K> = (initialValues = {}) => {
  const values = useRef<ValueRecord>(initialValues);
  const subscriptions = useRef<Record<string, ValueChanged[]>>({});
  const result: HookReturn = useMemo(() => {
    const setValue: HookReturn['setValue'] = (key, newValue) => {
      const oldValue = values.current[key];
      if (newValue === oldValue) return false;

      values.current[key as string] = newValue;
      const subs = subscriptions.current[key] ?? [];
      subs.forEach((s) => s(newValue));
      return true;
    };
    const getValue: <T>(key: string, initialValue?: T) => T = (key, initialValue) =>
      (values.current[key] as never) ?? initialValue;

    return {
      subscribe: (key, onValueChanged) => {
        const subs = subscriptions.current[key] ?? [];
        if (subs.indexOf(onValueChanged) === -1) {
          subscriptions.current[key] = [...subs, onValueChanged];
        }
      },
      unsubscribe: (key, onValueChanged) => {
        const subs = subscriptions.current[key] ?? [];
        const subIndex = subs.indexOf(onValueChanged);
        if (subIndex !== -1) {
          const newArray = [...subs];
          newArray.splice(subIndex);
          subscriptions.current[key] = newArray;
        }
      },
      setValue,
      getValue,
      setValues: (newValues: ValueRecord, deleteNonexistantValues?) => {
        const result = { changed: {}, removed: {} };
        Object.entries(newValues).forEach(([key, value]) => {
          if (setValue(key, value)) {
            result.changed[key] = value;
          }
        });

        if (deleteNonexistantValues) {
          Object.keys(values.current).forEach((k) => {
            if (!(k in newValues)) {
              setValue(k);
              result.removed[k] = null;
            }
          });
        }
        return result;
      },
    };
  }, []);
  return result;
};
