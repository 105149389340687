import { useMutation, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { Workspace } from '../types';
import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

async function toggleWorkspaceIsPublic(workspaceId: string, isPublic: boolean) {
  const update = await client.patch(`${apis.WORKSPACES}/${workspaceId}`, { isPublic });
  return update.data as Workspace;
}

export function useToggleWorkspaceIsPublic(workspaceId: string, organisationId: string) {
  const queryClient = useQueryClient();
  const { data, mutateAsync, error, isError, isLoading } = useMutation(
    (isPublic: boolean) => toggleWorkspaceIsPublic(workspaceId, isPublic),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WorkspaceKeys.details(workspaceId));
      },
    }
  );
  return {
    workspace: data,
    isLoading,
    error,
    isError,
    toggleWorkspaceIsPublic: mutateAsync,
  };
}
