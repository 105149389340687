import { useCallback, useEffect, useReducer } from 'react';
import { JsonValue } from 'type-fest';

/**
 * Stores an object as JSON in the browser's sessionStorage
 * Normally session storage is not shared between tabs. And i rely on that in some places.
 * However when you duplicate a tab the useragent copies session storage to the duplicate.
 * (so its a kind of one time share). With this implementation you can pass in a flag (valuesShouldNotBeCopiedToDuplicatedTab)
 * if that is true i use the globalThis implementation to ensure session variables dont copy
 * (if the flag is flase it behaves like normal session storge)
 * @param key The string key to store the item against
 * @param initialValue The default value to start with
 * @param valuesShouldNotBeCopiedToDuplicatedTab indicates values should not be copied when tab duplicated
 * @returns A tuple of the instance of {T} and a function to update the value in sessionStorage
 */
export function useSessionStorage<T>(
  key: string,
  initialValue?: T,
  valuesShouldNotBeCopiedToDuplicatedTab?: boolean
): [T, (value: T | ((oldValue: T) => T)) => void, (value: T) => void] {
  const [, forceUpdate] = useReducer((s) => s + 1, 0);

  //if nothing in window pull from session storage or use default value
  if (globalThis.storedVariables?.[key] === undefined) {
    if (!globalThis.storedVariables) {
      globalThis.storedVariables = {};
    }
    const storedValue = globalThis.sessionStorage.getItem(key);
    globalThis.storedVariables[key] = storedValue ? JSON.parse(storedValue) : initialValue;
  }

  //clear after reading from sessionstorage - this will prevent duplicated tabs from picking up the session state
  if (valuesShouldNotBeCopiedToDuplicatedTab) {
    globalThis.sessionStorage?.removeItem(key);
  }

  //store in session on unload and unmount
  useEffect(() => {
    const unload = () => {
      if (globalThis.storedVariables?.[key] === undefined) {
        globalThis.sessionStorage.removeItem(key);
      } else {
        globalThis.sessionStorage.setItem(key, JSON.stringify(globalThis.storedVariables?.[key]));
      }
    };
    globalThis.addEventListener('beforeunload', unload);
    return unload;
  }, [key]);

  const setValueQuiet = useCallback(
    (value: T) => {
      if (!globalThis.storedVariables) {
        globalThis.storedVariables = {};
      }
      globalThis.storedVariables[key] = value;
    },
    [key]
  );

  const setValue = useCallback(
    (value: T | ((oldValue: T) => T)) => {
      const oldValue = globalThis.storedVariables?.[key];
      const newValue: T =
        typeof value === 'function' ? (value as (oldValue: T) => T)(oldValue) : value;
      setValueQuiet(newValue);
      forceUpdate();
    },
    [key, setValueQuiet]
  );

  return [globalThis.storedVariables?.[key], setValue, setValueQuiet];
}
