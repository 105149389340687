import { useMutation, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { PermitUserForWorkspaceRequest } from '../types';
import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

async function permitUser(workspaceId: string, request: PermitUserForWorkspaceRequest) {
  await client.post(`${apis.WORKSPACES}/${workspaceId}/permissions/users`, request);
}

export function usePermitUserForWorkspace(workspaceId: string) {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (request: PermitUserForWorkspaceRequest) => permitUser(workspaceId, request),
    {
      onSuccess: () => queryClient.invalidateQueries(WorkspaceKeys.userPermissions(workspaceId)),
    }
  );
  return {
    isLoading,
    response: data,
    error,
    isError,
    isSuccess,
    permitUser: mutateAsync,
  };
}
