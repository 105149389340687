import { useCallback, useEffect, useRef } from 'react';

/**
 *  Will always fire the first invocation immediately.
 *  subsequent invocations will wait until at least delay miliseconds to fire.
 *  When it does fire - the most recent invocation will be used , others will be discarded
 */

export type DebounceReturn = (debouncedCall: () => void) => void;

export const useDebounce: (options: {
  delay: number;
  executeIfUnmounted: boolean;
}) => DebounceReturn = ({ delay, executeIfUnmounted }) => {
  const lastCall = useRef<() => void>(null);
  const mounted = useRef(true);
  const timeoutRef = useRef(null);

  const executeCall = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (!lastCall.current) return;
    if (!executeIfUnmounted && !mounted) return;

    lastCall.current();
    lastCall.current = null;
  }, [executeIfUnmounted]);

  const debounce: DebounceReturn = useCallback(
    (debouncedCall) => {
      lastCall.current = debouncedCall;
      if (!timeoutRef.current) {
        executeCall();
        timeoutRef.current = setTimeout(executeCall, delay);
      }
    },
    [delay, executeCall]
  );

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return debounce;
};
