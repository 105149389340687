"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceSession = exports.getPublicUserIdFromCookie = exports.PUBLIC_USER_ID_COOKIE_NAME = void 0;
var cookie_1 = require("cookie");
var errors_1 = require("expertli-lib/dist/errors");
var claims_1 = require("./claims");
exports.PUBLIC_USER_ID_COOKIE_NAME = 'expertli.workspace.public-id';
var getPublicUserIdFromCookie = function (cookie) {
    var parsed = (0, cookie_1.parse)(cookie || '', {
        decode: function (value) { return value; },
    });
    if (exports.PUBLIC_USER_ID_COOKIE_NAME in parsed) {
        return parsed[exports.PUBLIC_USER_ID_COOKIE_NAME];
    }
    return null;
};
exports.getPublicUserIdFromCookie = getPublicUserIdFromCookie;
var WorkspaceSession = /** @class */ (function () {
    function WorkspaceSession(workspaceId, role, claims, userId, isPublic) {
        // These are all required arguments, and since this object is deserialized from external source,
        // we need to make sure they are not undefined.
        if (!workspaceId || !claims || !role || !Array.isArray(claims)) {
            throw new Error('Invalid arguments');
        }
        this.workspaceId = workspaceId;
        this.role = role;
        this.claims = claims;
        this.userId = userId;
        this.isPublic = isPublic;
        this.isReadOnly = this.role === 'VIEWER';
    }
    Object.defineProperty(WorkspaceSession.prototype, "policy", {
        get: function () {
            return new claims_1.WorkspacePolicy(this.claims || []);
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Returns a workspace session object relating to the contextual information provided via params
     * @param workspaceLike The relevant workspace (and organisation)
     * @param session The user's current authenticated session (null if unauthenticated)
     * @param userId The user's unique identifier (random guid for unauthenticated, stored in cookie)
     * @param role The user's delegated role from database (null if none present)
     * @returns {WorkspaceSession} The user's {WorkspaceSession} that dictates what they are permitted to do
     * @throws {Unauthorized} error if we are unable to determine the workspace role for the user, and the workspace is not public
     */
    WorkspaceSession.fromSession = function (workspaceLike, session, userId, role) {
        var id = workspaceLike.id, isPublic = workspaceLike.isPublic;
        var workspaceRole = role;
        if (!workspaceRole && isPublic) {
            workspaceRole = 'VIEWER';
        }
        if (!workspaceRole) {
            throw new errors_1.Unauthorized();
        }
        var claims = workspaceRole in claims_1.workspaceClaimsByRole ? claims_1.workspaceClaimsByRole[workspaceRole] : [];
        return new WorkspaceSession(id, workspaceRole, claims, ((session === null || session === void 0 ? void 0 : session.user.id) || userId), session === null);
    };
    return WorkspaceSession;
}());
exports.WorkspaceSession = WorkspaceSession;
