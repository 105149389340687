import { WorkspaceSession } from 'expertli-auth';

import { client } from '@expertli/lib/axios';

import { apis } from '../../urls';

export const getTemplateWorkspaceSession: (
  workspaceId: string
) => Promise<WorkspaceSession> = async (workspaceId) => {
  const workspace = await client.get<WorkspaceSession>(
    `${apis.WORKSPACES}/${workspaceId}/template`
  );
  return workspace.data;
};
