import { parseAsNumber } from './parser';
import { Config } from './types';

const config: Config = {
  ILLION_INTEGRATION_PARTNER_CODE: '52B69C08-64A4-7FD3-51B3-94F9A124981C',
  ENVIRONMENT: 'preview',
  AUTH_FROM_EMAIL: 'expertli.dev <support@expertli.dev>',
  AUTH_SMTP_SERVER: process.env.AUTH_SMTP_SERVER,
  AUTH_GITHUB_CLIENT_ID: process.env.AUTH_GITHUB_CLIENT_ID,
  AUTH_GITHUB_CLIENT_SECRET: process.env.AUTH_GITHUB_CLIENT_SECRET,
  AUTH_GOOGLE_CLIENT_ID: '348693737891-llpf6b845fh92to2apo58aigurkmgr45.apps.googleusercontent.com',
  AUTH_GOOGLE_CLIENT_SECRET: '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',
  AUTH_AZURE_AD_CLIENT_ID: process.env.AUTH_AZURE_AD_CLIENT_ID,
  AUTH_AZURE_AD_CLIENT_SECRET: process.env.AUTH_AZURE_AD_CLIENT_SECRET,
  AUTH_ATLASSIAN_CLIENT_ID: 'k3JjISmBmxxtzluAHqXLNwtnEzIPBM6S',
  AUTH_ATLASSIAN_CLIENT_SECRET:
    'ATOAhB4eVKg5Qt6HpBPmfVaYL3bkUU0v1MeSe_AdJMbmqptRTnYuvI9wWOP0oFpAtV6yC7A0936D',

  NEXT_PUBLIC_EXPERTLI_URL: process.env.NEXT_PUBLIC_EXPERTLI_URL || 'https://app.expertli.dev',
  NEXTAUTH_URL: process.env.NEXTAUTH_URL || process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  NEXT_PUBLIC_MUNCHER_URL: process.env.NEXT_PUBLIC_MUNCHER_URL as string,
  LOGGING_DISABLED: false,
  NEXT_PUBLIC_LOG_LEVEL: (process.env.NEXT_PUBLIC_LOG_LEVEL as string) || 'info',
  DATADOG_API_KEY: (process.env.DATADOG_API_KEY as string) || 'd8d968e1ec3c0e16a5c048b2b97e4ef1',
  DATADOG_CLIENT_TOKEN: 'pub2ef69633e55598e4a789b7026abafa1f',

  PDFTRON_LICENSE_KEY: process.env.NEXT_PUBLIC_PDFTRON_LICENSE_KEY as string,

  POSTMARK_API_TOKEN: process.env.POSTMARK_API_TOKEN || '43af4cb9-4a4b-44bb-b231-14301d454f06',
  QUICKLI_BEARER_TOKEN: process.env.QUICKLI_BEARER_TOKEN || '',
  QUICKLI_EXPERTLI_SECRET: '0734b213-b332-467f-aad0-56b028845259',

  CLICKSEND_USERNAME: (process.env.CLICKSEND_USERNAME as string) || 'dev@expertli.com',
  CLICKSEND_API_TOKEN:
    (process.env.CLICKSEND_API_TOKEN as string) || '86C785EB-3A96-E26E-71AA-CB2E7328ED9B',

  SUPABASE_URL: process.env.SUPABASE_URL || 'https://wktlmnhqrsniswnapruz.supabase.co',
  SUPABASE_KEY:
    process.env.SUPBASE_KEY ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndrdGxtbmhxcnNuaXN3bmFwcnV6Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY0NTA3MDE2NSwiZXhwIjoxOTYwNjQ2MTY1fQ.WMAEU4qldnTzhCt6sRLjSv4JAiHlXypWXD5eRqD4E90',

  TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID as string,
  TWILIO_API_KEY: process.env.TWILIO_API_KEY as string,
  TWILIO_API_SECRET: process.env.TWILIO_API_SECRET as string,
  TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN as string,

  NEXT_PUBLIC_EXPERTLI_API_URL:
    process.env.NEXT_PUBLIC_EXPERTLI_API_URL || 'https://api.expertli.dev',
  EXPERTLI_API_KEY: process.env.EXPERTLI_API_KEY || 'some-secret-value',
  NEXT_PUBLIC_TWILIO_LOG_LEVEL: (process.env.NEXT_PUBLIC_TWILIO_LOG_LEVEL || 'info') as string,
  NEXT_PUBLIC_GTM_CONTAINER_ID: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || undefined,
  NEXT_PUBLIC_USER_PRIVACY_VERSION: '2022-06-06',

  NEXT_PUBLIC_FEATURE_LINK_ACCOUNTS_ENABLED: true,

  NEXT_PUBLIC_FEATURE_UNDO_ENABLED: false, // Turned off unit Ed can review
  GOOGLE_MAPS_API_KEY: 'AIzaSyB_Meg3u6YiKcaz-ZxEWthWKwfGWX0SuNI',

  WORKSPACE_SESSION_IDLE_TIMEOUT: parseAsNumber(
    process.env.NEXT_PUBLIC_WORKSPACE_SESSION_IDLE_TIMEOUT,
    60 * 5
  ), // 5 minutes default
  WORKSPACE_SESSION_IDLE_LOGOUT_TIMEOUT: parseAsNumber(
    process.env.NEXT_PUBLIC_WORKSPACE_SESSION_IDLE_LOGOUT_TIMEOUT,
    30
  ), // 30 seconds to make yourself active again

  USER_SESSION_IDLE_TIMEOUT: parseAsNumber(
    process.env.NEXT_PUBLIC_USER_SESSION_IDLE_TIMEOUT,
    60 * 60 * 2
  ), // 2 hours
  USER_SESSION_IDLE_LOGOUT_TIMEOUT: parseAsNumber(
    process.env.NEXT_PUBLIC_USER_SESSION_IDLE_LOGOUT_TIMEOUT,
    60 * 2
  ), // 2 minutes
  VERSION: `v1.0.0-${(
    process.env.VERCEL_GIT_COMMIT_SHA || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
  ).substring(0, 7)}`,
};

export default config;
