import { useCallback, useEffect, useReducer } from 'react';

import { useDistributedPageContext } from '.';

export const useFocus: (fieldName: string) => {
  color: string;
  setFocus: () => void;
  hasFocus: boolean;
} = (fieldName) => {
  const {
    getFocus,
    setFocus: internalSetFocus,
    subscribeFocus,
    unsubscribeFocus,
    focusOutDetectionSuppression,
  } = useDistributedPageContext();
  const [, forceUpdate] = useReducer((s) => s + 1, 0);

  const setFocus: () => void = useCallback(
    () => internalSetFocus(fieldName),
    [fieldName, internalSetFocus]
  );

  useEffect(() => {
    subscribeFocus(fieldName, forceUpdate);
    return () => {
      unsubscribeFocus(fieldName, forceUpdate);
    };
  }, [fieldName, subscribeFocus, unsubscribeFocus]);

  const color = getFocus(fieldName);

  return { color, setFocus, hasFocus: color !== undefined, focusOutDetectionSuppression };
};
