import { useEffect } from 'react';

export const usePaste: (onPaste: (e: ClipboardEvent) => void, element?: HTMLElement) => void = (
  onPaste,
  element
) => {
  useEffect(() => {
    const eventSource = element ?? document;
    if (onPaste) {
      eventSource.addEventListener('paste', onPaste);
      return () => {
        eventSource.removeEventListener('paste', onPaste);
      };
    }
  }, [element, onPaste]);
};
