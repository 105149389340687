import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { FC, KeyboardEventHandler, useCallback } from 'react';
import { useState } from 'react';

import { trackEvent } from '@expertli/lib/analytics';

import { ConfirmOptions } from '../model/dialog';

export const ConfirmationDialog: FC<
  ConfirmOptions & { onOk: () => void; okDisabled?: boolean }
> = ({
  cancelLabel = 'Cancel',
  okLabel = 'Ok',
  onOk,
  onCancel,
  asyncExecuteOnOk,
  message,
  title,
  children,
  cancelGtmEvent,
  okGtmEvent,
  okDisabled,
}) => {
  const [loading, setLoading] = useState(false);
  const handleOk = useCallback(async () => {
    if (asyncExecuteOnOk) {
      setLoading(true);
      await asyncExecuteOnOk();
    }
    if (okGtmEvent) {
      trackEvent(okGtmEvent);
    }
    onOk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncExecuteOnOk, onOk]);

  const handleCancel = useCallback(async () => {
    if (cancelGtmEvent) {
      trackEvent(cancelGtmEvent);
    }
    onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCancel]);

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleOk();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [handleOk]
  );

  return (
    <MuiDialog onKeyDown={handleKeyDown} open onClose={handleCancel ?? handleOk} fullWidth>
      {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
      {(message || children) && (
        <MuiDialogContent>
          {message}
          {children}
        </MuiDialogContent>
      )}
      <MuiDialogActions>
        {onCancel && (
          <Button
            sx={{ minWidth: '120px' }}
            disabled={loading}
            variant="outlined"
            onClick={handleCancel}
          >
            {cancelLabel}
          </Button>
        )}
        <LoadingButton
          sx={{ minWidth: '120px' }}
          variant="contained"
          disableElevation
          loading={loading}
          onClick={handleOk}
          disabled={okDisabled}
        >
          {okLabel}
        </LoadingButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
