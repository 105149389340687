import { useSessionStorage } from '.';

export const TODO_TARGET_UNSET = 'UNSET';
export const TODO_TARGET_NEW = 'NEW';

export const useTodoTargetPage: () => {
  todoTargetPageId: string;
  setTodoTargetPageId: (pageId: string) => void;
} = () => {
  const [todoTargetPageId, setTodoTargetPageId] = useSessionStorage<string>(
    'targetPageId',
    TODO_TARGET_UNSET
  );

  return { todoTargetPageId, setTodoTargetPageId };
};
