import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Socket, io } from 'socket.io-client';

import { BridgeDispatchFunction, DataBridge, TransportEvents } from 'expertli-lib/dist/models';

import parentLogger from '@expertli/logging';

import { DataBridgeContext } from '.';
import config from '../../../config';

export type SocketsDataBridgeProviderProps = { workspaceId: string; participantId: string };

const logger = parentLogger.child({ component: 'SocketsDataBridgeProvider' });

class SocketReconnectError extends Error {}

export const SocketsDataBridgeProvider: FC<SocketsDataBridgeProviderProps> = ({
  workspaceId,
  participantId,
  children,
}) => {
  const handleError = useErrorHandler();
  const handleMessage = useRef<BridgeDispatchFunction>(null);
  const socket = useRef<Socket<TransportEvents, TransportEvents>>();
  const onReceive: (handler: BridgeDispatchFunction) => void = useCallback((handler) => {
    handleMessage.current = handler;
  }, []);
  const send: BridgeDispatchFunction = useCallback((message, callback) => {
    if (socket.current) {
      socket.current.emit(
        'data',
        message,
        callback ? (...args: unknown[]) => setTimeout(() => callback(...args), 0) : undefined
      );
    } else {
      logger.warn('Attempting to send message on disconnected socket');
    }
  }, []);

  const [bridge, setBridge] = useState<DataBridge>({ onReceive, send: null });

  useEffect(
    () => {
      return () => {
        logger.debug('Component unmounted, disconnecting socket');

        if (socket.current?.connected) {
          socket.current.disconnect();
          socket.current = null;
        }
      };
    },
    [] // Run once on mount
  );

  useEffect(() => {
    socket.current = io(`${config.NEXT_PUBLIC_MUNCHER_URL}/workspaces/${workspaceId}`, {
      reconnectionAttempts: 5,
      transports: ['websocket', 'polling'],
      withCredentials: true,
    });

    socket.current.on('disconnect', (reason, description) => {
      logger.info({ reason, description }, 'WebSocket#disconnect');
      setBridge({ onReceive, send: null });
    });
    socket.current.on('connect', () => {
      setBridge({ onReceive, send });
    });
    socket.current.io.on('reconnect_error', (error) => {
      logger.error({ error }, 'WebSocket#reconnect_error');
    });
    socket.current.io.on('reconnect_failed', () => {
      socket.current.removeAllListeners();
      socket.current.disconnect();
      socket.current.io.reconnection(false);
      socket.current.io._close();
      socket.current = null;
      setBridge({ onReceive, send: null });
      handleError(new SocketReconnectError(`Failed to reconnect to WebSocket`));
    });
    socket.current.on('connect_error', (error) => {
      // TODO: Error cannot be a custom one (i.e., only has message field) and when authorisation
      // fails, there is no "data channel". We'd probably want a new message that send them to
      // login page with toast (possible: /login?error=msg)
      if (error.message.toLowerCase().includes('unauthorised')) {
        handleMessage.current({
          messageType: 'alert-display',
          type: 'error',
          code: 'unauthorised',
          text: 'Unauthorised',
          participantId: null,
          isVolatile: true,
        });
      }

      logger.error({ error }, error.message, {
        component: 'SocketsDataBridgeProvider',
      });
    });
    socket.current.on('data', (msg) => {
      try {
        handleMessage.current(msg);
      } catch (error) {
        logger.error({ error }, 'Error handling recieved message');
      }
    });
  }, [onReceive, send, workspaceId, participantId, handleError]);

  return <DataBridgeContext.Provider value={bridge}>{children}</DataBridgeContext.Provider>;
};
