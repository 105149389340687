"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNotificationEventMessage = exports.EventTypes = void 0;
var EventTypes;
(function (EventTypes) {
    EventTypes["UserJoined"] = "user-joined";
    EventTypes["LoanAppSubmitted"] = "loan-app-submitted";
    EventTypes["LoanAppServiceability"] = "loan-app-serviceability";
    EventTypes["TaskListComplete"] = "task-list-complete";
    EventTypes["TaskListItemChecked"] = "task-list-item-checked";
    EventTypes["TaskListItemAttachmentUploaded"] = "task-list-item-attachment-uploaded";
    EventTypes["TaskListItemAssigned"] = "task-list-item-assigned";
    EventTypes["TaskListAssigned"] = "task-list-assigned";
    EventTypes["AttachmentUploaded"] = "attachment-uploaded";
    EventTypes["PageRemoved"] = "page-removed";
    EventTypes["DocumentRejected"] = "document-rejected";
    EventTypes["CustomerNote"] = "customer-note";
})(EventTypes = exports.EventTypes || (exports.EventTypes = {}));
const isNotificationEventMessage = (msg) => msg.messageType === 'notification-event';
exports.isNotificationEventMessage = isNotificationEventMessage;
