import { client } from '@expertli/lib/axios';

import { apis } from '../urls';

export async function forceExpertAccess(
  organisationId: string,
  userId: string,
  workspaceId: string
) {
  await client.post(
    `/api/organisations/${organisationId}/users/${userId}/workspaces/${workspaceId}/forceexpert`
  );
}
