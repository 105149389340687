import { useCallback, useEffect, useReducer, useRef } from 'react';

export const useForceUpdate: () => () => void = () => {
  const [, updateReducer] = useReducer((s) => s + 1, 0);
  const isMounted = useRef<boolean>(false);

  const forceUpdate = useCallback(() => {
    if (isMounted) updateReducer();
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  return forceUpdate;
};
