import { LocalConversation } from 'expertli-lib/dist/conversation';
import {
  GET_EMPTY_INIT_PARAMS,
  GET_EMPTY_VIEWPORT,
} from 'expertli-lib/dist/conversation/empty-conversation';
import {
  AnnotationLinePointAddMessage,
  Message,
  isAnnotationLineEndMessage,
  isPageMessage,
} from 'expertli-lib/dist/models';
import { getMessagesByPage, getUserColors } from 'expertli-lib/dist/utils';

import { A4_LARGE_DIMENSION, A4_SMALL_DIMENSION } from '../../constants';
import { RegisteredElement } from '../../context';
import { drawToDataUrl } from '../draw-to-canvas';
import { getStencilWorkspaceId } from './get-stencil-workspace-id';
import { getWorkspaceMessages } from './get-workspace-messages';

export type StencilDetails = {
  width: number;
  height: number;
  thumbnailDataUrl: string;
  label: string;
  pageId: string;
  getOverlayDataUrl: () => Promise<string>;
  includesPhrase: (phrase: string) => number;
  getMessages: (particiantId: string, targetPageId: string, addInterimSteps?: boolean) => Message[];
};

export const getStencils: (workspaceId: string) => Promise<{
  stencils: Record<string, StencilDetails>;
  stencilWorkspaceId?: string;
}> = async (workspaceId) => {
  //its ok to mutate stuff - this is not react code
  // get stencil messages
  const stencilWorkspaceId = await getStencilWorkspaceId(workspaceId);
  if (!stencilWorkspaceId) return { stencils: {}, thumbnails: {}, dimensions: {} };
  const messages = await getWorkspaceMessages(stencilWorkspaceId);

  const conv = new LocalConversation(GET_EMPTY_INIT_PARAMS());
  conv.dispatch(messages.filter((m) => isPageMessage(m)));
  const convState = conv.getState();

  //extract stencils
  const pages = getMessagesByPage(messages);
  const keys = Object.keys(pages);
  const stencils: Record<string, StencilDetails> = {};

  const stencilColor = getUserColors('stencil');

  //spin through stencils
  await Promise.all(
    keys.map(async (pageId) => {
      const convPage = convState.pages[pageId];
      if (convPage.pageType === 'blank-a4-landscape' || convPage.pageType === 'image-single') {
        const stencil: Partial<StencilDetails> = {
          pageId,
          label: convPage.label,
        };

        if (convPage.pageType === 'blank-a4-landscape') {
          stencil.width = A4_LARGE_DIMENSION;
          stencil.height = A4_SMALL_DIMENSION;
        } else {
          stencil.width = convPage.fields?.['image']?.['width'] ?? 0;
          stencil.height = convPage.fields?.['image']?.['height'] ?? 0;
        }

        const scale = 100 / Math.max(1, stencil.height, stencil.width);
        const magnification = Math.min(
          Math.max(Math.max(1, stencil.height, stencil.width) / 100, 1),
          10
        );

        //TODO remove object mutation
        //adjust line intensity
        Object.values(convPage.drawing.elements).forEach((e) => {
          if (e.mode === 'pen') {
            (e as any).width = e.width * magnification;
          }
        });

        const registeredElements: Record<string, RegisteredElement> = {
          image: {
            boundingRect: { left: 0, top: 0, height: stencil.height, width: stencil.width },
            elementRef: { current: null },
            drawingAnchorType: 'y',
            alpha: 1,
          },
          page0: {
            boundingRect: { left: 0, top: 0, height: stencil.height, width: stencil.width },
            elementRef: { current: null },
            drawingAnchorType: 'y',
            alpha: 1,
          },
        };

        //construct thumbnail
        stencil.thumbnailDataUrl = await drawToDataUrl(
          convPage.drawing,
          convPage.artifacts,
          { deviceType: 'desktop', height: 100, width: 100, orientation: 'landscape' },
          {
            ...GET_EMPTY_VIEWPORT(),
            offset: { x: 50 - (scale * stencil.width) / 2, y: 50 - (scale * stencil.height) / 2 },
            pageId,
            scale,
          },
          {},
          () => stencilColor,
          registeredElements
        );

        stencil.getMessages = (particiantId, targetPageId, addInterimSteps) => {
          const messages = pages[pageId].getMessages(
            particiantId,
            undefined,
            targetPageId
          ).messages;
          if (!addInterimSteps) {
            return messages;
          }

          const output: Message[] = [];
          messages.forEach((m) => {
            if (isAnnotationLineEndMessage(m)) {
              (m.points ?? []).forEach((p) => {
                const pointMessage: AnnotationLinePointAddMessage = {
                  messageType: 'annotation-line-point-add',
                  isVolatile: true,
                  elementId: m.elementId,
                  mode: m.mode,
                  pageId: m.pageId,
                  participantId: m.participantId,
                  point: [...p],
                  width: m.width,
                };
                output.push(pointMessage);
              });
            }
            output.push(m);
          });
          return output;
        };

        //construct overlay
        stencil.getOverlayDataUrl = async () => {
          const dataUrl = await drawToDataUrl(
            convPage.drawing,
            convPage.artifacts,
            {
              deviceType: 'desktop',
              height: stencil.height,
              width: stencil.width,
              orientation: 'landscape',
            },
            { ...GET_EMPTY_VIEWPORT(), pageId: stencil.pageId },
            {},
            () => stencilColor,
            registeredElements
          );
          return dataUrl;
        };

        stencil.includesPhrase = (phrase) => {
          const normalised = (' ' + convPage.note.plainText + ' ' + convPage.label + ' ')
            .replace(/[^a-zA-Z0-9]+/g, ' ')
            .toLowerCase();
          const tokens = phrase
            .replace(/[^a-zA-Z0-9]+/g, ' ')
            .toLowerCase()
            .trim()
            .split(' ');

          let foundTokens = 0;
          tokens.forEach(() => {
            if (normalised.includes(' ' + phrase.toLowerCase())) {
              foundTokens++;
            }
          });
          return foundTokens;
        };

        //TODO remove object mutation
        //restore line intensity
        Object.values(convPage.drawing.elements).forEach((e) => {
          if (e.mode === 'pen') {
            (e as any).width = e.width / magnification;
          }
        });
        stencils[pageId] = stencil as StencilDetails;
      }
    })
  );

  return {
    stencils,
    stencilWorkspaceId,
  };
};
