"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkspaceRequiredDocuments = exports.getRequiredDocumentsFromPage = void 0;
const getTaskDetails = (fields, itemId) => {
    return {
        itemId,
        label: fields[`task.${itemId}.label`],
        checked: fields[`task.${itemId}.checked`] ?? false,
        isSubhead: fields[`task.${itemId}.isSubhead`] ?? false,
        attachments: fields[`task.${itemId}.attachments`] ?? [],
        assignedTo: fields[`task.${itemId}.assignedTo`],
        verifiedDocument: fields[`task.${itemId}.verifiedDocument`],
        requiredUpload: fields[`task.${itemId}.requiredUpload`] ?? false,
        dueDate: fields[`task.${itemId}.dueDate`],
    };
};
const getRequiredDocumentsFromPage = (fields, userId, pageId, returnAssignedTo) => {
    const items = (fields['items'] ?? []);
    const tasks = items.map((i) => getTaskDetails(fields, i));
    const globalAssignedTo = fields['assignedTo'];
    const globalDueDate = fields['dueDate'];
    let parentAssignTo = globalAssignedTo;
    let parentDueDate = globalDueDate;
    tasks.forEach((t) => {
        if (!globalAssignedTo && t.isSubhead) {
            parentAssignTo = t.assignedTo;
        }
        else if (parentAssignTo) {
            t.assignedTo = parentAssignTo;
        }
        if (!globalDueDate && t.isSubhead) {
            parentDueDate = t.dueDate;
        }
        else if (parentDueDate) {
            t.dueDate = parentDueDate;
        }
    });
    return tasks
        .filter((t) => t.requiredUpload && t.assignedTo?.id === (userId || t.assignedTo?.id))
        .map((t) => ({
        description: t.label,
        itemId: t.itemId,
        pageId: pageId ?? '',
        documentType: t.verifiedDocument,
        provided: t.attachments.some((a) => a.approvalStatus !== 'rejected') || t.checked,
        assignedToUserId: t.assignedTo?.id,
        assignedTo: returnAssignedTo ? t.assignedTo : undefined,
        dueDate: t.dueDate,
    }));
};
exports.getRequiredDocumentsFromPage = getRequiredDocumentsFromPage;
const getWorkspaceRequiredDocuments = (pages, userId, pageId) => {
    return Object.entries(pages)
        .filter(([pid, p]) => !p.isHidden && p.pageType === 'task-list' && pid === (pageId || pid))
        .map(([pid, p]) => (0, exports.getRequiredDocumentsFromPage)(p.fields, userId, pid))
        .flat();
};
exports.getWorkspaceRequiredDocuments = getWorkspaceRequiredDocuments;
