import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import { Role } from 'expertli-db';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

import { WorkspaceKeys } from './query-keys';

async function grantAccess(workspaceId: string, body: { userId: string; role: Role }) {
  return await client.post(
    `${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/access/grant`,
    body
  );
}
export function useGrantWorkspaceAccess(workspaceId: string) {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (body: { userId: string; role: Role }) => grantAccess(workspaceId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WorkspaceKeys.userPermissions(workspaceId));
        queryClient.invalidateQueries(WorkspaceKeys.accessRequests(workspaceId));
      },
    }
  );
  return {
    isLoading,
    response: data,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    grantAccess: mutateAsync,
  };
}
