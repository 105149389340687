"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageDescription = exports.getLastOperationDescription = void 0;
const models_1 = require("../models");
const pop_operation_1 = require("./pop-operation");
const transaction_id_1 = require("./transaction-id");
const getLastOperationDescription = (myParticipantId, conversation, getMessages) => {
    const messages = getMessages().processed;
    //get next operation
    const operation = (0, pop_operation_1.peekOperation)(messages, myParticipantId, conversation.participants);
    const descriptions = operation.map((o) => (0, exports.getMessageDescription)(o, conversation));
    if (descriptions.length === 0) {
        return null;
    }
    const description = descriptions
        .flat(1)
        .sort((a, b) => b[1] - a[1])
        .find(() => true)?.[0];
    return {
        description,
        messages: operation,
        pageId: operation.find((m) => m.pageId)?.pageId,
        transactionId: operation.find((m) => !!m.transactionId)?.transactionId,
    };
};
exports.getLastOperationDescription = getLastOperationDescription;
const RANK_MESSAGE = 1;
const RANK_SIGNIFICANT_MESSAGE = 2;
const RANK_TRANSACTION = 3;
const getMessageDescription = (message) => {
    const descriptions = [];
    // if message is in a transaction apply transaction description
    if (message.transactionId) {
        const op = (0, transaction_id_1.getOperationFromTransactionId)(message.transactionId);
        if (op === 'artifact-sticky') {
            descriptions.push(['sticky', RANK_TRANSACTION]);
        }
        else if (op === 'stencil') {
            descriptions.push(['apply stencil', RANK_TRANSACTION]);
        }
        else if (op === 'artifact-textblock') {
            descriptions.push(['text-block', RANK_TRANSACTION]);
        }
        else if (op === 'artifact-redaction') {
            descriptions.push(['redaction', RANK_TRANSACTION]);
        }
        else if (op === 'viewport-set') {
            descriptions.push(['move', RANK_TRANSACTION]);
        }
        else if (op === 'eraser') {
            descriptions.push(['eraser', RANK_TRANSACTION]);
        }
        else if (op === 'duplicate-page') {
            descriptions.push(['create page', RANK_TRANSACTION]);
        }
        else if (op === 'task-attachment-upload') {
            descriptions.push(['upload attachment', RANK_TRANSACTION]);
        }
        else if (op === 'task-attachment-remove') {
            descriptions.push(['remove attachment', RANK_TRANSACTION]);
        }
    }
    if ((0, models_1.isAnnotationClearMessage)(message)) {
        descriptions.push([
            message.clear === 'drawings-only' ? 'clear drawings' : 'clear page',
            RANK_SIGNIFICANT_MESSAGE,
        ]);
    }
    else if ((0, models_1.isAnnotationLineEndMessage)(message)) {
        descriptions.push([message.mode === 'pen' ? 'drawing' : message.mode, RANK_MESSAGE]);
    }
    else if ((0, models_1.isFieldSetMessage)(message)) {
        descriptions.push(['field change', RANK_MESSAGE]);
    }
    else if ((0, models_1.isNavReorderMessage)(message)) {
        descriptions.push(['page order', RANK_MESSAGE]);
    }
    else if ((0, models_1.isPageDeleteMessage)(message)) {
        descriptions.push(['delete page', RANK_MESSAGE]);
    }
    else if ((0, models_1.isPageNotesSetMessage)(message)) {
        descriptions.push(['page notes', RANK_MESSAGE]);
    }
    else if ((0, models_1.isPageRenameMessage)(message)) {
        descriptions.push(['rename page', RANK_MESSAGE]);
    }
    else if ((0, models_1.isArtifactRemoveMessage)(message)) {
        descriptions.push(['delete', RANK_MESSAGE]);
    }
    else if ((0, models_1.isPageInsertMessage)(message)) {
        descriptions.push(['create page', RANK_MESSAGE]);
    }
    return descriptions;
};
exports.getMessageDescription = getMessageDescription;
