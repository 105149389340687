import { io } from 'socket.io-client';
import { v4 as uuid } from 'uuid';

import { Message, ParticipantConnectMessage, isStateResetMessage } from 'expertli-lib/dist/models';

import config from '../../../../config';

export const getWorkspaceMessages: (workspaceId: string) => Promise<ReadonlyArray<Message>> = (
  workspaceId
) => {
  const participantId = uuid();
  return new Promise<ReadonlyArray<Message>>((resolve, reject) => {
    const socket = io(`${config.NEXT_PUBLIC_MUNCHER_URL}/workspaces/${workspaceId}`, {
      forceNew: true,
      transports: ['websocket', 'polling'],
      withCredentials: true,
    });
    socket.on('connect', () =>
      socket.emit('data', {
        messageType: 'participant-connect',
        participantId,
        name: '',
        userId: participantId,
        requestReset: true,
        requestVolatile: false,
      } as ParticipantConnectMessage)
    );
    socket.on('connect_error', (e) => reject(e));
    socket.on('data', (msg) => {
      if (isStateResetMessage(msg) && msg.participantId === participantId) {
        socket.disconnect();
        resolve(msg.messages);
      }
    });
  });
};
