import { PaletteOptions, alpha } from '@mui/material/styles';

import { ColorComponents } from 'expertli-lib/dist/utils/get-user-colors';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export const DEFAULT_SECONDARY_COLOR = '#49525B';

export type GetUserColor = (index: number | string) => ColorComponents;
export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    getUserColor: GetUserColor;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    getUserColor: GetUserColor;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    placeholder: string;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#e3e3e3',
  light: '#999999',
  main: '#000000',
  dark: '#404040',
  darker: '#1b1b1b',
};

const PRIMARY_DARK = {
  lighter: '#aaaaaa',
  light: '#dddddd',
  main: '#ffffff',
  dark: '#dddddd',
  darker: '#aaaaaa',
};

const SECONDARY = {
  lighter: '#feffb8',
  light: '#fcff7a',
  main: '#faff11',
  dark: '#fae100',
  darker: '#fccb05',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#E8EBED',
  200: '#DDE0E4',
  300: '#D2D6DB',
  400: '#A4ADB6',
  500: '#697683',
  600: '#49525B',
  700: '#363D44',
  800: '#2D3339',
  900: '#212529',
  500_8: alpha('#697683', 0.08),
  500_12: alpha('#697683', 0.12),
  500_16: alpha('#697683', 0.16),
  500_24: alpha('#697683', 0.24),
  500_32: alpha('#697683', 0.32),
  500_48: alpha('#697683', 0.48),
  500_56: alpha('#697683', 0.56),
  500_80: alpha('#697683', 0.8),
};

const GREY_DARK = {
  0: '#000000',
  100: GREY[900],
  200: GREY[800],
  300: GREY[700],
  400: GREY[600],
  500: GREY[500],
  600: GREY[400],
  700: GREY[300],
  800: GREY[200],
  900: GREY[100],
  500_8: alpha('#697683', 0.08),
  500_12: alpha('#697683', 0.12),
  500_16: alpha('#697683', 0.16),
  500_24: alpha('#697683', 0.24),
  500_32: alpha('#697683', 0.32),
  500_48: alpha('#697683', 0.48),
  500_56: alpha('#697683', 0.56),
  500_80: alpha('#697683', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  secondary: { ...SECONDARY, contrastText: '#000' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette: Record<string, PaletteOptions> = {
  light: {
    ...COMMON,
    primary: { ...PRIMARY, contrastText: '#fff' },
    grey: GREY,
    common: { black: '#000', white: '#fff' },
    mode: 'light',
    text: { primary: '#000', secondary: GREY[700], disabled: GREY[600], placeholder: '#949494' },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
    getUserColor: (index) => null,
  },
  dark: {
    ...COMMON,
    primary: { ...PRIMARY_DARK, contrastText: '#000' },
    common: { black: '#fff', white: '#000' },
    grey: GREY_DARK,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600], placeholder: '#949494' },
    background: { paper: GREY[700], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
    getUserColor: (index) => null,
  },
} as const;

export default palette;
