"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAGE_TYPES = void 0;
exports.PAGE_TYPES = {
    'video-gallery': {
        defaultLabel: 'People',
        image: '/images/workspace/add-page/blank-section.svg',
        hoverImage: '/images/workspace/add-page/blank-section-hover.svg',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: false,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:video-gallery',
        export: 'clientside',
        autoFit: 'width-height',
        canHaveChildren: true,
        canManuallyRemoveChildren: true,
    },
    'screen-share': {
        defaultLabel: 'Screen share',
        image: '/images/workspace/add-page/blank-section.svg',
        hoverImage: '/images/workspace/add-page/blank-section-hover.svg',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: false,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:video-gallery',
        export: 'clientside',
        autoFit: 'width-height',
        hideFromNav: true,
    },
    'shared-browser': {
        defaultLabel: 'Shared browser',
        createPageLabel: 'Shared browser',
        image: '/images/workspace/add-page/shared-browser.svg',
        hoverImage: '/images/workspace/add-page/shared-browser-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: false,
            canManuallyCreate: true,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:shared-browser',
        export: 'clientside',
        autoFit: 'width-height',
        requiredOrganisationFeature: 'sharedBrowserFeatureEnabled',
    },
    'blank-a4-landscape': {
        defaultLabel: 'Blank',
        createPageLabel: 'Blank',
        image: '/images/workspace/add-page/blank-section.svg',
        hoverImage: '/images/workspace/add-page/blank-section-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: true,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:blank-a4-landscape',
        export: 'clientside',
    },
    'task-list': {
        defaultLabel: 'Untitled checklist',
        createPageLabel: 'Checklist',
        image: '/images/workspace/add-page/task-list-section.svg',
        hoverImage: '/images/workspace/add-page/task-list-section-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: true,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:checklist',
        export: 'clientside',
        canHaveChildren: true,
        canManuallyAddChildren: false,
    },
    'image-single': {
        defaultLabel: 'Image',
        image: '/images/workspace/add-page/image-section.svg',
        hoverImage: '/images/workspace/add-page/image-section-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: true,
        },
        tracking: 'workspace:section-added:image-single',
        export: 'clientside',
        autoFit: 'width-height',
    },
    'video-playback': {
        defaultLabel: 'Video',
        image: '/images/workspace/add-page/image-section.svg',
        hoverImage: '/images/workspace/add-page/image-section-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: false,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:video-playback',
        export: 'clientside',
        autoFit: 'width-height',
    },
    'pdf-editable': {
        defaultLabel: 'PDF',
        image: '/images/workspace/add-page/image-section.svg',
        hoverImage: '/images/workspace/add-page/image-section-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: true,
        },
        tracking: 'workspace:section-added:pdf-editable',
        export: 'serverside',
    },
    'add-page': {
        defaultPolicy: {
            canDelete: true,
            canRename: false,
            canAnnotate: false,
            canZoom: false,
            canExport: false,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        defaultLabel: 'New page',
        export: 'clientside',
        hideToolbar: true,
    },
    'home-loan-application': {
        createPageLabel: 'Home Loan Fact Find',
        image: '/images/workspace/add-page/serviceability-section.svg',
        hoverImage: '/images/workspace/add-page/serviceability-section-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        requiredOrganisationFeature: 'homeLoansFeatureEnabled',
        defaultLabel: 'Home Loan Fact Find',
        tracking: 'workspace:section-added:home-loan-application',
        export: 'clientside',
    },
    'home-loan-needs-analysis': {
        createPageLabel: 'Client Needs Analysis',
        image: '/images/workspace/add-page/client-needs-analysis.svg',
        hoverImage: '/images/workspace/add-page/client-needs-analysis-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        requiredOrganisationFeature: 'homeLoansFeatureEnabled',
        defaultLabel: 'Needs Analysis',
        tracking: 'workspace:section-added:home-loan-needs-analysis',
        export: 'clientside',
    },
    'home-loan-preferences': {
        createPageLabel: 'Loan preferences',
        image: '/images/workspace/add-page/loan-preferences.svg',
        hoverImage: '/images/workspace/add-page/loan-preferences-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        requiredOrganisationFeature: 'homeLoansFeatureEnabled',
        defaultLabel: 'Loan Preferences',
        tracking: 'workspace:section-added:home-loan-preferences',
        export: 'clientside',
    },
    'home-loan-serviceability': {
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: true,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        defaultLabel: 'Serviceability',
        tracking: 'workspace:section-added:home-loan-serviceability',
        export: 'clientside',
    },
    folder: {
        image: '/images/workspace/add-page/empty-folder.svg',
        hoverImage: '/images/workspace/add-page/empty-folder-hover.svg',
        defaultLabel: 'Untitled folder',
        createPageLabel: 'Folder',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: false,
            canZoom: false,
            canExport: false,
            canManuallyCreate: true,
            canDuplicate: true,
            canSaveAsTemplate: true,
            canReorder: true,
            canRedact: false,
        },
        tracking: 'workspace:section-added:folder',
        export: 'serverside',
        canHaveChildren: true,
        canManuallyAddChildren: true,
        canManuallyRemoveChildren: true,
    },
    settings: {
        hideToolbar: true,
        defaultLabel: 'Team member access',
        export: 'clientside',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: false,
            canZoom: false,
            canExport: false,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
    },
    'home-loan-v2-application': {
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        defaultLabel: 'Home Loan Application',
        export: 'clientside',
    },
    'home-loan-v2-applicant': {
        defaultLabel: 'Applicant',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-applicant-needs': {
        defaultLabel: 'Applicant Needs',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-household': {
        defaultLabel: 'Household',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-property': {
        defaultLabel: 'Property',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-liabilities': {
        defaultLabel: 'Other Debt',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-businesses': {
        defaultLabel: 'Businesses',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-servicability': {
        defaultLabel: 'Borrowing power',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-applicant-history': {
        defaultLabel: 'Applicant history',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-assets': {
        defaultLabel: 'Assets',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'home-loan-v2-loan-preferences': {
        createPageLabel: 'Loan preferences',
        defaultPolicy: {
            canDelete: false,
            canRename: false,
            canAnnotate: true,
            canZoom: true,
            canExport: true,
            canManuallyCreate: false,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: false,
            canRedact: false,
        },
        export: 'clientside',
    },
    'id-verification': {
        createPageLabel: 'ID Verification',
        image: '/images/workspace/add-page/id-verification.svg',
        hoverImage: '/images/workspace/add-page/id-verification-hover.svg',
        defaultPolicy: {
            canDelete: true,
            canRename: true,
            canAnnotate: true,
            canZoom: true,
            canExport: false,
            canManuallyCreate: true,
            canDuplicate: false,
            canSaveAsTemplate: false,
            canReorder: true,
            canRedact: false,
        },
        requiredOrganisationFeature: 'idVerificationEnabled',
        defaultLabel: 'ID Verification',
        tracking: 'workspace:section-added:id-verification',
        export: 'clientside',
        canHaveChildren: true,
        canManuallyAddChildren: false,
    },
};
