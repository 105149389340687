import { useMutation, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { Workspace } from '../types';
import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

export async function patchWorkspace(
  workspaceId: string,
  workspace: Partial<Pick<Workspace, 'name' | 'isPublic' | 'isOnDemand'>>
) {
  const { isPublic, name, isOnDemand } = workspace;
  const update = await client.patch(`${apis.WORKSPACES}/${workspaceId}`, {
    isPublic,
    name,
    isOnDemand,
  });
  return update.data as Workspace;
}

export function usePatchWorkspace() {
  const queryClient = useQueryClient();
  const { data, mutateAsync, error, isError, isLoading } = useMutation<
    Workspace,
    void,
    {
      workspaceId: string;
      workspace: Partial<Pick<Workspace, 'name' | 'isPublic' | 'isOnDemand'>>;
    }
  >(apis.WORKSPACES, async ({ workspace, workspaceId }) => {
    queryClient.setQueryData(WorkspaceKeys.details(workspaceId), (old: Workspace) => ({
      ...old,
      ...workspace,
    }));
    const result = await patchWorkspace(workspaceId, workspace);
    queryClient.invalidateQueries(WorkspaceKeys.all);
    return result;
  });
  return {
    workspace: data,
    isLoading,
    error,
    isError,
    patchWorkspace: mutateAsync,
  };
}
