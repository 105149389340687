"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifiedDocument = void 0;
var VerifiedDocument;
(function (VerifiedDocument) {
    VerifiedDocument["Other"] = "Other";
    VerifiedDocument["DriversLicense"] = "DriversLicense";
    VerifiedDocument["MedicareCard"] = "MedicareCard";
    VerifiedDocument["BankStatement"] = "BankStatement";
    VerifiedDocument["Payslip"] = "Payslip";
    VerifiedDocument["TransactionAccount"] = "TransactionAccount";
    VerifiedDocument["SavingsAccount"] = "SavingsAccount";
    VerifiedDocument["CouncilRates"] = "CouncilRates";
    VerifiedDocument["TaxDocument"] = "TaxDocument";
    VerifiedDocument["BusinessTaxDocuments"] = "BusinessTaxDocuments";
    VerifiedDocument["BusinessFinancials"] = "BusinessFinancials";
})(VerifiedDocument = exports.VerifiedDocument || (exports.VerifiedDocument = {}));
