"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPageAttributesMessage = exports.isPageNotesSetMessage = exports.isDuplicatePageMessage = exports.isPageRenameMessage = exports.isPageUndeleteMessage = exports.isPageDeleteMessage = exports.isPageInsertMessage = void 0;
const isPageInsertMessage = (msg) => msg.messageType === 'page-insert';
exports.isPageInsertMessage = isPageInsertMessage;
const isPageDeleteMessage = (msg) => msg.messageType === 'page-delete';
exports.isPageDeleteMessage = isPageDeleteMessage;
const isPageUndeleteMessage = (msg) => msg.messageType === 'page-undelete';
exports.isPageUndeleteMessage = isPageUndeleteMessage;
const isPageRenameMessage = (msg) => msg.messageType === 'page-rename';
exports.isPageRenameMessage = isPageRenameMessage;
const isDuplicatePageMessage = (msg) => msg.messageType === 'page-duplicate';
exports.isDuplicatePageMessage = isDuplicatePageMessage;
const isPageNotesSetMessage = (msg) => msg.messageType === 'page-notes-set';
exports.isPageNotesSetMessage = isPageNotesSetMessage;
const isPageAttributesMessage = (msg) => msg.messageType === 'page-attributes';
exports.isPageAttributesMessage = isPageAttributesMessage;
