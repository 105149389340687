import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { NextAdapter } from 'next-query-params';
import { PropsWithChildren } from 'react';
import { QueryParamProvider, StringParam } from 'use-query-params';

import config from '@expertli/config';
import { pages } from '@expertli/features/auth/urls';
import { ThemeProvider } from '@expertli/features/theme';
import { DialogProvider } from '@expertli/features/theme/contexts/dialog-context';
import { MediaSyncProvider } from '@expertli/features/theme/contexts/media-sync-context';

import { IdleCheck } from './idle-check';
import { QueryClientWrapper } from './query-client-wrapper';
import { SnackbarWrapper } from './snackbar-wrapper';

type Props<T> = {
  session: Session;
  dehydratedState: any;
} & PropsWithChildren<T>;

function QueryAdapter(props) {
  return <NextAdapter {...props} shallow={false} />;
}

export function AppProviders<T>({ session, children, dehydratedState }: Props<T>) {
  return (
    <QueryParamProvider
      adapter={QueryAdapter}
      options={{
        includeAllParams: true,
      }}
    >
      <SessionProvider session={session}>
        <MediaSyncProvider>
          <SnackbarWrapper>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <QueryClientWrapper session={session} dehydratedState={dehydratedState}>
                <ThemeProvider forceMode="expertli">
                  <IdleCheck
                    logOutOnIdle={true}
                    promptAfter={config.USER_SESSION_IDLE_TIMEOUT}
                    idleAfter={config.USER_SESSION_IDLE_LOGOUT_TIMEOUT}
                    idleRedirectUrl={`${pages.LOGGED_OUT}?variant=sessionExpired`}
                    pause={false}
                  />
                  <CssBaseline />
                  <DialogProvider>{children}</DialogProvider>
                </ThemeProvider>
              </QueryClientWrapper>
            </LocalizationProvider>
          </SnackbarWrapper>
        </MediaSyncProvider>
      </SessionProvider>
    </QueryParamProvider>
  );
}
