import { WorkspaceSession } from 'expertli-auth';

import { client } from '@expertli/lib/axios';

import { apis } from '../../urls';

export const getStencilWorkspaceId: (workspaceId: string) => Promise<string> = async (
  workspaceId
) => {
  const workspace = await client.get<WorkspaceSession>(`${apis.WORKSPACES}/${workspaceId}/stencil`);
  return workspace.data.workspaceId;
};
