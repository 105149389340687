import { FC, createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useForceUpdate } from '@expertli/features/workspace/hooks';

export type MediaSyncContextProps = {
  registerLoadingComponent: () => { unregister: () => void };
  registerMediaPlayer: (media: HTMLMediaElement) => { unregister: () => void };
  isBuffering: boolean;
  bufferingSynced: boolean;
  setBufferingSynced: (bufferingSynced: boolean) => void;
};

const initialValues: MediaSyncContextProps = {
  registerLoadingComponent: () => ({ unregister: () => {} }),
  registerMediaPlayer: () => ({ unregister: () => {} }),
  isBuffering: false,
  bufferingSynced: false,
  setBufferingSynced: () => {},
};

export const MediaSyncContext = createContext<MediaSyncContextProps>(initialValues);

export const useMediaSyncContext = () => useContext(MediaSyncContext);

export const MediaSyncProvider: FC = ({ children }) => {
  const registeredMedia = useRef<HTMLMediaElement[]>([]);
  const forceUpdate = useForceUpdate();
  const [bufferingSynced, setBufferingSynced] = useState<boolean>(false);
  const [isBuffering, setIsBuffering] = useState<boolean>(false);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const loadingComponents = useRef<string[]>([]);

  const checkBuffer = () => {
    const buffering =
      registeredMedia.current.some((m) => m.readyState != 4) ||
      loadingComponents.current.length > 0;
    setIsBuffering(buffering);
  };

  const mediaChanged = useCallback(() => {
    if (registeredMedia.current.length + loadingComponents.current.length) {
      if (!interval.current) {
        interval.current = setInterval(checkBuffer, 50);
      }
    } else {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
        checkBuffer();
      }
    }
  }, []);

  const registerMediaPlayer: MediaSyncContextProps['registerMediaPlayer'] = useCallback(
    (media) => {
      registeredMedia.current.push(media);
      mediaChanged();
      forceUpdate();
      return {
        unregister: () => {
          const index = registeredMedia.current.indexOf(media);
          if (index !== -1) {
            registeredMedia.current.splice(index);
            mediaChanged();
          }
        },
      };
    },
    [forceUpdate, mediaChanged]
  );

  const registerLoadingComponent: MediaSyncContextProps['registerLoadingComponent'] =
    useCallback(() => {
      const id = uuid();
      loadingComponents.current.push(id);
      mediaChanged();
      forceUpdate();
      return {
        unregister: () => {
          loadingComponents.current = loadingComponents.current.filter((c) => c !== id);
          mediaChanged();
        },
      };
    }, [forceUpdate, mediaChanged]);

  const returnValue = useMemo(
    () => ({
      registerMediaPlayer,
      isBuffering,
      bufferingSynced,
      setBufferingSynced,
      registerLoadingComponent,
    }),
    [bufferingSynced, isBuffering, registerLoadingComponent, registerMediaPlayer]
  );

  return <MediaSyncContext.Provider value={returnValue}>{children}</MediaSyncContext.Provider>;
};
