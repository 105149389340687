import Script from 'next/script';
import config from '@expertli/config';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { setUser } from '@expertli/lib/analytics';

export function GoogleAnalytics() {
  const session = useSession();

  useEffect(() => {
    if (session.data?.user) {
      setUser(session.data.user.id);
    }
  }, [session.data?.user]);

  // NOTE: Script components cannot appear in Head
  return (
    // TODO: nonce should be runtime generated
    <Script id="google-analytics" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.NEXT_PUBLIC_GTM_CONTAINER_ID}');
        function gtmDataLayerPush(){window.dataLayer.push(arguments);}
      `}
    </Script>
  );
}
