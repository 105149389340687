"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserColorIndex = void 0;
const getUserColorIndex = (userId, participantColors) => {
    const userIds = new Set();
    for (const p of Object.values(participantColors)) {
        if (p.userId === userId && p.colorIndex !== 0)
            return p.colorIndex;
        userIds.add(p.userId);
    }
    userIds.add(userId);
    return userIds.size;
};
exports.getUserColorIndex = getUserColorIndex;
