import { useSession } from 'next-auth/react';
import { useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { useLocalStorage } from './use-local-storage';

export const useLocalParticipant = () => {
  const { data: authSession, status } = useSession();
  const authUserId = authSession?.user?.id;
  const isReady = status !== 'loading';
  const storedParticipantId = useRef<string>(uuid());

  // only store in local storage when the user is unauthenticated
  // if you dont store for guests  - they keep getting different colours
  // if you store for authed users - the secondary display buggs out
  const [localStoredUserId, _setLocalUserId, setLocalUserIdQuiet] =
    useLocalStorage<string>('expertli:local-user-id');

  const userId = useMemo(() => {
    if (authUserId) return authUserId;
    const id = localStoredUserId || uuid();
    setLocalUserIdQuiet(id);
    return id;
  }, [setLocalUserIdQuiet, localStoredUserId, authUserId]);

  return {
    participantId: storedParticipantId.current,
    userId,
    isReady,
  };
};
