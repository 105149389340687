import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiToggleButton-root': {
            fontWeight: 500,
            border: `solid 1px ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                opacity: 0.7,
              },
            },
          },
        },
      },
    },
  };
}
