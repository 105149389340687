"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sleep = void 0;
/**
 * Simple sleep function.
 *
 * @param duration Duration to sleep in milliseconds
 * @returns
 */
async function sleep(duration) {
    return new Promise((resolve) => setTimeout(resolve, duration));
}
exports.sleep = sleep;
