import { v4 as uuid } from 'uuid';

import { Message, PageDuplicateMessage, Participant } from 'expertli-lib/dist/models';

import { getTemplateWorkspaceSession } from './get-template-workspace-session';
import { sendWorkspaceMessages } from './send-workspace-messages';

export const savePageAsTemplate: (
  workspaceId: string,
  participantId: string,
  participant: Participant,
  pageId: string,
  newTemplateName?: string,
  deletePageId?: string,
  parentId?: string
) => Promise<void> = async (
  workspaceId,
  participantId,
  participant,
  pageId,
  newTemplateName,
  deletePageId,
  parentId
) => {
  const newPageId = uuid();
  const templateWorkspace = await getTemplateWorkspaceSession(workspaceId);
  const sendMessages: Message[] = [
    {
      messageType: 'page-duplicate',
      destinationPageId: newPageId,
      isDirective: true,
      isVolatile: true,
      participantId,
      sourcePageId: pageId,
      sourceWorkspaceId: workspaceId,
      parentId,
    },
  ];

  if (deletePageId) {
    sendMessages.push({ messageType: 'page-delete', pageId: deletePageId, participantId });
  }
  if (newTemplateName) {
    sendMessages.push({
      messageType: 'page-rename',
      label: newTemplateName,
      pageId: newPageId,
      participantId,
    });
  }

  await sendWorkspaceMessages(
    templateWorkspace.workspaceId,
    participantId,
    participant,
    sendMessages
  );
};
