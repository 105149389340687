export const WORKSPACE_ZOOM_STEPS = [0.2, 0.33, 0.5, 0.75, 1, 1.5, 2, 3, 5, 7.5, 10];
export const WORKSPACE_MIN_ZOOM = WORKSPACE_ZOOM_STEPS[0];
export const WORKSPACE_MAX_ZOOM = WORKSPACE_ZOOM_STEPS[WORKSPACE_ZOOM_STEPS.length - 1];
export const UNKNOWN_NAME = 'Guest';
export const UNKNOWN_AVATAR_LABEL = 'G';

export const INITIALISED_SCREEN = { height: 5, width: 5 };
export const A4_SMALL_DIMENSION = 800; //210 * 4 / 21 * 20;
export const A4_LARGE_DIMENSION = 1130; // 297 * 4 / 21 * 20;
export const MINIMUM_VISIBLE_PIXELS_WHEN_SCROLLING_TO_BOTTOM = 80;

export const LEFT_NAV_WIDTH = 320;
export const LEFT_NAV_COLLAPSE_WIDTH = 85;

export const DASHBOARD_LINK_UTM_CONTENT = 'dashboard-link';
