import { FC, createContext, useContext } from 'react';

import { WorkspacePolicy } from 'expertli-auth/dist/workspace';
import type { WorkspaceWithOrganisationAndCreator } from 'expertli-db';

type Context = {
  claims: WorkspacePolicy;
  workspace: WorkspaceWithOrganisationAndCreator;
  isReadOnly: boolean;
  canEditTemplate: boolean;
  canAccessDashboard: boolean;
};

export type Props = Context;
export const WorkspaceClaimsContext = createContext<Context>({
  claims: new WorkspacePolicy([]),
  workspace: null,
  isReadOnly: false,
  canEditTemplate: false,
  canAccessDashboard: false,
});

export const useWorkspaceContext = () => useContext<Context>(WorkspaceClaimsContext);
export const useWorkspaceClaims = () => useWorkspaceContext().claims;

export const WorkspaceProvider: FC<Props> = ({
  children,
  claims,
  workspace,
  isReadOnly,
  canEditTemplate,
  canAccessDashboard,
}) => {
  return (
    <WorkspaceClaimsContext.Provider
      value={{
        claims,
        workspace,
        isReadOnly,
        canEditTemplate,
        canAccessDashboard,
      }}
    >
      {children}
    </WorkspaceClaimsContext.Provider>
  );
};
