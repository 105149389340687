"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenNavData = exports.flatten = exports.setPageIndexes = exports.buildNavData = void 0;
const models_1 = require("../models");
function recurseStructure(allPages, currentPages, pages, parent) {
    const newNavData = [];
    const order = currentPages.map((x) => x.id);
    for (let i = 0; i < order.length; i++) {
        const pid = order[i];
        const page = pages[pid];
        const isFolderItem = models_1.PAGE_TYPES[page.pageType].canHaveChildren;
        if (isFolderItem) {
            const subPages = allPages.filter((x) => x.parentId === pid).sort((a, b) => a.index - b.index);
            const folder = {
                id: pid,
                label: page.label,
                index: page.index,
                pageType: page.pageType,
                isHidden: page.isHidden,
                canReorder: models_1.PAGE_TYPES[page.pageType].defaultPolicy.canReorder,
                parentId: page.parentId,
                pages: recurseStructure(allPages, subPages, pages, page),
                canManuallyAddChildren: models_1.PAGE_TYPES[page.pageType].canManuallyAddChildren,
            };
            newNavData.push(folder);
        }
        else {
            const pageData = {
                label: page.label,
                id: pid,
                index: page.index,
                pageType: page.pageType,
                isHidden: page.isHidden,
                parentId: page.parentId,
                pages: [],
                canReorder: parent ? Boolean(models_1.PAGE_TYPES[parent.pageType].canManuallyAddChildren) : true,
                canManuallyAddChildren: models_1.PAGE_TYPES[page.pageType].canManuallyAddChildren,
            };
            newNavData.push(pageData);
        }
    }
    return newNavData;
}
function buildNavData(pages) {
    const allPages = Object.entries(pages)
        .map(([key, value]) => ({
        ...value,
        id: key,
    }))
        .filter((x) => !x.hideFromNav)
        .sort((a, b) => a.index - b.index);
    const newNavData = recurseStructure(allPages, allPages.filter((x) => !x.parentId), pages);
    return newNavData;
}
exports.buildNavData = buildNavData;
const setPageIndexes = (pages, navData) => {
    const newElements = {
        ...pages,
    };
    navData.forEach((x) => {
        newElements[x.id] = {
            ...newElements[x.id],
            index: x.index,
            parentId: x.parentId,
        };
    });
    return newElements;
};
exports.setPageIndexes = setPageIndexes;
function flatten(pageData, childrenSelector) {
    let result = [];
    for (const item of pageData) {
        result.push(item);
        const children = childrenSelector(item);
        if (children?.length > 0) {
            const subPages = flatten(children, childrenSelector);
            result = [...result, ...subPages];
        }
    }
    return result;
}
exports.flatten = flatten;
const flattenNavData = (navData) => flatten(navData, (item) => item.pages);
exports.flattenNavData = flattenNavData;
