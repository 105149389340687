import { PageDetails, getMessagesByPage } from 'expertli-lib/dist/utils';

import { getTemplateWorkspaceSession } from './get-template-workspace-session';
import { getWorkspaceMessages } from './get-workspace-messages';

export const getTemplates: (
  workspaceId: string,
  excludeChildren?: boolean
) => Promise<{
  pages: Record<string, PageDetails>;
  templateWorkspaceId?: string;
}> = async (workspaceId, excludeChildren) => {
  const templateWorkspace = await getTemplateWorkspaceSession(workspaceId);
  if (!templateWorkspace) return { pages: {} };

  const messages = await getWorkspaceMessages(templateWorkspace.workspaceId);
  return {
    pages: getMessagesByPage(messages, excludeChildren),
    templateWorkspaceId: templateWorkspace.workspaceId,
  };
};
