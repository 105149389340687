import createDebugger from 'debug';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { LocalConversation } from 'expertli-lib/dist/conversation';
import {
  BridgeDispatchFunction,
  ConversationState,
  InitConversationProps,
  Message,
  ParticipantDeviceSetMessage,
  isParticipantConnectMessage,
  isParticipantDeviceSetMessage,
  isPreserveExecutionOrderMessage,
  isStateResetMessage,
} from 'expertli-lib/dist/models';

import logger from '@expertli/logging';

import { DebounceReturn, useDataBridgeContext } from '.';
import { useDebounce } from '../hooks';

const debug = createDebugger('expertli:components:useRemoteConversationState');

/** connection flow
 * connecting  > connected
 * or: connecting > *error*
 *      then: reconnecting > connected     (in the event of network fluctuation)
 *      or:   disconnecting > disconnected       (if disconnect requested)
 */
export type ConversationConnectionStatus =
  | 'disconnected' /** will not attempt to reconnect */
  | 'connecting'
  | 'connected'
  | 'disconnecting' /** occurs at the request of the user */
  | 'reconnecting'; /** unintentionally disconnected, reconnection attempts in progress */

export type RemoteDispatchFunction = <T = void>(
  message: Message | Message[],
  options?: {
    doNotSetLocal?: boolean;
    doNotSetRemote?: boolean;
    UTCTimestamp?: number;
    suppressForceUpdate?: boolean;
  },
  callback?: (arg: T) => void
) => void;

export type RemoteDispatchAsyncFunction = <T = void>(
  message: Message | Message[],
  options?: { doNotSetLocal?: boolean; doNotSetRemote?: boolean }
) => Promise<T>;

export const useRemoteConversationState: (initialValues: InitConversationProps) => {
  conversation: ConversationState;
  dispatch: RemoteDispatchFunction;
  dispatchAsync: RemoteDispatchAsyncFunction;
  getMessages: LocalConversation['getMessages'];
  connectionStatus: ConversationConnectionStatus;
} = (initialValues) => {
  const localConversationRef = useRef<LocalConversation>(new LocalConversation(initialValues));

  const [conversationState, setConversationState] = useState<ConversationState>(
    localConversationRef.current.getState()
  );
  const {
    myParticipantId,
    participants,
    participantDevices,
    spotlight,
    followingUsers,
    pointers,
    viewports,
    foci,
    pages,
    workspaceId,
    alertMessage,
    navData,
    sessions,
    sessionRecordingOptions,
    commentary,
    lastMessageTimestamp,
  } = conversationState;

  const [connectionStatus, setConnectionStatus] =
    useState<ConversationConnectionStatus>('connecting');
  const { onReceive, send } = useDataBridgeContext();
  const sendRef = useRef<BridgeDispatchFunction>(null);

  const debounceViewportSend = useDebounce({ delay: 30, executeIfUnmounted: true });
  const debounceSpotlightSend = useDebounce({ delay: 75, executeIfUnmounted: true });
  const debouncePointerPositionSend = useDebounce({ delay: 300, executeIfUnmounted: true });
  const lastExecutionIdRef = useRef<string>(null);
  const lastParticipantDeviceSetMessage = useRef<ParticipantDeviceSetMessage>({
    messageType: 'participant-device-set',
    screen: initialValues.participantDevice.screen,
    isPrimary: initialValues.participantDevice.isPrimary,
    participantId: initialValues.participantId,
    isVolatile: true,
  });

  const forceUpdate = useCallback(() => {
    setConversationState(localConversationRef.current.getState());
  }, []);

  const dispatchToLocal: (
    messages: Message | readonly Message[],
    options?: Parameters<RemoteDispatchFunction>[1]
  ) => void = useCallback(
    (m, options) => {
      localConversationRef.current.dispatch(m, options);
      if (!options?.suppressForceUpdate || !options?.doNotSetLocal) {
        forceUpdate();
      }
    },
    [forceUpdate]
  );

  /** should discard messages until the connection is established and messages have been reset */
  const discardMessages = useRef<boolean>(true);

  useEffect(() => {
    if (connectionStatus !== 'connected') {
      discardMessages.current = true;
    }
  }, [connectionStatus]);

  // do not need to throw error for some if databridge not yet estalished
  // debouncing calls as zooming & panning can spam SetParticicpant
  // if multiple messages are passed - all are set to the same datetime
  const dispatchToRemote: RemoteDispatchFunction = useCallback(
    (inputMessages, options, callback) => {
      const messageDate = Date.now();

      if (Array.isArray(inputMessages)) {
        if (callback) {
          throw new Error('Callback not supported with multiple messages');
        }
        inputMessages.forEach((m) =>
          dispatchToRemote(m, {
            ...(options ?? {}),
            UTCTimestamp: options?.UTCTimestamp ?? messageDate,
            suppressForceUpdate: true,
          })
        );
        if (!options?.suppressForceUpdate) {
          forceUpdate();
        }
        return;
      }

      const message: Message = {
        ...(inputMessages as Message),
        UTCTimestamp: options?.UTCTimestamp ?? messageDate,
        messageId: uuid(),
      };

      if (isParticipantDeviceSetMessage(message)) {
        lastParticipantDeviceSetMessage.current = message;
      }

      //Client should not be dispatching messages when not fully connected
      if (discardMessages.current === true) {
        logger.warn(`Discarding ${message.messageType} message orignated by client. `);
        return;
      }

      if (isPreserveExecutionOrderMessage(message)) {
        //TODO remove mutation of message
        (message as any).executionId = uuid();
        (message as any).lastExecutionId = lastExecutionIdRef.current;
        lastExecutionIdRef.current = message.executionId;
      }

      //configure which messages require have an optional remote (ie will not throw an error if disconnected)
      //configure which messages have a debounce function
      const config: Partial<
        Record<Message['messageType'], { optionalRemote?: boolean; debounce?: DebounceReturn }>
      > = {
        'participant-device-set': { optionalRemote: true },
        'viewport-set': { optionalRemote: true, debounce: debounceViewportSend },
        'spotlight-set': { optionalRemote: true, debounce: debounceSpotlightSend },
        'pointer-position-set': { optionalRemote: true, debounce: debouncePointerPositionSend },
        'pointer-tool-set': { optionalRemote: true },
        'field-focus-set': { optionalRemote: true },
      };
      const messageConfig = config[message.messageType] ?? {};

      //dispatch locally
      dispatchToLocal(message, {
        ...options,
        doNotSetRemote: messageConfig.debounce ? true : options?.doNotSetRemote,
      });

      //dispatch to remote users
      if (!options?.doNotSetRemote) {
        if (!messageConfig.optionalRemote || (messageConfig.optionalRemote && sendRef.current)) {
          if (messageConfig.debounce) {
            messageConfig.debounce(() => {
              if (sendRef.current) {
                sendRef.current(message, callback);
                dispatchToLocal(message, { doNotSetLocal: true });
              }
            });
            //always call callback even if dispatch is debounced
            if (callback) {
              setTimeout(callback, 0);
            }
          } else {
            sendRef.current(message, callback);
          }
        } else {
          //if remote is not present (an if thats ok) still call callback
          if (callback) {
            setTimeout(callback, 0);
          }
        }
      }
    },
    [
      debounceViewportSend,
      debounceSpotlightSend,
      debouncePointerPositionSend,
      dispatchToLocal,
      forceUpdate,
    ]
  );

  const dispatchToRemoteAsync: RemoteDispatchAsyncFunction = useCallback(
    (message, options) => new Promise((resolve) => dispatchToRemote(message, options, resolve)),
    [dispatchToRemote]
  );

  //handle receipt of initialise request message
  const receiveMessage: (message: Message) => void = useCallback(
    (message) => {
      //set timestamp to percieved time - it is stored correctly on server
      message.UTCTimestamp = Date.now();

      if (
        isStateResetMessage(message) &&
        (message.messageSet === 'non-volatile' || message.messageSet === 'all') &&
        message.participantId === localConversationRef.current.getState().myParticipantId
      ) {
        // i have reset
        debug('Received initialise message');

        message.messages.forEach((m) => {
          if (isPreserveExecutionOrderMessage(m)) {
            lastExecutionIdRef.current = m.executionId;
          }
        });

        //store & restore volatile state
        const volatileState = localConversationRef.current.getVolatileState();
        localConversationRef.current = new LocalConversation(volatileState);
        dispatchToLocal(message);
        localConversationRef.current.setVolatileState(volatileState);

        // messages have been initialised from muncher - ok to handle new messages now
        discardMessages.current = false;

        setConnectionStatus('connected');
        return;
      }

      if (
        isStateResetMessage(message) &&
        message.messageSet === 'volatile' &&
        message.participantId === localConversationRef.current.getState().myParticipantId
      ) {
        // just store volatile messages, dont process them
        const messageData = localConversationRef.current.getMessages();
        messageData.all = [...messageData.processed, ...message.messages].sort(
          (a, b) => a.UTCTimestamp - b.UTCTimestamp
        );
        messageData.processed = [...messageData.all];
        return;
      }

      if (isParticipantConnectMessage(message)) {
        if (message.participantId !== myParticipantId) {
          // someone else has connected - push my latest screen size to them
          dispatchToRemote(lastParticipantDeviceSetMessage.current);
        }
      }

      //Discarding messages originated remotely - should be ok - messages should be should be in subsequent reset messages payload
      if (discardMessages.current === true) {
        logger.info(`Discarding ${message.messageType} message orignated remotely.`);
        return;
      }

      dispatchToLocal(message);
    },
    [dispatchToLocal, dispatchToRemote, myParticipantId]
  );

  //hook into data bridge recieve message
  useEffect(() => {
    onReceive(receiveMessage);
  }, [onReceive, receiveMessage]);

  // At connection send initialise request (to request state)
  // on receipt of reset state message status will be set to connected
  useEffect(() => {
    const lastState = localConversationRef.current.getState();
    sendRef.current = send;
    if (send) {
      const myParticipant = lastState.participants[lastState.myParticipantId];
      // do not set local - connect will be echoed by the server & that will be stored
      send({
        messageType: 'participant-connect',
        participantId: lastState.myParticipantId,
        userId: myParticipant.userId,
        name: myParticipant.name,
        requestReset: true,
        requestVolatile: true,
        UTCTimestamp: Date.now(),
        messageId: uuid(),
      });
      debug('connection state set to connecting');
    } else {
      debug('connection state set to reconnecting');
      setConnectionStatus((c) => (c === 'connected' ? 'reconnecting' : c));
    }
  }, [send]);

  return {
    conversation: {
      pointers,
      followingUsers,
      workspaceId,
      spotlight,
      myParticipantId,
      participants,
      participantDevices,
      viewports,
      foci,
      pages,
      alertMessage,
      navData,
      sessions,
      sessionRecordingOptions,
      commentary,
      lastMessageTimestamp,
    },
    getMessages: localConversationRef.current.getMessages,
    connectionStatus,
    dispatch: dispatchToRemote,
    dispatchAsync: dispatchToRemoteAsync,
  };
};
