import {
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined,
  WarningOutlined,
} from '@mui/icons-material';
import { Box, GlobalStyles, useTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';

type SnackbarIconProps = {
  icon: React.ReactNode;
};

function SnackbarIcon({ icon }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `white`,
      }}
    >
      {icon}
    </Box>
  );
}

export function SnackbarWrapper<T>({ children }: PropsWithChildren<T>) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <>
      <GlobalStyles
        styles={{
          '#__next': {
            '& .SnackbarContainer-root': {
              marginTop: -12,
              pointerEvents: 'none!important' as any,
            },
            '& .SnackbarContainer-root > * ': {
              pointerEvents: 'none!important' as any,
            },
            '& .SnackbarContent-root': {
              width: '100%',
              padding: theme.spacing(2),
              margin: theme.spacing(0.25, 0),
              borderRadius: '8px',
              color: theme.palette.grey[isLight ? 0 : 800],
              backgroundColor: theme.palette.grey[800],
              '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
                {
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.grey[800],
                },
              [theme.breakpoints.up('md')]: {
                minWidth: 240,
              },
            },
            '& .SnackbarItem-message': {
              padding: '0 !important',
              fontWeight: theme.typography.fontWeightMedium,
            },
            '& .SnackbarItem-action': {
              marginRight: 0,
              color: theme.palette.action.active,
              '& svg': { width: 20, height: 20 },
            },
          },
        }}
      />
      <SnackbarProvider
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableWindowBlurListener
        iconVariant={{
          info: <SnackbarIcon icon={<InfoOutlined width={18} height={18} />} />,
          success: <SnackbarIcon icon={<CheckCircleOutline width={18} height={18} />} />,
          warning: <SnackbarIcon icon={<WarningOutlined width={18} height={18} />} />,
          error: <SnackbarIcon icon={<ErrorOutline width={18} height={18} />} />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
