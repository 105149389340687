import { FC } from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  CircularProgress,
  Stack,
  Box,
} from '@mui/material';
import { SpinnerOptions } from '../model/dialog';

export const SpinnerDialog: FC<SpinnerOptions> = ({ message, title, children }) => {
  return (
    <MuiDialog open fullWidth>
      {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
      {(message || children) && (
        <MuiDialogContent>
          <Stack direction="column" alignItems="center">
            {message}
            {children}
            <Box marginTop="32px" marginBottom="16px">
              <CircularProgress />
            </Box>
          </Stack>
        </MuiDialogContent>
      )}
    </MuiDialog>
  );
};
