import { useQuery, useQueryClient } from 'react-query';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

import { WorkspaceAccessRequest } from '../types';
import { WorkspaceKeys } from './query-keys';

const queryKey = (workspaceId: string) => WorkspaceKeys.accessRequests(workspaceId);

async function getAccessRequests(url: string) {
  const response = await client.get(url);
  return response.data as WorkspaceAccessRequest[];
}

export function useGetWorkspaceAccessRequests(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(queryKey(workspaceId), () =>
    getAccessRequests(`${config.NEXT_PUBLIC_EXPERTLI_API_URL}/workspaces/${workspaceId}/access`)
  );

  const invalidate = async () => {
    await queryClient.invalidateQueries(queryKey(workspaceId));
  };

  return {
    isLoading,
    accessRequests: data,
    invalidate,
  };
}
