import { PageType } from 'expertli-lib/dist/models';

declare global {
  interface Window {
    dataLayer: any[];
    gtmDataLayerPush: (...args: any[]) => void;
  }
}

function isAnalyticsEvent(event: any): event is analytics_event {
  return Boolean(event.action && event.category);
}

export function gtmEventToDataAttributes(
  event?: analytics_event | analytics_event_string | analytics_event_string_with_label
): DOMStringMap | null {
  if (!event) {
    return null;
  }

  if (isAnalyticsEvent(event)) {
    if (event.label && event.value) {
      return {
        'data-event-action': event.action || undefined,
        'data-event-category': event.category || undefined,
        'data-event-label': event.label || undefined,
        'data-event-value': event.value || undefined,
      };
    }
    if (event.label) {
      return {
        'data-event-action': event.action || undefined,
        'data-event-category': event.category || undefined,
        'data-event-label': event.label || undefined,
      };
    }
    if (event.value) {
      return {
        'data-event-action': event.action || undefined,
        'data-event-category': event.category || undefined,
        'data-event-value': event.value || undefined,
      };
    }
    return {
      'data-event-action': event.action || undefined,
      'data-event-category': event.category || undefined,
    };
  } else {
    const data = event?.split(':');
    if (!data || data.length < 2) {
      return;
    }

    if (data.length === 3) {
      return {
        'data-event-category': data[0] || undefined,
        'data-event-action': data[1] || undefined,
        'data-event-label': data.length === 3 ? data[2] : '',
      };
    }
    return {
      'data-event-category': data[0] || undefined,
      'data-event-action': data[1] || undefined,
    };
  }
}

export function setOrganisation(organisationId: string, industry?: string) {
  window.dataLayer?.push({
    organisation_id: organisationId,
    organisation_industry: industry || '',
  });
}

export function setUser(userId: string) {
  window.dataLayer?.push({
    user_id: userId,
    // this value is for reports/explorations
    // https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites#tag-manager_2
    crm_id: userId,
  });
}

export function setRoleType(roleType: 'expert' | 'customer') {
  window.dataLayer?.push({
    role_type: roleType,
  });
}

export function trackEvent(
  event: analytics_event | analytics_event_string | analytics_event_string_with_label
) {
  if (isAnalyticsEvent(event)) {
    window.gtmDataLayerPush?.('event', event.action, {
      event_category: event.category,
      event_action: event.action,
      event_label: event.label || '',
      value: event.value || '',
    });
  } else {
    const data = event?.split(':');
    if (!data || data.length < 2) {
      return;
    }

    window.gtmDataLayerPush?.('event', data[1], {
      event_category: data[0],
      event_action: data[1],
      event_label: data.length === 3 ? data[2] : '',
    });
  }
}

function scopedTrackEvent(category: analytics_event['category']) {
  return (event: Omit<analytics_event, 'category'>) => {
    trackEvent({
      ...event,
      category,
    } as analytics_event);
  };
}

export const trackDashboardEvent = scopedTrackEvent('dashboard');
export const trackWorkspaceEvent = scopedTrackEvent('workspace');
export const trackLogInEvent = scopedTrackEvent('log-in');

type dashboard_events_string = `${dashboard_events['category']}:${dashboard_events['action']}`;
type log_in_events_string = `${log_in_events['category']}:${log_in_events['action']}`;
type workspace_events_string = `${workspace_events['category']}:${workspace_events['action']}`;
type homeloan_app_events_string =
  `${homeloan_app_events['category']}:${homeloan_app_events['action']}`;
type checklist_events_string = `${checklist_events['category']}:${checklist_events['action']}`;
export type analytics_event_string =
  | dashboard_events_string
  | log_in_events_string
  | workspace_events_string
  | homeloan_app_events_string
  | checklist_events_string;

export type analytics_event_string_with_label =
  `${analytics_event_string}:${analytics_event['label']}`;

/**
 * Events described here:
 * https://titanplatform.atlassian.net/wiki/spaces/EX/pages/810090497/Tracking+Analytics
 */
export type analytics_event =
  | log_in_events
  | dashboard_events
  | workspace_events
  | homeloan_app_events
  | checklist_events;

type workspace_events =
  | tool_changed
  | widget_created
  | widget_selected
  | widget_edit_started
  | widget_edit_ended
  | widget_deleted
  | section_added
  | section_renamed
  | section_deleted
  | section_duplicated
  | section_exported
  | section_save_as_template
  | page_added
  | page_deleted
  | powered_by_expertli_clicked
  | share_clicked
  | leave_all
  | leave_me_only
  | invite_sent
  | public_access_set
  | private_access_set
  | link_copied
  | edit_templates
  | template_deleted
  | turn_on_video
  | turn_off_video
  | turn_on_mic
  | turn_off_mic
  | turn_on_screenshare
  | turn_off_screenshare
  | av_settings_clicked
  | av_settings_changed
  | follow_activated
  | zoomed_in
  | zoomed_out
  | workspace_exited
  | workspace_connect_tablet
  | toggle_fullscreen_video
  | undo
  | redo;

type log_in_events = otp_verified | expired_otp_request | log_out_success | request_otp;

type dashboard_events =
  | workspace_create_started
  | workspace_created
  | workspace_create_cancelled
  | workspace_renamed
  | workspace_selected
  | workspace_searched;

type homeloan_app_events = homeloan_app_started | homeloan_app_servicing_calculated;

type checklist_events =
  | checklist_item_created
  | checklist_item_deleted
  | checklist_locked
  | checklist_edit
  | checklist_item_upload;

export type event_args = {
  label?: string;
  value?: string;
};

type request_otp = {
  category: 'log-in';
  action: 'request-otp';
} & event_args;

type otp_verified = {
  category: 'log-in';
  action: 'otp-verified';
} & event_args;

type expired_otp_request = {
  category: 'log-in';
  action: 'expired-otp-request';
} & event_args;

type log_out_success = {
  category: 'log-in';
  action: 'log-out-success';
} & event_args;

type workspace_create_started = {
  category: 'dashboard';
  action: 'workspace-create-started';
} & event_args;

type workspace_created = {
  category: 'dashboard';
  action: 'workspace-created';
} & event_args;

type workspace_renamed = {
  category: 'dashboard';
  action: 'workspace-renamed';
} & event_args;

type workspace_create_cancelled = {
  category: 'dashboard';
  action: 'workspace-create-cancelled';
} & event_args;

type workspace_selected = {
  category: 'dashboard';
  action: 'workspace-selected';
} & event_args;

type workspace_searched = {
  category: 'dashboard';
  action: 'workspace-searched';
} & event_args;

type tool_changed = {
  category: 'workspace';
  action: 'tool-changed';
} & event_args;

type redo = {
  category: 'workspace';
  action: 'redo';
} & event_args;

type undo = {
  category: 'workspace';
  action: 'undo';
} & event_args;

type widget_created = {
  category: PageType;
  action: 'widget-created';
} & event_args;

type widget_selected = {
  category: PageType;
  action: 'widget-selected';
} & event_args;

type widget_edit_started = {
  category: PageType;
  action: 'widget-edit-started';
} & event_args;

type widget_edit_ended = {
  category: PageType;
  action: 'widget-edit-ended';
} & event_args;

type widget_deleted = {
  category: PageType;
  action: 'widget-deleted';
} & event_args;

type section_added = {
  category: 'workspace';
  action: 'section-added';
} & event_args;

type section_deleted = {
  category: 'workspace';
  action: 'section-deleted';
} & event_args;

type section_duplicated = {
  category: 'workspace';
  action: 'section-duplicated';
} & event_args;

type section_exported = {
  category: 'workspace';
  action: 'section-exported';
} & event_args;

type section_save_as_template = {
  category: 'workspace';
  action: 'section-template-saved';
} & event_args;

type section_renamed = {
  category: 'workspace';
  action: 'section-renamed';
} & event_args;

type page_added = {
  category: 'workspace';
  action: 'page-added';
} & event_args;

type page_deleted = {
  category: 'workspace';
  action: 'page-deleted';
} & event_args;

type powered_by_expertli_clicked = {
  category: 'workspace';
  action: 'powered-by-expertli-clicked';
} & event_args;

type share_clicked = {
  category: 'workspace';
  action: 'share-clicked';
} & event_args;

type leave_me_only = {
  category: 'workspace';
  action: 'leave-me-only';
} & event_args;

type leave_all = {
  category: 'workspace';
  action: 'leave-all';
} & event_args;

type invite_sent = {
  category: 'workspace';
  action: 'invite-sent';
} & event_args;

type public_access_set = {
  category: 'workspace';
  action: 'public-access-set';
} & event_args;

type private_access_set = {
  category: 'workspace';
  action: 'private-access-set';
} & event_args;

type link_copied = {
  category: 'workspace';
  action: 'link-copied';
} & event_args;

type edit_templates = {
  category: 'workspace';
  action: 'edit-templates';
} & event_args;

type template_deleted = {
  category: 'workspace';
  action: 'template-deleted';
} & event_args;

type turn_on_video = {
  category: 'workspace';
  action: 'turn-on-video';
} & event_args;

type turn_off_video = {
  category: 'workspace';
  action: 'turn-off-video';
} & event_args;

type toggle_fullscreen_video = {
  category: 'workspace';
  action: 'activated' | 'deactivated';
} & event_args;

type turn_on_mic = {
  category: 'workspace';
  action: 'turn-on-mic';
} & event_args;

type turn_off_mic = {
  category: 'workspace';
  action: 'turn-off-mic';
} & event_args;

type turn_on_screenshare = {
  category: 'workspace';
  action: 'turn-on-screenshare';
} & event_args;

type turn_off_screenshare = {
  category: 'workspace';
  action: 'turn-off-screenshare';
} & event_args;

type av_settings_clicked = {
  category: 'workspace';
  action: 'av-settings-clicked';
} & event_args;

type av_settings_changed = {
  category: 'workspace';
  action: 'av-settings-changed';
} & event_args;

type follow_activated = {
  category: 'workspace';
  action: 'follow-activated';
} & event_args;

type zoomed_in = {
  category: 'workspace';
  action: 'zoomed-in';
} & event_args;

type zoomed_out = {
  category: 'workspace';
  action: 'zoomed-out';
} & event_args;

type workspace_exited = {
  category: 'workspace';
  action: 'workspace-exited';
} & event_args;

type workspace_connect_tablet = {
  category: 'workspace';
  action: 'connect-tablet';
} & event_args;

type homeloan_app_started = {
  category: 'homeloan-app';
  action: 'app-started';
} & event_args;

type homeloan_app_servicing_calculated = {
  category: 'homeloan-app';
  action: 'servicing-calculated';
} & event_args;

type checklist_item_created = {
  category: 'checklist';
  action: 'item-created';
} & event_args;

type checklist_item_deleted = {
  category: 'checklist';
  action: 'item-deleted';
} & event_args;

type checklist_item_upload = {
  category: 'checklist';
  action: 'item-upload';
} & event_args;

type checklist_locked = {
  category: 'checklist';
  action: 'checklist-locked';
} & event_args;

type checklist_edit = {
  category: 'checklist';
  action: 'edit-checklist-clicked';
} & event_args;
