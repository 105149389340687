import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import Router from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import { browserVersion, isSafari } from 'react-device-detect';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import urlParse from 'url-parse';
import { useCountdown } from 'usehooks-ts';

import { pages } from '@expertli/features/auth/urls';

import { getCurrentUrl } from '../lib/get-current-url';

type Props = {
  /**
   * Number of seconds becore use is considered inactive and shown a dialog to confirm they are still active
   */
  promptAfter: number;

  /**
   * Log the user out when idle timer runs out.
   */
  logOutOnIdle: boolean;

  /**
   * Number of seconds before users are logged out after dialog is shown
   */
  idleAfter: number;
  idleRedirectUrl: string;

  /**
   * Pause the idle timer depending on external event
   */
  pause: boolean;
};

const Component = (props: Props) => {
  const session = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [sessionExpiresIn, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: props.idleAfter,
  });
  const enableWorkers = !isSafari || (isSafari && !browserVersion.startsWith('15'));
  const onIdleHandler = useCallback(() => {
    let callbackUrl = (Router.query?.callbackUrl as string) || window.location;
    let idleRedirectUrl = urlParse(props.idleRedirectUrl, true);
    idleRedirectUrl.set('query', {
      ...idleRedirectUrl.query,

      // Set the callbackUrl to existing callbackUrl or current URL
      callbackUrl,
    });
    let redirectUrl: string = idleRedirectUrl.toString();

    if (props.logOutOnIdle) {
      // Log out first, then redirect to the original idleRedirectUrl
      redirectUrl = `${pages.LOG_OUT}?callbackUrl=${encodeURIComponent(redirectUrl)}`;
    }

    Router.push(redirectUrl, undefined, { shallow: false });
  }, [props.idleRedirectUrl, props.logOutOnIdle]);
  const { activate, start, pause } = useIdleTimer({
    crossTab: true,
    syncTimers: 200,
    startOnMount: false,
    startManually: true,
    // After timeout, prompt is displayed
    timeout: props.promptAfter * 1000,
    // After promptTimeout is reached, onIdle is called
    promptBeforeIdle: props.idleAfter * 1000,
    onActive: () => {
      // Force next-auth session to reload
      const visibilitychangeEvent = new Event('visibilitychange');
      document.dispatchEvent(visibilitychangeEvent);
    },
    onPrompt: () => {
      setIsOpen(true);
      startCountdown();
    },
    onIdle: onIdleHandler,
    timers: enableWorkers ? workerTimers : undefined,
  });

  useEffect(() => {
    // When it's already open, don't let pause prop do anything. Instead we wait for user action
    if (isOpen) {
      return;
    }

    if (session.status === 'authenticated' && !props.pause) {
      activate();
      start();
    } else {
      pause();
    }
  }, [activate, isOpen, pause, props, props.pause, session.status, start]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    activate();
    start();
    resetCountdown();
    stopCountdown();
  }, [activate, resetCountdown, start, stopCountdown]);

  if (sessionExpiresIn === 0) {
    onIdleHandler();
  } else if (session.status !== 'loading' && !session.data) {
    return null;
  }

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="xs">
      <IconButton
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
        }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography variant="h6">Are you still there?</Typography>
        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
          To ensure your data remains secure, we will end your session after a period of inactivity.
        </Typography>

        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
          Your session will expire in {sessionExpiresIn} seconds.
        </Typography>

        <Button variant="contained" onClick={onClose} sx={{ width: '100%' }}>
          Continue session
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export const IdleCheck = memo(Component);
