import { useQuery, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { OrganisationWorkspacePermission } from '../types';
import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

const queryKey = (workspaceId: string) => WorkspaceKeys.orgPermission(workspaceId);

async function getOrganisationWorkspacePermission(url: string) {
  const response = await client.get(url);
  return response.data as OrganisationWorkspacePermission[];
}

export function useGetOrganisationWorkspacePermission(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(queryKey(workspaceId), () =>
    getOrganisationWorkspacePermission(`${apis.WORKSPACES}/${workspaceId}/permissions/organisation`)
  );

  const invalidate = async () => {
    await queryClient.invalidateQueries(queryKey(workspaceId));
  };

  return {
    isLoading,
    permissions: data,
    invalidate,
  };
}
