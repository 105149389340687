import { ProviderContext } from 'notistack';
import { useEffect, useRef } from 'react';

import { ConversationState } from 'expertli-lib/dist/models';

import { getParticipantsFollowingMe } from '../utils/get-participants-following-me';
import { useLatestRef } from './use-latest-ref';

export const useAlertFollowingChange: (
  myParticipantId: string,
  participants: ConversationState['participants'],
  followingUsers: ConversationState['followingUsers'],
  enqueueAlert: ProviderContext['enqueueSnackbar'],
  active: boolean
) => void = (myParticipantId, participants, followingUsers, enqueueAlert, active) => {
  const latestActive = useLatestRef(active);
  const latestParticipants = useLatestRef(participants);
  const previousFollowers = useRef<string[]>([]);

  useEffect(() => {
    const participantsFollowing = getParticipantsFollowingMe(
      latestParticipants.current,
      followingUsers,
      myParticipantId
    );

    const currentFollowers = Array.from(
      new Set(participantsFollowing.map((pid) => latestParticipants.current[pid].name))
    );
    if (latestActive.current) {
      previousFollowers.current.forEach((c) => {
        if (!currentFollowers.includes(c)) {
          enqueueAlert(`${c} has stopped following you`, { variant: 'info' });
        }
      });
    }
    previousFollowers.current = currentFollowers;
  }, [enqueueAlert, followingUsers, latestActive, myParticipantId, latestParticipants]);
};
