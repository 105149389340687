import axios from 'axios';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { pick } from 'ramda';
import safeClone from 'safe-clone-deep';
import traverse from 'traverse';
import type { JsonObject } from 'type-fest';

export const client = axios.create({
  withCredentials: true,
});

class RedactedAxiosError<ResponseDataType = unknown, RequestDataType = any> extends AxiosError<
  ResponseDataType,
  RequestDataType
> {
  // AxiosError<T>
  config: AxiosRequestConfig;
  code?: string;
  request?: JsonObject;
  response?: AxiosResponse<ResponseDataType>;
  isAxiosError: boolean;

  // RedactedAxiosError
  isRedacted: boolean;

  constructor(message?: string) {
    super(message);
    this.isRedacted = true;
  }

  toJSON = (): JsonObject => {
    // JSON.stringify will remove all undefined values.
    return JSON.parse(JSON.stringify(safeClone(this)));
  };
}

const REDACTED_VALUE = '[REDACTED]';

const SECRET_KEYS = [
  /passw(or)?d/i,
  /^pw$/,
  /^pass$/i,
  /secret/i,
  /token/i,
  /api[-._]?key/i,
  /session[-._]?id/i,
  /authorization/i,

  // connect-session
  /^connect\.sid$/,
];

const SECRET_VALUES = [/bearer/i];

const isSecret = {
  key: (str) => SECRET_KEYS.some((regex) => regex.test(str)),
  value: (str) => SECRET_VALUES.some((regex) => regex.test(str)),
};

const INCLUDED_AXIOS_CONFIG_PROPS = [
  'timeout',
  'xsrfCookieName',
  'xsrfHeaderName',
  'maxContentLength',
  'headers',
  'method',
  'data',
  'baseURL',
  'url',
];

const INCLUDED_AXIOS_REQUEST_PROPS = ['method', 'path', 'data'];
const INCLUDED_AXIOS_RESPONSE_PROPS = ['status', 'statusText', 'headers', 'data'];

export function isAxiosError(error: unknown): error is AxiosError {
  return Boolean(error && (error as AxiosError).isAxiosError);
}

export const redactAxiosError = (exception: Error): RedactedAxiosError | AxiosError | Error => {
  const error = exception as AxiosError;
  if (!error?.isAxiosError) {
    return error;
  }

  const redactedAxiosError: RedactedAxiosError = new RedactedAxiosError(error.message);

  redactedAxiosError.isRedacted = true;
  redactedAxiosError.code = error.code;

  // Hand pick a few useful properties from AxiosError object.
  if (error.config) {
    // @ts-ignore
    redactedAxiosError.config = pick(INCLUDED_AXIOS_CONFIG_PROPS, safeClone(error.config));
  }

  if (error.request) {
    redactedAxiosError.request = pick(INCLUDED_AXIOS_REQUEST_PROPS, safeClone(error.request));
  }

  if (error.response) {
    redactedAxiosError.response = pick(
      INCLUDED_AXIOS_RESPONSE_PROPS,
      safeClone(error.response)
    ) as AxiosResponse;
  }

  return JSON.parse(
    JSON.stringify(
      traverse(redactedAxiosError).map(function (val) {
        if (this.circular) {
          this.remove();
        }

        if (isSecret.key(val) || isSecret.value(val)) {
          this.update(REDACTED_VALUE);
        }
      }),
      null,
      2
    )
  );
};
