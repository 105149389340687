import { useQuery } from 'react-query';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';

async function getReview(workspaceId: string): Promise<number> {
  const response = await client.get(`${apis.WORKSPACES}/${workspaceId}/review`, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
  });
  return response?.status;
}

export function useHasReview(workspaceId: string) {
  const { data, error, status, isLoading } = useQuery(
    ['workspace', 'review', workspaceId],
    () => getReview(workspaceId),
    {
      enabled: !!workspaceId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    error: error as Error,
    status,
    isLoading,
    responseCode: data,
  };
}
