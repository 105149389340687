import { io } from 'socket.io-client';

import { Message, Participant, ParticipantConnectMessage } from 'expertli-lib/dist/models';

import config from '../../../../config';

export const sendWorkspaceMessages: (
  workspaceId: string,
  participantId: string,
  participant: Participant,
  messages: Message[]
) => Promise<void> = (workspaceId, participantId, participant, messages) => {
  return new Promise<void>((resolve, reject) => {
    const socket = io(`${config.NEXT_PUBLIC_MUNCHER_URL}/workspaces/${workspaceId}`, {
      forceNew: true,
      transports: ['websocket', 'polling'],
      withCredentials: true,
    });
    socket.on('connect', () => {
      const connectMsg: ParticipantConnectMessage = {
        messageType: 'participant-connect',
        participantId,
        name: participant.name,
        userId: participant.userId,
        requestReset: false,
        requestVolatile: false,
      };

      [connectMsg, ...messages].forEach((m) => socket.emit('data', m));
      resolve();

      // TODO come up with a better approach to know when to disconnect
      // disconnecting immediately causes issues if messages are still queued.
      // does not seem to flush queue first.
      setTimeout(() => {
        socket.disconnect();
      }, 4000);
    });
    socket.on('connect_error', (e) => reject(e));
  });
};
