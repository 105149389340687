import { styled, useTheme } from '@mui/material/styles';
import ResizeObserver, { ResizeObserverProps } from 'rc-resize-observer';
import {
  FC,
  MutableRefObject,
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { JsonValue } from 'type-fest';

import {
  PAGEID_SCREEN_SHARE,
  PAGEID_VIDEO_GALLERY,
  STORAGE_PROTOCOL,
} from 'expertli-lib/dist/constants';
import { getTransactionId } from 'expertli-lib/dist/conversation';
import {
  Anchor,
  Commentary,
  ConversationState,
  DispatchNotificationEvent,
  Flashback,
  Focus,
  Page,
  Participant,
  Rect,
} from 'expertli-lib/dist/models';

import { GetUserColor } from '@expertli/features/theme/palette';
import { RequestFollowFunction } from '@expertli/features/video/follow-button';

import { filesDroppedHandler } from '../components';
import {
  RemoteDispatchAsyncFunction,
  RemoteDispatchFunction,
  SubscriptionStore,
  ValueChanged,
  useLatestRef,
  useStoreSubscription,
  useSubscriptionState,
} from '../hooks';
import { getStorageUrl } from '../utils/get-storage-url';

const Root = styled('div', { shouldForwardProp: (prop) => prop !== 'readOnly' })<{
  readOnly: boolean;
}>(({ readOnly }) => ({
  width: '100%',
  height: '100%',
  textAlign: 'left',
  userSelect: readOnly ? 'none' : undefined,
  pointerEvents: readOnly ? 'none' : undefined,
  touchAction: readOnly ? 'none' : undefined,
  input: { display: 'block' },
}));

export type AddAnchorCallback = () => string | null;
export type SetAnchorsCallback = (options: {
  anchors: Anchor[];
  addAnchorCallback: AddAnchorCallback;
  canAddAnchorCallback: () => boolean;
}) => void;
export type FocusControl = { participant: Participant; isSelf: boolean } & Omit<Focus, 'pageId'>;

export type DistributedPage = {
  getFocus(fieldName: string): string | undefined;
  setFocus(fieldName: string | null): void;
  subscribeFocus: (key: string, onValueChanged: ValueChanged) => void;
  unsubscribeFocus: (key: string, onValueChanged: ValueChanged) => void;
  getValue<T extends JsonValue>(key: string, initialValue?: T, pageId?: string): T;
  setValue<T extends JsonValue>(
    key: string,
    value: T | ((oldValue: T) => T),
    options?: {
      doNotSetRemote?: boolean;
      doNotSetLocal?: boolean;
      initialValue?: T;
      pageId?: string;
      transactionId?: string;
    }
  ): void;
  subscribeValue: (key: string, onValueChanged: ValueChanged) => void;
  unsubscribeValue: (key: string, onValueChanged: ValueChanged) => void;
  setAddAnchorCallback: (onAddAnchor: AddAnchorCallback, canAddAnchor?: () => boolean) => void;
  goToAnchor(anchorName: string): void;
  registerElement(props: {
    name: string;
    elementRef: MutableRefObject<RegisteredHtmlElement>;
    drawingAnchorType: RegisteredElement['drawingAnchorType'];
    anchorLabel?: string;
  }): void;
  printMode: boolean;
  dispatch: RemoteDispatchFunction;
  dispatchAsync: RemoteDispatchAsyncFunction;
  dispatchNotificationEvent: DispatchNotificationEvent;
  selectPage: (pageId: string, transactionId?: string) => void;
  addFiles: filesDroppedHandler;
  deletePage: (pageId: string) => void;
  pageId: string;
  pageType: string;
  workspaceId: string;
  participantId: string;
  getParticipant: () => Participant;
  getAttachmentUrl: (attachmentId: string, options?: { filenameOnly?: boolean }) => string | null;
  transformClientRect: (rect: DOMRect) => DOMRect;
  getInnerPortal: () => Element;
  getOuterPortal: () => Element;
  pageLabel: string;
  readOnly: boolean;
  getParticipants: () => ConversationState['participants'];
  getFollowingUsers: () => ConversationState['followingUsers'];
  requestFollow: RequestFollowFunction;
  getFlashback: () => Flashback;
  getNavData: () => ConversationState['navData'];
  getFields: () => Page['fields'];
  getPage: () => Page;
  getSessions: () => ConversationState['sessions'];
  subscribe: (value: conversationValueOptions, onValueChanged: ValueChanged) => void;
  unsubscribe: (value: conversationValueOptions, onValueChanged: ValueChanged) => void;
  createdByParticipantId: string;
  pages: SubscriptionStore<ConversationState['pages']>;
  getViewports: () => ConversationState['viewports'];
  registeredElementsChangedBounds: () => void;
  getCommentary: () => Commentary & Flashback;
  isExporting?: boolean;
  focusOutDetectionSuppression: (value: boolean) => void;
};

export const DistributedPageContext = createContext<DistributedPage>(null);

const isControlAParent: (control: HTMLElement, potentialParent: HTMLElement) => boolean = (
  control,
  potentialParent
) => {
  if (control) {
    if (potentialParent === control) {
      return true;
    }
    return isControlAParent(control.parentElement, potentialParent);
  } else {
    return false;
  }
};

const transformFoci: (
  foci: ConversationState['foci'],
  participants: ConversationState['participants'],
  getUserColor: GetUserColor
) => Record<string, string> = (foci, participants, getUserColor) => {
  return Object.keys(foci)
    .map((k) => ({ key: `${foci[k].pageId}::${foci[k].fieldName}`, value: k }))
    .reduce((prev, current) => {
      const participant = participants[current.value];
      if (participant?.status === 'active') {
        prev[current.key] = getUserColor(participant.colorIndex).pen;
      }

      return prev;
    }, {});
};

export type WorkspacePageProps = Pick<
  ConversationState,
  | 'foci'
  | 'participants'
  | 'myParticipantId'
  | 'workspaceId'
  | 'followingUsers'
  | 'viewports'
  | 'navData'
  | 'pages'
  | 'sessions'
> & {
  dispatch: RemoteDispatchFunction;
  dispatchAsync: RemoteDispatchAsyncFunction;
  pageId: string;
  page: Page;
  scale: number;
  onSetAnchors?: SetAnchorsCallback;
  onResize: ResizeObserverProps['onResize'];
  onGoToAnchor?: (anchorName: string) => void;
  onRegisteredElementsChange: (registeredElements: Record<string, RegisteredElement>) => void;
  onScrollIntoView: (boundingRect: Rect) => void;
  printMode?: boolean;
  onSelectPage: (pageId: string, transactionId?: string) => void;
  onDeletePage: (pageId: string) => void;
  onAddFiles: filesDroppedHandler;
  readOnly: boolean;
  dispatchNotificationEvent: DispatchNotificationEvent;
  onRequestFollow: RequestFollowFunction;
  outerPortalRef: MutableRefObject<HTMLDivElement>;
  flashbackViewports: ConversationState['viewports'];
  commentary: Commentary & Flashback;
  isExporting?: boolean;
  pageSizeStabilising?: boolean;
};

export type RegisteredHtmlElement = Pick<HTMLBaseElement, 'getBoundingClientRect'> & {
  style: Pick<HTMLBaseElement['style'], 'opacity'>;
};

export type RegisteredElement = {
  elementRef: MutableRefObject<RegisteredHtmlElement>;
  boundingRect: Rect | null;
  anchorLabel?: string;
  drawingAnchorType: 'y' | 'xy' | 'none' | 'xyalpha';
  alpha: number;
};

export type conversationValueOptions =
  | 'participants'
  | 'flashback'
  | 'navData'
  | 'fields'
  | 'artifacts'
  | 'drawing'
  | 'registeredElements'
  | 'followingUsers';

export const WorkspacePage: FC<WorkspacePageProps> = ({
  children,
  participants,
  sessions,
  viewports,
  followingUsers,
  foci,
  myParticipantId,
  pageId,
  page,
  pages,
  scale,
  printMode,
  dispatch,
  dispatchAsync,
  dispatchNotificationEvent,
  onSetAnchors,
  onResize,
  onGoToAnchor,
  onRegisteredElementsChange,
  onScrollIntoView,
  onSelectPage,
  onDeletePage,
  onAddFiles,
  workspaceId,
  readOnly,
  onRequestFollow,
  navData,
  outerPortalRef,
  flashbackViewports,
  commentary,
  isExporting,
  pageSizeStabilising,
}) => {
  const theme = useTheme();
  const registeredRefreshQueued = useRef<boolean>(false);
  const values = page?.fields;
  const attachments = page?.attachments;
  const focusValues = useSubscriptionState<string>(
    transformFoci(foci, participants, theme.palette.getUserColor)
  );

  const conversationStateValues = useSubscriptionState<JsonValue>(values);
  const registeredElements = useRef<Record<string, RegisteredElement>>({});
  const conversationValues = useSubscriptionState<any, conversationValueOptions>({
    flashback: viewports?.[myParticipantId]?.flashback,
    participants: participants,
    navData: navData,
    fields: page.fields,
    artifacts: page.artifacts,
    drawing: page.drawing,
    registeredElements,
    followingUsers,
  });
  const ref = useRef<HTMLDivElement>(null);

  const lastFoci = useRef<ConversationState['foci']>(foci);
  const lastDispatchedValues = useRef<Record<string, unknown>>({});
  const lastDispatchedValuesInitialised = useRef(false);
  const lastParticipants = useRef<Record<string, Participant>>(participants);
  const lastSessions = useRef<ConversationState['sessions']>(sessions);
  const lastViewports = useRef<ConversationState['viewports']>(flashbackViewports ?? viewports);
  const lastCanvasScale = useRef(scale);
  const lastPage = useRef<Page>(page);
  const lastPages = useRef<ConversationState['pages']>(pages);
  const latestCommentary = useLatestRef(commentary);
  const pagesStore = useStoreSubscription(pages);
  const focusOutDetectionSuppressed = useRef<boolean>(false);

  useLayoutEffect(() => {
    conversationValues.setValues({
      flashback: viewports?.[myParticipantId]?.flashback,
      participants: participants,
      navData: navData,
      fields: page.fields,
      artifacts: page.artifacts,
      drawing: page.drawing,
      registeredElements,
      followingUsers,
    });
  }, [
    conversationValues,
    myParticipantId,
    participants,
    viewports,
    navData,
    page.fields,
    page,
    followingUsers,
  ]);

  useLayoutEffect(() => {
    lastParticipants.current = participants;
  }, [participants]);

  useLayoutEffect(() => {
    lastCanvasScale.current = scale;
  }, [scale]);

  useLayoutEffect(() => {
    lastFoci.current = foci;
  }, [foci]);

  useLayoutEffect(() => {
    lastPage.current = page;
  }, [page]);

  useLayoutEffect(() => {
    lastPages.current = pages;
  }, [pages]);

  useLayoutEffect(() => {
    //initialise last dispatched on first page render
    if (!lastDispatchedValuesInitialised.current) {
      lastDispatchedValuesInitialised.current = true;
      //note: shallow clone of values - should not cause any mutation risk
      lastDispatchedValues.current = { ...values };
    }
    // when conversation changes synchromise lastdispatched guard so that a value is not dispatched again
    // if set back to the same value
    const delta = conversationStateValues.setValues(values, true);
    Object.entries(delta.changed).forEach(
      ([key, value]) => (lastDispatchedValues.current[key] = value)
    );
    Object.keys(delta.removed).forEach((key) => delete lastDispatchedValues.current[key]);
  }, [conversationStateValues, values]);

  useLayoutEffect(() => {
    focusValues.setValues(transformFoci(foci, participants, theme.palette.getUserColor), true);
  }, [focusValues, foci, myParticipantId, participants, theme.palette.getUserColor]);

  const addAnchorCallback = useRef<AddAnchorCallback>(null);
  const canAddAnchorCallback = useRef<() => boolean>(null);

  const refreshRegisteredElementDimensions = useCallback((names: string[]) => {
    if (ref.current) {
      const scale = lastCanvasScale.current;
      const outerRect = ref.current.getBoundingClientRect();
      names.forEach((name) => {
        const e = registeredElements.current[name];
        const domRect = e.elementRef?.current?.getBoundingClientRect?.();
        const alpha = parseFloat(e.elementRef?.current?.style?.opacity || '1');
        const boundingRect = domRect
          ? {
              top: (domRect.top - outerRect.top) / scale,
              left: (domRect.left - outerRect.left) / scale,
              width: domRect.width / scale,
              height: domRect.height / scale,
            }
          : null;
        registeredElements.current[name] = { ...e, boundingRect, alpha };
      });
    }
  }, []);

  const transformClientRect = useCallback((rect: DOMRect) => {
    const scale = lastCanvasScale.current;
    const outerRect = ref.current.getBoundingClientRect();

    return new DOMRect(
      (rect.left - outerRect.left) / scale,
      (rect.top - outerRect.top) / scale,
      rect.width / scale,
      rect.height / scale
    );
  }, []);

  const getInnerPortal = useCallback(() => ref.current as Element, []);
  const getOuterPortal = useCallback(() => outerPortalRef.current as Element, [outerPortalRef]);

  const fireSetAnchors = useCallback(() => {
    const anchors: Anchor[] = [];
    Object.keys(registeredElements.current).forEach((name) => {
      const e = registeredElements.current[name];
      if (e.anchorLabel && e.boundingRect) {
        anchors.push({ label: e.anchorLabel, name, yOffset: e.boundingRect.top - 8 });
      }
    });

    const sorted = anchors.sort((a, b) => a.yOffset - b.yOffset);
    onSetAnchors?.({
      addAnchorCallback: addAnchorCallback.current,
      canAddAnchorCallback: canAddAnchorCallback.current,
      anchors: sorted,
    });
    onRegisteredElementsChange({ ...registeredElements.current });
  }, [onSetAnchors, onRegisteredElementsChange]);

  const queueRefresh = useCallback(() => {
    if (registeredRefreshQueued.current !== true) {
      registeredRefreshQueued.current = true;
      setTimeout(() => {
        refreshRegisteredElementDimensions(Object.keys(registeredElements.current));
        fireSetAnchors();
        registeredRefreshQueued.current = false;
      }, 0);
    }
  }, [fireSetAnchors, refreshRegisteredElementDimensions]);

  const handleResize = useCallback(
    (s, e) => {
      queueRefresh();
      onResize?.(s, e);
    },
    [onResize, queueRefresh]
  );

  const registerElement: DistributedPage['registerElement'] = useCallback(
    ({ name, elementRef, anchorLabel, drawingAnchorType }) => {
      if (elementRef) {
        registeredElements.current = { ...registeredElements.current };
        registeredElements.current[name] = {
          elementRef,
          boundingRect: null,
          anchorLabel,
          drawingAnchorType,
          alpha: 1,
        };
      } else {
        registeredElements.current = { ...registeredElements.current };
        delete registeredElements.current[name];
      }
      queueRefresh();
    },
    [queueRefresh]
  );

  const setAddAnchorCallback = useCallback(
    (onAddAnchor: AddAnchorCallback, canAddAnchor?: () => boolean) => {
      addAnchorCallback.current = onAddAnchor;
      canAddAnchorCallback.current = canAddAnchor ?? (() => !!onAddAnchor);
      fireSetAnchors();
    },
    [fireSetAnchors]
  );

  const getValue: DistributedPage['getValue'] = useCallback(
    (key, initialValue, explicitPageId) =>
      explicitPageId
        ? ((lastPages.current?.[explicitPageId ?? pageId]?.fields?.[key] ?? initialValue) as any)
        : conversationStateValues.getValue(key, initialValue),
    [conversationStateValues, pageId]
  );

  const setValue: DistributedPage['setValue'] = useCallback(
    (key, value, options = {}) => {
      //TODO resolve why populating a readonly form with checkboxes calls setValue
      if (readOnly) return;
      const { doNotSetLocal, doNotSetRemote, initialValue } = options;
      const conversationStateValue = conversationStateValues.getValue(key, initialValue);
      const lastDispatchedValue = lastDispatchedValues.current[key];
      let newValue: JsonValue;

      if (typeof value === 'function') {
        const valueFunc = value as (oldValue: JsonValue) => JsonValue;
        newValue = valueFunc(conversationStateValue);
      } else {
        newValue = value;
      }

      const newString = JSON.stringify(newValue);
      const conversationString = JSON.stringify(conversationStateValue);
      const lastDispatchedString = JSON.stringify(lastDispatchedValue);

      //making sure only dispatch when theres a change to avoid reflection
      // me getting a value, setting the value in state, updating the control, firing a change event and dispatching a change,
      // and on and on
      if (newString !== conversationString || newString !== lastDispatchedString) {
        //dispatch if its value is different than the last interim or stored value
        dispatch(
          {
            messageType: 'field-set',
            fieldName: key,
            pageId: options?.pageId ?? pageId,
            jsonValue: newString,
            preserveExecutionOrder: true,
            participantId: myParticipantId,
            transactionId:
              options?.transactionId ?? getTransactionId({ operation: 'field-set', uniqueId: key }),
          },
          { doNotSetLocal, doNotSetRemote }
        );

        lastDispatchedValues.current[key] = newValue;

        if (!doNotSetLocal && !options?.pageId) {
          conversationStateValues.setValue(key, newValue);
        }
      }
    },
    [conversationStateValues, dispatch, myParticipantId, pageId, readOnly]
  );

  const getFocus: (fieldName: string) => string | undefined = useCallback(
    (fieldName) => focusValues.getValue(`${pageId}::${fieldName}`),
    [focusValues, pageId]
  );

  const getAttachmentUrl: DistributedPage['getAttachmentUrl'] = useCallback(
    (key, options) => {
      const url = attachments?.[key] ?? null;
      if (options?.filenameOnly) {
        return url ? url.replace(new RegExp(`^${STORAGE_PROTOCOL}:\/\/`, 'g'), '') : url;
      }
      return getStorageUrl(workspaceId, url);
    },
    [attachments, workspaceId]
  );

  const setFocus: (fieldName: string | null) => void = useCallback(
    (fieldName) => {
      if (fieldName !== lastFoci.current[myParticipantId]?.fieldName) {
        dispatch({
          messageType: 'field-focus-set',
          fieldName,
          pageId,
          participantId: myParticipantId,
          isVolatile: true,
        });
      }
      if (fieldName) {
        const rect = registeredElements.current[fieldName]?.boundingRect;
        if (rect) {
          onScrollIntoView(rect);
        }
      }
    },
    [dispatch, myParticipantId, pageId, onScrollIntoView]
  );

  const focusOutFieldInternal = useCallback(() => {
    if (focusOutDetectionSuppressed.current) return;
    const focusElement = document.activeElement as HTMLElement;
    const isInside = isControlAParent(focusElement, ref.current);
    if (!isInside || !document.hasFocus()) {
      setFocus(null);
    }
  }, [setFocus]);

  const focusOutField = useCallback(() => {
    if (focusOutDetectionSuppressed.current) return;
    setTimeout(focusOutFieldInternal, 50);
  }, [focusOutFieldInternal]);

  const focusOutDetectionSuppression = useCallback(
    (value: boolean) => {
      focusOutDetectionSuppressed.current = value;
      if (focusOutDetectionSuppressed.current === false) {
        focusOutField();
      }
    },
    [focusOutField]
  );

  const getParticipants = useCallback(
    () => conversationValues.getValue('participants', null) as ConversationState['participants'],
    [conversationValues]
  );

  const getParticipant = useCallback(
    () => getParticipants()[myParticipantId],
    [getParticipants, myParticipantId]
  );

  const handleGoToAnchor = useCallback((anchorName) => onGoToAnchor?.(anchorName), [onGoToAnchor]);

  const getSessions = useCallback(() => lastSessions.current, []);
  const getViewports = useCallback(() => lastViewports.current, []);
  const getPage = useCallback(() => lastPage.current, []);
  const getFollowingUsers = useCallback(
    () =>
      conversationValues.getValue('followingUsers', null) as ConversationState['followingUsers'],
    [conversationValues]
  );
  const getFlashback = useCallback(
    () => conversationValues.getValue('flashback', null) as Flashback,
    [conversationValues]
  );
  const getCommentary = useCallback(() => latestCommentary.current, [latestCommentary]);

  const getNavData = useCallback(
    () => conversationValues.getValue('navData', null) as ConversationState['navData'],
    [conversationValues]
  );
  const getFields = useCallback(() => lastPage.current?.fields, []);

  const getContext = useCallback(() => {
    const result: DistributedPage = {
      getFocus,
      setFocus,
      subscribeFocus: (fieldName: string, onValueChanged: ValueChanged) =>
        focusValues.subscribe(`${pageId}::${fieldName}`, onValueChanged),
      unsubscribeFocus: (fieldName: string, onValueChanged: ValueChanged) =>
        focusValues.unsubscribe(`${pageId}::${fieldName}`, onValueChanged),
      getValue,
      setValue,
      subscribeValue: conversationStateValues.subscribe,
      unsubscribeValue: conversationStateValues.unsubscribe,
      setAddAnchorCallback,
      goToAnchor: handleGoToAnchor,
      registerElement,
      printMode,
      dispatch,
      dispatchAsync,
      dispatchNotificationEvent,
      selectPage: onSelectPage,
      addFiles: onAddFiles,
      deletePage: onDeletePage,
      pages: pagesStore,
      pageId,
      pageType: page.pageType,
      workspaceId,
      participantId: myParticipantId,
      getParticipant,
      getAttachmentUrl,
      transformClientRect,
      getInnerPortal,
      getOuterPortal,
      pageLabel: page.label,
      readOnly,
      getParticipants,
      getViewports,
      getPage,
      getFollowingUsers,
      requestFollow: onRequestFollow,
      getFlashback,
      subscribe: conversationValues.subscribe,
      unsubscribe: conversationValues.unsubscribe,
      getNavData,
      getFields,
      getSessions,
      createdByParticipantId: page.createdByParticipantId,
      registeredElementsChangedBounds: queueRefresh,
      getCommentary,
      isExporting,
      focusOutDetectionSuppression,
    };
    return result;
  }, [
    getFocus,
    setFocus,
    getValue,
    setValue,
    conversationStateValues.subscribe,
    conversationStateValues.unsubscribe,
    setAddAnchorCallback,
    handleGoToAnchor,
    registerElement,
    printMode,
    dispatch,
    dispatchAsync,
    dispatchNotificationEvent,
    onSelectPage,
    onAddFiles,
    onDeletePage,
    pagesStore,
    pageId,
    page.pageType,
    page.label,
    page.createdByParticipantId,
    workspaceId,
    myParticipantId,
    getParticipant,
    getAttachmentUrl,
    transformClientRect,
    getInnerPortal,
    getOuterPortal,
    readOnly,
    getParticipants,
    getViewports,
    getPage,
    getFollowingUsers,
    onRequestFollow,
    getFlashback,
    conversationValues.subscribe,
    conversationValues.unsubscribe,
    getNavData,
    getFields,
    getSessions,
    queueRefresh,
    getCommentary,
    focusValues,
    isExporting,
    focusOutDetectionSuppression,
  ]);

  const [context, setContext] = useState<DistributedPage>(getContext);

  useLayoutEffect(() => {
    const inputDiv = ref.current;
    inputDiv.addEventListener('focusout', focusOutField, { passive: true });
    return () => {
      inputDiv.removeEventListener('focusout', focusOutField);
    };
  }, [focusOutField]);

  useEffect(() => setContext(getContext()), [getContext]);

  return (
    <DistributedPageContext.Provider value={context}>
      <Root
        readOnly={readOnly && pageId !== PAGEID_VIDEO_GALLERY && pageId !== PAGEID_SCREEN_SHARE}
        ref={ref}
      >
        <ResizeObserver onResize={handleResize}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'block', opacity: pageSizeStabilising ? 0 : 1 }}>{children}</div>
          </div>
        </ResizeObserver>
      </Root>
    </DistributedPageContext.Provider>
  );
};
