import { useCallback, useEffect, useRef, useState } from 'react';

import { LocalConversation } from 'expertli-lib/dist/conversation';
import {
  ConversationState,
  Flashback,
  InitConversationProps,
  Message,
} from 'expertli-lib/dist/models';

import {
  ComputeConversationRequest,
  ComputeConversationResponse,
} from '@expertli/workers/compute-conversation';

import { useAnimationFrame, useForceUpdate } from '.';

const nullFlashback = { flashbackConversation: null };

export const useFlashbackConversationState: (
  initialValues: InitConversationProps,
  messages: Message[],
  flashback: Flashback | null
) => { flashbackConversation: ConversationState | null } = (initialValues, messages, flashback) => {
  const flashbackConversationRef = useRef<LocalConversation>(null);
  if (flashbackConversationRef.current === null) {
    flashbackConversationRef.current = new LocalConversation();
  }
  const animFrame = useAnimationFrame();
  const currentViewportUTCTimestamp = useRef<number | null>(null);
  const lastFlashback = useRef<Flashback>(null);
  const lastMessageStoreIndex = useRef<number>(-1);
  const lastViewportUTCTimestamp = useRef<number | null>(null);
  const tickInterval = useRef<number>(null);
  const tickTimeout = useRef<number>(null);
  const lastMessages = useRef([]);
  const forceUpdate = useForceUpdate();
  lastMessages.current = messages;
  const waitingForWorker = useRef(false);
  const workerRef = useRef<Worker>();

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('/src/workers/compute-conversation.ts', import.meta.url)
    );
    workerRef.current.onmessage = (event: MessageEvent<ComputeConversationResponse>) => {
      waitingForWorker.current = false;
      lastViewportUTCTimestamp.current = event.data.processUntilTimestamp;
      lastMessageStoreIndex.current = event.data.lastMessageStoreIndex;
      flashbackConversationRef.current.setState(event.data.state);
      forceUpdate();
    };
    return () => {
      workerRef.current.terminate();
    };
  }, [forceUpdate]);

  const clearInterval = useCallback(() => {
    if (!tickInterval.current) return;
    window.clearInterval(tickInterval.current);
    tickInterval.current = null;
  }, []);

  const clearTimeout = useCallback(() => {
    if (!tickTimeout.current) return;
    window.clearTimeout(tickTimeout.current);
    tickTimeout.current = null;
  }, []);

  const handleTimestampChanged = useCallback(() => {
    clearTimeout();
    // if the current date is after the last date only process newer messages
    const startPos =
      lastViewportUTCTimestamp.current === null ||
      currentViewportUTCTimestamp.current < lastViewportUTCTimestamp.current
        ? -1
        : lastMessageStoreIndex.current;

    // if reprocessing create a new conversation
    if (startPos === -1) {
      if (!waitingForWorker.current) {
        const workerMessage: ComputeConversationRequest = {
          messages: lastMessages.current,
          processUntilTimestamp: currentViewportUTCTimestamp.current,
        };
        workerRef.current.postMessage(workerMessage);
        waitingForWorker.current = true;
      } else {
        if (tickTimeout.current) return;
        tickTimeout.current = window.setTimeout(handleTimestampChanged, 50);
        return;
      }
    } else {
      if (waitingForWorker.current) {
        // try again in 50
        if (tickTimeout.current) return;
        tickTimeout.current = window.setTimeout(handleTimestampChanged, 50);
        return;
      }
      // just apply the delta
      for (let i = startPos + 1; i < lastMessages.current.length; i++) {
        const msg = lastMessages.current[i];

        //process messages up until timestamp
        if (msg.UTCTimestamp > currentViewportUTCTimestamp.current) break;

        flashbackConversationRef.current.dispatch(msg as Message, { doNotSetRemote: true });
        lastMessageStoreIndex.current = i;
      }
      lastViewportUTCTimestamp.current = currentViewportUTCTimestamp.current;
      forceUpdate();
    }
  }, [clearTimeout, forceUpdate]);

  const updateCurrentViewportUTCTimestamp = useCallback(
    (immediate?: boolean) => {
      const flashback = lastFlashback.current;
      let newViewportTimestamp: number = null;
      if (!flashback) {
        newViewportTimestamp = null;
      } else if (flashback.playSpeed == 0) {
        newViewportTimestamp = flashback.UTCTimestamp;
      } else {
        newViewportTimestamp =
          (Date.now() - flashback.startTime) * flashback.playSpeed + flashback.UTCTimestamp;
        if (newViewportTimestamp >= Date.now()) {
          newViewportTimestamp = null;
        }
      }

      if (newViewportTimestamp != currentViewportUTCTimestamp.current) {
        currentViewportUTCTimestamp.current = newViewportTimestamp;
        if (immediate) {
          handleTimestampChanged();
        } else {
          animFrame.execute(handleTimestampChanged);
        }
      }
    },
    [animFrame, handleTimestampChanged]
  );

  useEffect(() => {
    //clear interval
    if (!flashback || flashback.playSpeed === 0) {
      if (tickInterval.current) {
        clearInterval();
      }
    } else {
      if (!tickInterval.current) {
        tickInterval.current = window.setInterval(() => {
          updateCurrentViewportUTCTimestamp();
        }, 50);
      }
    }
    lastFlashback.current = flashback;
    updateCurrentViewportUTCTimestamp();
  }, [clearInterval, flashback, updateCurrentViewportUTCTimestamp]);

  //clear interval on unmount
  useEffect(() => {
    return () => {
      clearInterval();
    };
  }, [clearInterval]);

  if (!flashback || !lastFlashback.current) {
    return nullFlashback;
  }
  return { flashbackConversation: flashbackConversationRef.current.getState() };
};
