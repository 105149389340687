"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.peekOperation = exports.popOperation = void 0;
const _1 = require(".");
const models_1 = require("../models");
//find all messages in a logical operation & pop them
const popOperation = (messages, participantId) => {
    const reprocessMessages = [];
    const popStack = [];
    // move processed messages to undone until you find a persist one
    let significantMessageFound = false;
    let significantMessageTransactionId = null;
    for (let i = messages.length; i > 0; i--) {
        const popped = messages.pop();
        // do not undo messages before connection
        if ((0, models_1.isParticipantConnectMessage)(popped) && popped.participantId === participantId) {
            reprocessMessages.unshift(popped);
            break;
        }
        if (popped.transactionId === _1.TRANSACTION_ID_WITH_NEXT) {
            popStack.unshift(popped);
        }
        else if (popped.transactionId === _1.TRANSACTION_ID_WITH_PREVIOUS && !significantMessageFound) {
            popStack.unshift(popped);
        }
        else if (popped.transactionId === _1.TRANSACTION_ID_WITH_PREVIOUS) {
            reprocessMessages.unshift(popped);
        }
        else if (popped?.isVolatile && !popped.transactionId) {
            popStack.unshift(popped);
        }
        else if ((0, models_1.isViewportSetMessage)(popped) && !popped.transactionId) {
            // pop viewport changes without a transactionid - used to ignore settings page
            popStack.unshift(popped);
        }
        else if (!(0, models_1.isPageMessage)(popped) &&
            !(0, models_1.isViewportSetMessage)(popped) &&
            !(0, models_1.isNavReorderMessage)(popped)) {
            // dont pop connection messages and other non page messages
            reprocessMessages.unshift(popped);
        }
        else {
            //persisted message
            if (significantMessageFound) {
                if (!significantMessageTransactionId ||
                    popped.transactionId !== significantMessageTransactionId) {
                    //found 2nd nonvolatile put the message back
                    messages.push(popped);
                    break;
                }
            }
            popStack.unshift(popped);
            significantMessageFound = true;
            significantMessageTransactionId = popped.transactionId;
        }
    }
    messages.push(...reprocessMessages);
    return popStack;
};
exports.popOperation = popOperation;
//find all messages in a logical operation
const peekOperation = (messages, participantId, participants) => {
    const operation = (0, exports.popOperation)(messages, participantId);
    messages.push(...operation);
    return operation;
};
exports.peekOperation = peekOperation;
