import { useMutation, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

const getUrl = (workspaceId: string) => `${apis.WORKSPACES}/${workspaceId}/archive`;

async function archiveWorkspace(workspaceId: string) {
  if (workspaceId) {
    await client.post(getUrl(workspaceId));
  }
}

async function restoreWorkspace(workspaceId: string) {
  if (workspaceId) {
    await client.delete(getUrl(workspaceId));
  }
}

export function useArchiveWorkspace() {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError } = useMutation(
    (workspaceId: string) => archiveWorkspace(workspaceId),
    {
      onSuccess: () => queryClient.invalidateQueries(WorkspaceKeys.all),
    }
  );

  return {
    isLoading,
    response: data,
    error,
    isError,
    archiveWorkspace: mutateAsync,
  };
}

export function useRestoreWorkspace() {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError } = useMutation(
    (workspaceId: string) => restoreWorkspace(workspaceId),
    {
      onSuccess: () => queryClient.invalidateQueries(WorkspaceKeys.all),
    }
  );

  return {
    isLoading,
    response: data,
    error,
    isError,
    restoreWorkspace: mutateAsync,
  };
}
