import { createLogger } from 'expertli-logging/dist';
import { createBrowserLogger } from 'expertli-logging/dist/browser';

import config from '@expertli/config';

const {
  DATADOG_API_KEY,
  DATADOG_CLIENT_TOKEN,
  ENVIRONMENT,
  LOGGING_DISABLED,
  NEXT_PUBLIC_EXPERTLI_URL,
  NEXT_PUBLIC_LOG_LEVEL,
  VERSION,
} = config;

const isDevelopment = ENVIRONMENT === 'development' || process.env.NODE_ENV === 'development';
const isBrowser = typeof window !== 'undefined';

const browserLogger =
  isBrowser &&
  createBrowserLogger({
    clientToken: DATADOG_CLIENT_TOKEN,
    logLevel: NEXT_PUBLIC_LOG_LEVEL,
    env: ENVIRONMENT,
    disabled: LOGGING_DISABLED,
    ...(!isDevelopment && {
      proxyUrl: `${window.location.origin}/__log`,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'],
    }),
    service: NEXT_PUBLIC_EXPERTLI_URL,
    version: VERSION,
  });

const logger = createLogger({
  name: NEXT_PUBLIC_EXPERTLI_URL,
  logLevel: NEXT_PUBLIC_LOG_LEVEL,
  environment: ENVIRONMENT,
  datadogApiKey: DATADOG_API_KEY,
  version: VERSION,
  browserLogger,
});

export default logger;
