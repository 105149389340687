import { Participant, PageDeleteMessage } from 'expertli-lib/dist/models';
import { sendWorkspaceMessages } from './send-workspace-messages';

export const deleteTemplatePage: (
  templateWorkspaceId: string,
  participantId: string,
  participant: Participant,
  pageId: string
) => Promise<void> = async (templateWorkspaceId, participantId, participant, pageId) => {
  sendWorkspaceMessages(templateWorkspaceId, participantId, participant, [
    { messageType: 'page-delete', pageId } as PageDeleteMessage,
  ]);
};
