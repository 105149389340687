import { useCallback, useEffect, useMemo, useRef } from 'react';

type Selector<S, V> = (store: S) => V;
type ChangeCallback<V> = (newValue: V) => void;
type SubscriptionRecord<S, V> = {
  selector: Selector<S, V>;
  onValueChanged: ChangeCallback<V>;
};
type SubscribeFunction<S, V> = (
  selector: Selector<S, V>,
  onValueChanged: ChangeCallback<V>
) => () => void;
type GetValueFunction<S> = () => S;

export type SubscriptionStore<S, V = unknown> = {
  subscribe: SubscribeFunction<S, V>;
  getValue: GetValueFunction<S>;
};

export const useStoreSubscription: <S, V extends any>(latestValue: S) => SubscriptionStore<S, V> = (
  latestValue
) => {
  type S = typeof latestValue;
  const previousValue = useRef(latestValue);
  const subscriptions = useRef<Set<SubscriptionRecord<S, unknown>>>(new Set());

  useEffect(() => {
    const previous = previousValue.current;
    subscriptions.current.forEach((s) => {
      const newValue = s.selector(latestValue);
      if (newValue !== s.selector(previous)) {
        s.onValueChanged(newValue);
      }
    });
    previousValue.current = latestValue;
  }, [latestValue]);

  const subscribe: SubscribeFunction<S, any> = useCallback((selector, onValueChanged) => {
    const record = { selector, onValueChanged };
    subscriptions.current.add(record);
    return () => {
      subscriptions.current.delete(record);
    };
  }, []);

  const getValue: GetValueFunction<S> = useCallback(() => previousValue.current, []);

  const result = useMemo(() => ({ getValue, subscribe }), [getValue, subscribe]);
  return result;
};
