import { ConversationState, Participant } from 'expertli-lib/dist/models';

export const getParticipantsFollowingMe: (
  participants: Record<string, Participant>,
  followingUsers: ConversationState['followingUsers'],
  myParticipantId: string
) => string[] = (participants, followingUserIds, myParticipantId) => {
  const myParticipant = participants[myParticipantId];
  const myUserId = myParticipant.userId;

  const followingIds = Object.entries(followingUserIds)
    .filter(([, master]) => myUserId === master.userId && master.followType === 'live')
    .map(([followingId]) => followingId);

  return Object.entries(participants)
    .filter(([, p]) => followingIds.includes(p.userId) && p.status === 'active')
    .map(([pid]) => pid);
};

export const getParticipantsNotFollowingMe: (
  participants: Record<string, Participant>,
  followingUsers: ConversationState['followingUsers'],
  myParticipantId: string
) => string[] = (participants, followingUsers, myParticipantId) => {
  const myParticipant = participants[myParticipantId];
  const myUserId = myParticipant.userId;

  const participantsFollowingMe = getParticipantsFollowingMe(
    participants,
    followingUsers,
    myParticipantId
  );

  return Object.entries(participants)
    .filter(
      ([pid, p]) =>
        !participantsFollowingMe.includes(pid) && p.userId !== myUserId && p.status === 'active'
    )
    .map(([pid]) => pid);
};
