import { useCallback, useReducer, useRef } from 'react';

/**
 * Stores an object as JSON in the browser's localStorage
 * @param key The string key to store the item against
 * @param initialValue The default value to start with
 * @returns A tuple of the instance of {T} and a function to update the value in localStorage
 */
const getFromLocalStorage = (key: string) => {
  const value = globalThis?.localStorage?.getItem(key);
  return value ? JSON.parse(value) : value;
};

export function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T, (value: T | ((oldValue: T) => T)) => void, (value: T) => void] {
  const [, forceUpdate] = useReducer((s) => s + 1, 0);

  const initRefValue = getFromLocalStorage(key) ?? initialValue;
  const refValue = useRef<T>(initRefValue);

  const setValueQuiet = useCallback(
    (value: T) => {
      refValue.current = value;
      globalThis?.localStorage?.setItem(key, JSON.stringify(value));
    },
    [key]
  );

  const setValue = useCallback(
    (value: T | ((oldValue: T) => T)) => {
      const newValue: T =
        typeof value === 'function' ? (value as (oldValue: T) => T)(refValue.current) : value;
      if (globalThis?.localStorage?.getItem(key) === getFromLocalStorage(key)) return;

      setValueQuiet(newValue);
      forceUpdate();
    },
    [key, setValueQuiet]
  );

  return [refValue.current, setValue, setValueQuiet];
}
