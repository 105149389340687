"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regenerateSessions = exports.startSession = exports.endSession = exports.isInSession = void 0;
function isInSession(sessionStart, sessionEnd, participantStart, participantEnd) {
    const BUFFER = 1000 * 60 * 2; // two mins either side
    const adjSStart = sessionStart - BUFFER;
    const adjSEnd = (sessionEnd ?? Date.now()) + BUFFER;
    const adjPStart = participantStart;
    const adjPEnd = participantEnd ?? Date.now();
    if (adjPEnd >= adjSStart && adjPEnd <= adjSEnd)
        return true;
    if (adjPStart >= adjSStart && adjPStart <= adjSEnd)
        return true;
    if (adjPStart <= adjSStart && adjPEnd >= adjSEnd)
        return true;
    return false;
}
exports.isInSession = isInSession;
const endSession = (sessions, participants, participantId, disconnectTime) => {
    //update session
    let result = [...sessions];
    let matchedSessionIndex = sessions.findIndex((s) => !s.endTime && s.participantIds.includes(participantId));
    if (matchedSessionIndex !== -1) {
        const matchedSession = { ...sessions[matchedSessionIndex] };
        const sessionStillActive = matchedSession.participantIds.some((pid) => participants[pid]?.status === 'active');
        matchedSession.endTime = sessionStillActive ? null : disconnectTime;
        if (matchedSession.endTime === matchedSession.startTime) {
            //if session is 0 duration remove
            result.splice(matchedSessionIndex, 1);
        }
        else {
            result[matchedSessionIndex] = matchedSession;
        }
    }
    return result;
};
exports.endSession = endSession;
const startSession = (sessions, participantId, connectTime) => {
    //update sessions
    let result;
    let matchedSessionIndex = sessions.findIndex((s) => isInSession(s.startTime, s.endTime, connectTime, null));
    if (matchedSessionIndex === -1) {
        result = [
            ...sessions,
            {
                startTime: connectTime,
                endTime: null,
                participantIds: [participantId],
            },
        ];
    }
    else {
        result = [...sessions];
        const matchedSession = { ...sessions[matchedSessionIndex] };
        if (matchedSession.participantIds.indexOf(participantId) === -1) {
            matchedSession.participantIds = [...matchedSession.participantIds, participantId];
        }
        matchedSession.startTime = Math.min(matchedSession.startTime, connectTime);
        matchedSession.endTime = null;
        result[matchedSessionIndex] = matchedSession;
    }
    return result;
};
exports.startSession = startSession;
const regenerateSessions = (participants) => {
    const messages = [
        ...Object.entries(participants).map(([pid, p]) => ({
            pid,
            timeStamp: p.connectUTCTimestamp,
            op: 'connect',
        })),
        ...Object.entries(participants).map(([pid, p]) => ({
            pid,
            timeStamp: p.disconnectUTCTimestamp ?? 0,
            op: 'disconnect',
        })),
    ]
        .filter((m) => m.timeStamp)
        .sort((a, b) => a.timeStamp - b.timeStamp);
    let sessions = [];
    messages.forEach((m) => {
        if (m.op === 'connect') {
            sessions = (0, exports.startSession)(sessions, m.pid, m.timeStamp);
        }
        else {
            sessions = (0, exports.endSession)(sessions, participants, m.pid, m.timeStamp);
        }
    });
    return sessions;
};
exports.regenerateSessions = regenerateSessions;
