import { alpha } from '@mui/material';
import chroma from 'chroma-js';

import {
  Artifact,
  Drawing,
  LineSegment,
  ParticipantColors,
  Screen,
  Viewport,
  isLineSegment,
  isTextBlock,
} from 'expertli-lib/dist/models';
import { ColorComponents } from 'expertli-lib/dist/utils';
import { toRGBAString } from 'expertli-lib/dist/utils/color';

import { GetUserColor } from '@expertli/features/theme/palette';

import { RegisteredElement } from '../context';

export const drawSmoothLine = (
  ctx: CanvasRenderingContext2D,
  line: LineSegment,
  firstPoint: number,
  color: ColorComponents,
  anchorElementLeft: number,
  anchorElementTop: number
) => {
  if (line.points == null || line.points[firstPoint] == null) return;
  const yOffset = line.yOffset + anchorElementTop;
  const xOffset =
    line.xOffset === null || line.xOffset === undefined ? 0 : line.xOffset + anchorElementLeft;

  ctx.lineWidth = line.width;
  if (line.mode === 'eraser') {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.globalAlpha = 1;
    ctx.strokeStyle = 'white';
  } else if (line.mode === 'highlighter') {
    ctx.globalCompositeOperation = 'source-over';
    ctx.strokeStyle = color.highlighter;
  } else {
    ctx.globalCompositeOperation = 'source-over';
    ctx.globalAlpha = 1;
    ctx.strokeStyle = color.pen;
  }

  ctx.lineCap = line.mode === 'highlighter' ? 'butt' : 'round';
  ctx.lineJoin = line.mode === 'highlighter' ? 'bevel' : 'round';

  ctx.beginPath();
  // if complete pre-smoothed line
  if (line.complete && firstPoint === 0 && line.points.length > 0 && line.points[0].length === 6) {
    ctx.moveTo(line.points[0][0] + xOffset, line.points[0][1] + yOffset);
    if (line.points.length === 1) {
      ctx.lineTo(line.points[0][0] + xOffset, line.points[0][1] + yOffset);
    } else {
      for (let i = 1; i < line.points.length; i++) {
        const s = line.points[i] as [number, number, number, number, number, number];
        const p = line.points[i - 1] as [number, number, number, number, number, number];
        ctx.bezierCurveTo(
          p[4] + p[0] + xOffset,
          p[5] + p[1] + yOffset,
          s[2] + s[0] + xOffset,
          s[3] + s[1] + yOffset,
          s[0] + xOffset,
          s[1] + yOffset
        );
      }
    }
  } else if (line.points.length > 0 && line.points[0].length === 6) {
    //draw bezier interim (used by stencils)
    ctx.moveTo(line.points[firstPoint][0] + xOffset, line.points[firstPoint][1] + yOffset);
    for (let i = firstPoint + 1; i < line.points.length; i++) {
      const s = line.points[i] as [number, number, number, number, number, number];
      const p = line.points[i - 1] as [number, number, number, number, number, number];
      ctx.bezierCurveTo(
        p[4] + p[0] + xOffset,
        p[5] + p[1] + yOffset,
        s[2] + s[0] + xOffset,
        s[3] + s[1] + yOffset,
        s[0] + xOffset,
        s[1] + yOffset
      );
    }
  } else {
    // draw to midpoints

    let drawFromPoint = firstPoint;
    let p = [line.points[firstPoint][0], line.points[firstPoint][1]];
    if (firstPoint === 0) {
      ctx.moveTo(p[0] + xOffset, p[1] + yOffset);
      if (line.points.length > 1) {
        // draw a line to the first midpoint
        const q = [line.points[1][0], line.points[1][1]];
        const xc = (q[0] + p[0]) / 2;
        const yc = (q[1] + p[1]) / 2;
        ctx.lineTo(xc + xOffset, yc + yOffset);
        p = q;
        drawFromPoint++;
      }
    } else {
      const q = [line.points[firstPoint - 1][0], line.points[firstPoint - 1][1]];
      const xc = (q[0] + p[0]) / 2;
      const yc = (q[1] + p[1]) / 2;
      ctx.moveTo(xc + xOffset, yc + yOffset);
    }
    for (let i = drawFromPoint + 1; i < line.points.length; i++) {
      const q = [line.points[i][0], line.points[i][1]];
      const xc = (q[0] + p[0]) / 2;
      const yc = (q[1] + p[1]) / 2;
      //draw curve between midpoints
      ctx.quadraticCurveTo(p[0] + xOffset, p[1] + yOffset, xc + xOffset, yc + yOffset);
      p = q;
    }
  }

  ctx.stroke();
  ctx.closePath();
};

export const clearCanvas = (canvas: HTMLCanvasElement, screen: Screen) => {
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio || 1;
  const mtx = ctx.getTransform();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, screen.width * pixelRatio, screen.height * pixelRatio);
  ctx.setTransform(mtx);
};

const initialiseCanvas = (canvas: HTMLCanvasElement, screen: Screen, viewport: Viewport) => {
  if (canvas.width !== screen.width || canvas.height !== screen.height) {
    const ratio = window.devicePixelRatio || 1;
    canvas.width = screen.width * ratio;
    canvas.height = screen.height * ratio;
    canvas.style.width = screen.width + 'px';
    canvas.style.height = screen.height + 'px';
  }
  const ctx = canvas.getContext('2d');
  clearCanvas(canvas, screen);

  //transform
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  const pixelRatio = window.devicePixelRatio || 1;
  const scale = viewport.scale;
  const viewportOffset = viewport.offset;
  ctx.translate(viewportOffset.x * pixelRatio, viewportOffset.y * pixelRatio);
  ctx.scale(scale * pixelRatio, scale * pixelRatio);
};

export const drawToCanvas = (
  canvas: HTMLCanvasElement,
  drawing: Drawing,
  screen: Screen,
  viewport: Viewport,
  participantColors: ParticipantColors,
  getUserColor: GetUserColor,
  registeredElements: Record<string, RegisteredElement>
) => {
  if (canvas) {
    initialiseCanvas(canvas, screen, viewport);
    drawDrawingToCanvas(canvas, drawing, participantColors, getUserColor, registeredElements);
  }
};

const drawDrawingToCanvas = (
  canvas: HTMLCanvasElement,
  drawing: Drawing,
  participantColors: ParticipantColors,
  getUserColor: GetUserColor,
  registeredElements: Record<string, RegisteredElement>
) => {
  if (canvas) {
    const ctx = canvas.getContext('2d');
    //draw lines
    drawing.order.forEach((elementId) => {
      const ele = drawing.elements[elementId];
      if (isLineSegment(ele)) {
        const userColors = getUserColor(
          ele.colorIndex ?? participantColors[ele.participantId]?.colorIndex
        );
        const anchorElement = registeredElements[ele.anchorElementName];
        const alphaAdjustedColors: typeof userColors =
          (anchorElement?.alpha ?? 1) === 1
            ? userColors
            : {
                highlighter: toRGBAString(
                  chroma(userColors.highlighter).alpha(
                    chroma(userColors.highlighter).alpha() * anchorElement.alpha
                  )
                ),
                pen: alpha(userColors.pen, anchorElement.alpha),
                sticky: alpha(userColors.sticky, anchorElement.alpha),
              };

        if (!ele.complete) {
          drawSmoothLine(ctx, ele, 0, userColors, null, 0);
        } else if (
          anchorElement !== undefined &&
          anchorElement.boundingRect &&
          ele.points?.length > 0
        ) {
          drawSmoothLine(
            ctx,
            ele,
            0,
            alphaAdjustedColors,
            anchorElement.boundingRect.left,
            anchorElement.boundingRect.top
          );
        }
      }
    });
  }
};

const drawTextBlocks: (
  canvas: HTMLCanvasElement,
  participantColors: ParticipantColors,
  getUserColor: GetUserColor,
  artifacts: Record<string, Artifact>
) => void = (canvas, participantColors, getUserColor, artifacts = {}) => {
  const ctx = canvas.getContext('2d');
  ctx.globalAlpha = 0.2;

  Object.keys(artifacts).forEach((artifactId) => {
    const artifact = artifacts[artifactId];
    if (isTextBlock(artifact)) {
      ctx.fillStyle = getUserColor(participantColors[artifact.participantId]?.colorIndex).pen;
      ctx.fillRect(artifact.left, artifact.top, artifact.width, artifact.height);
    }
  });
};

export const drawToDataUrl: (
  drawing: Drawing,
  artifacts: Record<string, Artifact>,
  screen: Screen,
  viewport: Viewport,
  participantColors: ParticipantColors,
  getUserColor: GetUserColor,
  registeredElements: Record<string, RegisteredElement>
) => Promise<string> = async (
  drawing,
  artifacts,
  screen,
  viewport,
  participantColors = {},
  getUserColor,
  registeredElements
) => {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio || 1;
  canvas.width = screen.width * pixelRatio;
  canvas.height = screen.height * pixelRatio;

  initialiseCanvas(canvas, screen, viewport);
  drawTextBlocks(canvas, participantColors, getUserColor, artifacts);
  drawDrawingToCanvas(canvas, drawing, participantColors, getUserColor, registeredElements);
  return canvas.toDataURL();
};
