"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageDetails = exports.getMessagesByPage = void 0;
const uuid_1 = require("uuid");
const conversation_1 = require("../conversation");
const models_1 = require("../models");
const getMessagesByPage = (messages, excludeChildren) => {
    const conversation = new conversation_1.LocalConversation((0, conversation_1.GET_EMPTY_INIT_PARAMS)());
    conversation.dispatch(messages);
    const processedMessages = conversation.getMessages().processed;
    const groupedMessages = {};
    processedMessages.forEach((msg) => {
        if ((0, models_1.isPageMessage)(msg)) {
            if (!groupedMessages[msg.pageId]) {
                groupedMessages[msg.pageId] = [];
            }
            groupedMessages[msg.pageId].push(msg);
        }
    });
    const state = conversation.getState();
    const pageMessages = {};
    Object.entries(state.pages).forEach(([pageId, convPage]) => {
        if (models_1.PAGE_TYPES[convPage.pageType] == null) {
            if (process.env.ENVIRONMENT === 'production') {
                // log error without risk of pii leak
                console.error('Template Excepton', (0, conversation_1.GET_EMPTY_INIT_PARAMS)(), JSON.stringify(Object.entries(state.pages).map(([pid, p]) => ({
                    id: pid,
                    label: p.label,
                    pageType: p.pageType,
                    parentId: p.parentId,
                    isHidden: p.isHidden,
                }))), JSON.stringify(messages.map((m) => ({
                    messageType: m.messageType,
                }))), JSON.stringify(new conversation_1.LocalConversation((0, conversation_1.GET_EMPTY_INIT_PARAMS)()).getState()));
            }
            else {
                // log detailed error
                console.error('Template Excepton', (0, conversation_1.GET_EMPTY_INIT_PARAMS)(), convPage.pageType, JSON.stringify(state), JSON.stringify(messages), JSON.stringify(new conversation_1.LocalConversation((0, conversation_1.GET_EMPTY_INIT_PARAMS)()).getState()));
            }
        }
        if (models_1.PAGE_TYPES[convPage.pageType].defaultPolicy.canDuplicate &&
            !convPage.isHidden &&
            (!convPage.parentId || (convPage.parentId && !excludeChildren))) {
            const currentMessages = groupedMessages[pageId];
            const lastUpdated = currentMessages.at(-1)?.UTCTimestamp;
            pageMessages[pageId] = new PageDetails(convPage.label, convPage.pageType, currentMessages, pageId, Object.values(convPage.attachments), convPage?.note?.plainText ?? '', convPage.parentId, lastUpdated);
        }
    });
    for (const item of Object.values(pageMessages).filter((x) => x.parentId)) {
        const parent = pageMessages[item.parentId];
        parent.addChild(item);
    }
    return pageMessages;
};
exports.getMessagesByPage = getMessagesByPage;
class PageDetails {
    label;
    pageType;
    _messages;
    pageId;
    attachments;
    notes;
    parentId;
    lastUpdatedUtc;
    _children;
    constructor(label, pageType, messages, pageId, attachments, notes, parentId, lastUpdatedUtc) {
        this.label = label;
        this.pageType = pageType;
        this._messages = messages;
        this.pageId = pageId;
        this.attachments = attachments;
        this.notes = notes;
        this.parentId = parentId;
        this.lastUpdatedUtc = lastUpdatedUtc;
        this._children = [];
    }
    get children() {
        return this._children;
    }
    get hasChildren() {
        return this._children.length > 0;
    }
    addChild(child) {
        this._children.push(child);
    }
    getMessages = (participantId, UTCTimestamp, targetPageId, parentId) => {
        const newPageId = targetPageId ?? (0, uuid_1.v4)();
        const newMessages = this._messages.map((m) => ({
            ...m,
            UTCTimestamp,
            messageId: (0, uuid_1.v4)(),
            participantId,
            ...((0, models_1.isPageMessage)(m)
                ? {
                    pageId: newPageId,
                }
                : {}),
            ...((0, models_1.isArtifactTextBlockMessage)(m)
                ? {
                    isEditing: false,
                }
                : {}),
            ...((0, models_1.isPageInsertMessage)(m) ? { parentId } : {}),
            ...((0, models_1.isPreserveExecutionOrderMessage)(m)
                ? {
                    preserveExecutionOrder: true,
                    executionId: undefined,
                    lastExecutionId: undefined,
                }
                : {}),
            preserveExecutionOrder: false,
            transactionId: (0, conversation_1.getTransactionId)({ operation: 'duplicate-page', uniqueId: newPageId }),
        }));
        return { messages: newMessages, pageId: newPageId };
    };
}
exports.PageDetails = PageDetails;
