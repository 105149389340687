import { useQuery, useQueryClient } from 'react-query';

import { client } from '@expertli/lib/axios';

import { WorkspaceWatcher } from '../types';
import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

const queryKey = (workspaceId: string) => WorkspaceKeys.watchers(workspaceId);

async function getWorkspaceWatchers(url: string) {
  const response = await client.get(url);
  return response.data as WorkspaceWatcher[];
}

export function useGetWorkspaceWatchers(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(queryKey(workspaceId), () =>
    getWorkspaceWatchers(`${apis.WORKSPACES}/${workspaceId}/watchers`)
  );

  const invalidate = async () => {
    await queryClient.invalidateQueries(queryKey(workspaceId));
  };

  return {
    isLoading,
    watchers: data,
    invalidate,
  };
}
