import {
  Box,
  LinearProgress,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Stack,
} from '@mui/material';
import { FC } from 'react';

import { ProgressBarOptions } from '../model/dialog';

export const ProgressBarDialog: FC<ProgressBarOptions> = ({
  progress,
  message,
  title,
  children,
}) => {
  return (
    <MuiDialog open fullWidth>
      {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
      {(message || children) && (
        <MuiDialogContent>
          <Stack direction="column" alignItems="center">
            {message}
            {children}
            <Box marginTop="32px" marginBottom="16px">
              <LinearProgress sx={{ width: '200px' }} variant="determinate" value={progress ?? 0} />
            </Box>
          </Stack>
        </MuiDialogContent>
      )}
    </MuiDialog>
  );
};
