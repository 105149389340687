import { QueryClient, useQuery, useQueryClient } from 'react-query';

import { getTemplates as getTemplatesAsync } from '../utils/template';
import { WorkspaceKeys } from './query-keys';

export const invalidateGetTemplates = async (queryClient: QueryClient, workspaceId: string) => {
  await queryClient.invalidateQueries(WorkspaceKeys.templates(workspaceId));
  await getTemplates(queryClient, workspaceId);
};

export const getTemplates = (queryClient: QueryClient, workspaceId: string) => {
  return queryClient.fetchQuery(
    WorkspaceKeys.templates(workspaceId),
    () => {
      return getTemplatesAsync(workspaceId, false);
    },
    {
      staleTime: Infinity,
    }
  );
};

export function useGetTemplates(workspaceId: string, enabled: boolean) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    WorkspaceKeys.templates(workspaceId),
    () => {
      return getTemplatesAsync(workspaceId, false);
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      enabled,
      onError: (err) => {
        console.error('Error retrieving Template', err);
      },
    }
  );

  return {
    isLoading,
    data,
    invalidate: () => {
      invalidateGetTemplates(queryClient, workspaceId);
    },
  };
}
