import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import { client } from '@expertli/lib/axios';

import { apis } from '../urls';
import { WorkspaceKeys } from './query-keys';

async function createWatcher(workspaceId: string, userId: string) {
  await client.post(`${apis.WORKSPACES}/${workspaceId}/watchers/${userId}`);
}

export function useCreateWorkspaceWatcher(workspaceId: string) {
  const queryClient = useQueryClient();
  const { isLoading, data, mutateAsync, error, isError, isSuccess } = useMutation(
    (userId: string) => createWatcher(workspaceId, userId),
    {
      onSuccess: () => queryClient.invalidateQueries(WorkspaceKeys.watchers(workspaceId)),
    }
  );
  return {
    isLoading,
    response: data,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    createWatcher: mutateAsync,
  };
}
